// MUI
import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";

// SUI
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageListing from "components/PagesListing";
import TableRequest from "./components/TableRequest";
import TicketUpdate from "examples/Drawers/ticketUpdate";

// Redux
import { getBrandTickets, getSingleBrand } from "@sharecode/common/redux/actions/brand";

// React
import { useParams, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

// External Imports
import moment from "moment";
import { ExportButton } from "components/ExportButton";
import { SizeSelectorFilter } from "components/Filters/SizeSelectorFilter";

import { DatePeriodFilter } from "components/Filters/DatePeriodFilter";
import { exportTable } from "@sharecode/common/utils/helpers";
import { URL_POST_BRAND } from "@sharecode/common/services/constantApi";
import { setSnackbar } from "@sharecode/common/redux/actions/snack";

function BrandTickets() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tickets, setTickets] = useState(null);
  const [brandInfos, setBrandInfos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const [sizeSelected, setSizeSelected] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const dispatch = useDispatch();

  const { bid } = useParams();

  const filterParams = (obj, itemToRemove) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== "" && obj[key] !== null && key !== itemToRemove) {
        result[key] = obj[key];
      }
    });
    return result;
  };

  const generalSearchParamsObject = {
    page: 1,
    size: searchParams.get("size") || 10,
    search: searchParams.get("search") || "",
    unassigned: searchParams.get("unassigned") || "",
    area: searchParams.get("area") || "",
    start_date: searchParams.get("start_date") || "",
    paper_tickets: searchParams.get("paper_tickets") || "",
    end_date: searchParams.get("end_date") || "",
  };

  const filteredSearchParams = filterParams(generalSearchParamsObject);

  useEffect(() => {
    const size = searchParams.get("size") || "10";
    const initialDate = searchParams.get("start_date") || "";
    const finalDate = searchParams.get("end_date") || "";

    setStartDate(initialDate);
    setEndDate(finalDate);
    setSizeSelected({ value: size, label: size });
  }, [searchParams]);

  const handleSizeChange = (e) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      size: e.value,
      search: searchParams.get("search") || "",
    });
    setSizeSelected({ value: e.value, label: e.label });
  };

  const handlePeriodFilter = () => {
    if (startDate && endDate) {
      let formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      let formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      searchParams.set("start_date", formattedStartDate);
      searchParams.set("end_date", formattedEndDate);
      return setSearchParams(searchParams);
    }

    searchParams.delete("start_date");
    searchParams.delete("end_date");
    setSearchParams(searchParams);

    setStartDate("");
    setEndDate("");
  };

  const handleExportBrandTickets = () => {
    const exportUrl = `${URL_POST_BRAND}${bid}/tickets/export/`;
    const exportFilename = `brand-${bid}-tickets.xlsx`;

    const keys = ["start_date", "end_date"];

    const paramsValues = {};

    keys.forEach((key) => {
      const value = searchParams.get(key);
      if (value) {
        paramsValues[key] = value;
      }
    });

    setLoadingExport(true);

    exportTable({ url: exportUrl, fileName: exportFilename }, paramsValues)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        dispatch(setSnackbar(true, "error", "error", "No entry to export"));
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  const handleClearPeriodFilter = () => {
    setSearchParams({ ...filteredSearchParams, start_date: "", end_date: "" });
  };

  useEffect(() => {
    setLoading(true);
    new Promise(async (resolve, reject) => {
      const page = searchParams.get("page") || 1;
      const size = searchParams.get("size") || 10;
      const search = searchParams.get("search") || "";
      const startDate = searchParams.get("start_date") || "";
      const endDate = searchParams.get("end_date") || "";
      const area = searchParams.get("area") || "";
      const brandId = bid;

      dispatch(getBrandTickets(page, size, search, startDate, endDate, area, brandId))
        .then((response) => {
          resolve(response);
          setTickets(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });

      dispatch(getSingleBrand(brandId))
        .then((response) => {
          setBrandInfos(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [window.location.search]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={2}>
          <Card>
            <SuiBox mb={4}>
              <SuiBox p={2} display="flex" alignItems="baseline" justifyContent="space-between">
                <SuiTypography variant="h6" fontWeight="medium">
                  Gestion des tickets de {brandInfos?.name}
                </SuiTypography>
                {/* <ExportButton
                  disabled={tickets?.count === 0}
                  handleExport={handleExportBrandTickets}
                  loading={loadingExport}
                /> */}
              </SuiBox>

              <SuiBox
                display="flex"
                gap={2}
                justifyContent="space-between"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                p={2}
              >
                <SizeSelectorFilter
                  sizeValue={sizeSelected}
                  handleSizeChange={handleSizeChange}
                  totalCount={tickets?.count}
                />
              </SuiBox>

              <SuiBox px={2} display="flex">
                <DatePeriodFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  handlePeriodFilter={handlePeriodFilter}
                  handleClearPeriodFilter={handleClearPeriodFilter}
                />
              </SuiBox>
            </SuiBox>

            <TableRequest data={tickets?.results} loading={loading} />
          </Card>
          {tickets && <PageListing data={tickets} />}
        </SuiBox>
      </DashboardLayout>
      <TicketUpdate />
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(BrandTickets);
