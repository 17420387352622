import { HTTP } from "../utils/http-common";
import {
  getAuthorizationToken,
  setAuthorizationToken,
  clearSession,
  URL_REGISTER,
  URL_AUTHENT,
  URL_LOGOUT,
  URL_VERIFY_EMAIL,
  URL_ACCOUNT_PROFILE,
  URL_RESET_PASSWORD,
  URL_CONFIRM_PASSWORD,
} from "./constantApi";
import { SIGN_IN } from "@sharecode/common/routes/constants";
import { mapRole } from "@sharecode/common/redux/actions/actionTypes";
import axios from "axios";
import StorageUtil from "@sharecode/common/utils/storageUtil";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { isMobile } from "../utils/isMobile";
import { connectAdvanced } from "react-redux";
export const postCodeSocial = (payload) => {
  return axios
    .post([URL_AUTHENT + payload.urlRedirect] + "/", payload.code)
    .then((response) => {
      setAuthorizationToken(response.data.key);
      StorageUtil.setItem("user", JSON.stringify(response.data));
      StorageUtil.setItem(
        "role",
        response.data.user.role ?? mapRole[response.data.user.role]
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAuthorizationSocial = (payload) => {
  return axios
    .get([URL_AUTHENT + payload + "/"])
    .then((response) => {
      if (response.data.authorisation_page)
        window.location.href = response.data.authorisation_page;
    })
    .catch((error) => {
      console.log(error);
    });
};

const register = (account) => {
  return new Promise((resolve, reject) => {
    HTTP.post(URL_REGISTER, account)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response.data.email[0]);
        }
      });
  });
};

const registerEmailValidation = (key) => {
  return new Promise((resolve, reject) => {
    HTTP.post(URL_VERIFY_EMAIL, {
      key: key,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const login = (email, password) => {
  return new Promise((resolve, reject) => {
    HTTP.post(URL_AUTHENT, {
      email: email,
      password: password,
    })
      .then(function (response) {
        if (isMobile) {
          const items = [
            ["token", response.data.key],
            ["user", JSON.stringify(response.data)],
          ];
          if (
            response.data &&
            response.data.user &&
            response.data.user.picture
          ) {
            items.push(["picture", response.data.user.picture]);
          }
          if (
            response.data &&
            response.data.user &&
            response.data.user.role &&
            mapRole[response.data.user.role]
          ) {
            items.push(["role", mapRole[response.data.user.role]]);
          }
          AsyncStorage.multiSet(items, () => {
            resolve(response);
          });
        } else {
          setAuthorizationToken(response.data.key);
          StorageUtil.setItem("user", JSON.stringify(response.data));
          StorageUtil.setItem(
            "role",
            response.data.user.role ?? mapRole[response.data.user.role]
          );
          resolve(response);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const logout = () => {
  return HTTP.get(URL_LOGOUT)
    .then((resp) => {
      clearSession();

      if (!isMobile) {
        window.location.href = SIGN_IN;
      }
    })
    .catch((e) => {
      clearSession();
      if (!isMobile) {
        window.location.href = SIGN_IN;
      }
    });
};

// const requireAuth = (to, from, next) => {
//   if (!getAuthorizationToken()) {
//     return next({
//       name: 'Sign In',
//     })
//   } else return next()
// }

const profile = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_ACCOUNT_PROFILE)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        reject(error);
      });
  });
};

const updateProfile = (profile) => {
  return new Promise((resolve, reject) => {
    HTTP.put(URL_ACCOUNT_PROFILE, profile)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        if (e.response) {
          let errors = e.response.data;
          let error = [];
          Object.keys(errors).forEach((el) =>
            error.push(el + " : ", errors[el] + "\n" + "")
          );
          reject(error);
        }
        reject(e);
      });
  });
};

const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    return HTTP.post(URL_RESET_PASSWORD, { email: email })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ChangePassword = (password1, password2, uid, key) => {
  return new Promise((resolve, reject) => {
    return HTTP.post(URL_CONFIRM_PASSWORD, {
      uid: uid,
      token: key,
      new_password1: password1,
      new_password2: password2,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const AccountService = {
  login,
  register,
  registerEmailValidation,
  logout,
  profile,
  updateProfile,
  resetPassword,
  ChangePassword,
  postCodeSocial,
};
