const initialState = [{}];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_TICKETS":
      return payload;
    case "GET_SINGLE_USER_TICKETS":
      return payload;
    // creating a generic receipt
    case "CREATE_GENERIC_RECEIPT_CARREPOS":
      return Array.isArray(state) ? [...state, payload] : [payload];
    case "CREATE_GENERIC_RECEIPT_CSI":
      return Array.isArray(state) ? [...state, payload] : [payload];
    case "DELETE_GENERIC_RECEIPT_CSI":
      return Array.isArray(state) ? state.filter((ticket) => ticket.id !== payload) : state;
    case "DELETE_GENERIC_RECEIPT_CARREPOS":
      return Array.isArray(state) ? state.filter((ticket) => ticket.id !== payload) : state;

    default:
      return state;
  }
}
