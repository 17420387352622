import { connect, useDispatch } from "react-redux";
// SOFTWARE COMPONENTS
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiAvatar from "components/SuiAvatar";
// MUI COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import { Rating } from "@mui/material";

import { CheckService } from "@sharecode/common/services/CheckService";

import { DefaultServiceHandler } from "utils/http-common";
// Loading spinner
import { Oval } from "@agney/react-loading";
// logo
import Logo from "assets/images/logos/logo_black.png";
import { useRef, useState } from "react";
const ChatModal = (props) => {
  const message = useRef(null);
  const disp = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleClickEvent = () => {
    const form = message.current;
    setLoading(true);
    CheckService.replyToComment(props.userReview.id, form["message"].value)
      .then((r) => {
        disp(DefaultServiceHandler("success", "success", `Message envoyé !`));
        props.closeModal(false);
      })
      .catch((e) => {
        disp(DefaultServiceHandler("error", "error", e.toString()));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog open={() => props.closeModal(true)} onClose={() => props.closeModal(false)}>
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center">
          <SuiBox component="img" src={Logo} height="70px" />
        </DialogTitle>
        <DialogContent>
          <SuiBox textAlign="center">
            <SuiTypography variant="h5"> Demande de chat avec un client</SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" mt={2}>
            <SuiBox mr={4}>
              <SuiAvatar
                src={props.userReview ? props.userReview.user.picture : " "}
                variant="rounded"
                size="xl"
              />
            </SuiBox>
            <SuiBox display="flex" flexDirection="column">
              <SuiTypography variant="h5" fontWeight="light">
                {props.userReview ? props.userReview.user.fullname : " "}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox mt={2} display="flex" justifyContent="center">
            <Rating value={props.userReview ? parseFloat(props.userReview.rating) : " "} readOnly />
          </SuiBox>
          <SuiBox mt={2}>
            <SuiTypography variant="body2">
              Ajouter un message pour personnaliser votre demande de prise de contact
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={2}>
            <form ref={message}>
              <SuiInput name={"message"} type="text" multiline size="large" rows={5}></SuiInput>
            </form>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={4} display="flex" justifyContent="center">
            <Grid item>
              <SuiButton variant="gradient" color="error" onClick={() => props.closeModal(false)}>
                Annuler
              </SuiButton>
            </Grid>
            <Grid item>
              {loading ? (
                <Oval width="30" color="info" />
              ) : (
                <SuiButton variant="gradient" color="info" onClick={handleClickEvent}>
                  Envoyer
                </SuiButton>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps)(ChatModal);
