/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */

// @mui material components
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Icon from "@mui/material/Icon";
import { Formik, Form, useFormik } from "formik";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";

import { updateTicket, getTickets } from "@sharecode/common/redux/actions/tickets";

import { connect, useDispatch } from "react-redux";
// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenTicketDrawer } from "context";

import { DASHBOARD } from "@sharecode/common/routes/constants";
// Custom styles for the Configurator
import TicketUpdateRoot from "./ticketUpdateRoot";
import { REMOVE_EDITED_BRAND } from "@sharecode/common/redux/actions/actionTypes";
// brandCreateUpdate components
import FormField from "./FormField";
import { isPropsEqual } from "@fullcalendar/react";
import * as Yup from "yup";
import ticketToEdit from "@sharecode/common/redux/reducers/ticketToEdit";
import SuiAvatar from "components/SuiAvatar";
import { DefaultServiceHandler } from "utils/http-common";
import { useSearchParams } from "react-router-dom";
import { useRole } from "routes/ProtectedRoutes";
import { IS_ADMIN_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { BrandService, TicketService } from "@sharecode/common/services";

function ticketUpdate(props) {
  const disp = useDispatch();
  const role = useRole();
  const [controller, dispatch] = useSoftUIController();
  const [searchParams, setSearchParams] = useSearchParams();
  const { openTicketDrawer } = controller;
  const [updateL, setUpdateL] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState();
  const [availableBrands, setAvailableBrands] = useState();
  const [selectedShop, setSelectedShop] = useState();
  const [availableShops, setAvailableShops] = useState();
  const [total, setTotal] = useState("");

  const handleCloseTicketDrawer = () => {
    setOpenTicketDrawer(dispatch, false);
  };

  useEffect(() => {
    if (openTicketDrawer) {
      BrandService.getBrand(null, 500).then((response) => {
        let tmpBrands = [];
        if (response.results) {
          response.results.map((item) => {
            tmpBrands.push({ value: item.id, label: item.name });
          });
        }
        setAvailableBrands(tmpBrands);
      });
    }
  }, [openTicketDrawer]);

  useEffect(() => {
    if (selectedBrand?.value) {
      BrandService.getBrandShops(selectedBrand.value).then((response) => {
        let tmpShops = [];
        if (response.results) {
          response.results.map((item) => {
            tmpShops.push({ value: item.id, label: item.name });
          });
        }
        setAvailableShops(tmpShops);
      });
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (props.ticketToEdit?.id) {
      if (props.ticketToEdit?.brand?.id && props.ticketToEdit?.brand?.name) {
        setSelectedBrand({
          value: props.ticketToEdit.brand.id,
          label: props.ticketToEdit.brand.name,
        });
      } else {
        setSelectedBrand({
          value: "",
          label: "",
        });
      }

      if (props.ticketToEdit?.shop?.id && props.ticketToEdit?.shop?.name) {
        setSelectedShop({
          value: props.ticketToEdit.shop.id,
          label: props.ticketToEdit.shop.name,
        });
      } else {
        setSelectedShop({
          value: "",
          label: "",
        });
      }
      setTotal(props.ticketToEdit.total);
    }
  }, [props.ticketToEdit]);

  const handleSelectBrand = (event) => {
    if (event == null) {
      setSelectedBrand({ value: "", label: "" });
    } else {
      setSelectedBrand({ value: event.value, label: event.label });
      setSelectedShop(null);
    }
  };

  const handleSelectShop = (event) => {
    if (event == null) {
      setSelectedShop({ value: "", label: "" });
    } else {
      setSelectedShop({ value: event.value, label: event.label });
    }
  };

  const handleChangeTotal = (event) => {
    setTotal(event.target.value);
  };

  const saveTicket = () => {
    let data = {
      brand: selectedBrand ? selectedBrand.value : null,
      shop: selectedShop ? selectedShop.value : null,
      total: total,
    };
    // TicketService.updateTicket(props.ticketToEdit.id, data);
    disp(updateTicket(props.ticketToEdit.id, data))
      .then(() => {
        disp(
          getTickets(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        ).then(() => {
          handleCloseTicketDrawer();
          disp(DefaultServiceHandler("success", "success", "Ticket modifié avec succès !"));
        });
      })
      .catch((error) => {
        disp(DefaultServiceHandler("error", "error", "Le ticket n'a pas pu être modifié"));
      });
  };

  return (
    <TicketUpdateRoot variant="permanent" ownerState={{ openTicketDrawer }}>
      <form>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0}
          px={3}
          pl={0}
        >
          <SuiBox>
            <SuiTypography variant="h4" color="primary">
              Modifier un ticket
            </SuiTypography>
          </SuiBox>

          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseTicketDrawer}
          >
            close
          </Icon>
        </SuiBox>
        <Divider />
        <SuiBox mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="name"
                name="name"
                label="Utilisateur"
                disabled={true}
                value={
                  props.ticketToEdit && props.ticketToEdit.user
                    ? props.ticketToEdit.user.first_name + " " + props.ticketToEdit.user.last_name
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    id="validated"
                    name="validated"
                    disabled={true}
                    checked={props.ticketToEdit?.id ? props.ticketToEdit.is_expense_report : false}
                  />
                }
                label="Note de frais"
                style={{ marginLeft: 5, marginTop: 8 }}
              />
            </Grid>
            <Grid item xs={12}>
              <>
                <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Enseigne
                  </SuiTypography>
                </SuiBox>

                <SuiSelect
                  isClearable={true}
                  options={availableBrands}
                  name="brand"
                  onChange={(e) => {
                    handleSelectBrand(e);
                  }}
                  defaultValue={selectedBrand}
                  value={selectedBrand}
                />
              </>
            </Grid>
            <Grid item xs={12}>
              <>
                <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Point de vente
                  </SuiTypography>
                </SuiBox>

                <SuiSelect
                  isClearable={true}
                  options={availableShops}
                  name="shop"
                  onChange={(e) => {
                    handleSelectShop(e);
                  }}
                  defaultValue={selectedShop}
                  value={selectedShop}
                />
              </>
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="number"
                id="total"
                name="total"
                label="Total"
                value={total}
                onChange={(e) => handleChangeTotal(e)}
              />
            </Grid>
            {/* <Grid item xs={12} mt={2}>
            <FormField type="text" label="Manager" placeholder="" />
          </Grid>
          <Grid item xs={12} mt={2} >
            <SuiBox ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Type d'enseigne
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              defaultValue={{ value: "clothing", label: "Clothing" }}
              options={[
                { value: "clothing", label: "Clothing" },
                { value: "electronics", label: "Electronics" },
                { value: "furniture", label: "Furniture" },
                { value: "others", label: "Others" },
                { value: "real estate", label: "Real Estate" },
              ]}
            />
          </Grid> */}
            <Grid container spacing={2} display="flex" justifyContent="space-between" mt={2} ml={0}>
              <Grid item xs={6}>
                <SuiButton variant="gradient" color="error" onClick={handleCloseTicketDrawer}>
                  Annuler
                </SuiButton>
              </Grid>
              <Grid item xs={6}>
                <SuiButton variant="gradient" color="info" onClick={saveTicket}>
                  Valider
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </form>
    </TicketUpdateRoot>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ticketUpdate);
