import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// utils
import { months, monthMap } from "utils/date-utils";

export const useViewsChartData = (
  periodicity,
  selectedCampaignIds,
  selectedStoryIds,
  selectedBrandIds,
  startDate,
  endDate
) => {
  const stats = useSelector((state) => {
    const reducer = state.campaignReducer;
    switch (periodicity) {
      case "daily":
        return reducer.dailyAdminStats;
      case "weekly":
        return reducer.weeklyAdminStats;
      case "monthly":
        return reducer.monthlyAdminStats;
      default:
        return {};
    }
  });

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{ data: [], color: "primary" }],
  });

  const getDateLabel = (dateString, periodicity) => {
    const date = new Date(dateString);
    if (periodicity === "weekly") {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const days = Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000));
      const weekNum = Math.ceil((days + firstDayOfYear.getDay() + 1) / 7);
      return `Semaine ${weekNum}`;
    } else if (periodicity === "monthly") {
      return months[date.getMonth()];
    }
    return dateString?.split("T")[0];
  };

  useEffect(() => {
    let aggregatedData = {};

    Object.entries(stats).forEach(([brandId, brandStats]) => {
      if (selectedBrandIds.length === 0 || selectedBrandIds.includes(parseInt(brandId))) {
        brandStats.data
          ?.filter((stat) => {
            const isCampaignSelected =
              selectedCampaignIds.length === 0 || selectedCampaignIds.includes(stat.adsCampaignID);
            const isStorySelected =
              selectedStoryIds.length === 0 || selectedStoryIds.includes(stat.adsID);

            const statDate = new Date(stat.statDate?.split("T")[0]);
            const startDateObj = startDate ? new Date(startDate) : null;
            const endDateObj = endDate ? new Date(endDate) : null;

            const isInDateRange =
              (!startDateObj || statDate >= startDateObj) &&
              (!endDateObj || statDate <= endDateObj);

            return isCampaignSelected && isStorySelected && isInDateRange;
          })
          .forEach((stat) => {
            let label = getDateLabel(stat.statDate, periodicity);
            aggregatedData[label] = (aggregatedData[label] || 0) + stat.totalViewed;
          });
      }
    });

    const labels = Object.keys(aggregatedData).sort((a, b) => {
      if (periodicity === "monthly") {
        return monthMap[a] - monthMap[b];
      }
      return a.localeCompare(b);
    });
    const data = labels.map((label) => aggregatedData[label]);

    setChartData({
      labels,
      datasets: [{ label: "Nombre de vues", data, color: "primary" }],
    });
  }, [
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    selectedBrandIds,
    startDate,
    endDate,
    stats,
  ]);

  return chartData;
};

export default useViewsChartData;
