/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    campaignName: {
      name: "name",
      label: "Nom de votre campagne",
      type: "text",
      placeholder: "Ecrire un nom pour votre campagne",
      errorMsg: "Le nom est requis.",
      description: "Ce nom est interne à votre entreprise",
    },
    maxBudget: {
      name: "budget",
      label: "Budget maximum",
      type: "number",
      placeholder: "Choisir un budget à ne pas dépasser",
      errorMsg: "Le budget maximum est requis.",
      description: "La campagne se coupera une fois le budget atteint",
    },
    costPerClick: {
      name: "costPerClick",
      label: "Coût par clic",
      type: "number",
      placeholder:
        "Le montant du coût par clic définit votre position d’affichage face à vos concurrents",
      errorMsg: "Un coût par clic minimum de 0.10€ est requis.",
      description:
        "Le montant du coût par clic définit votre position d’affichage face à vos concurrents",
    },
    storyName: {
      name: "storyName",
      label: "Nom de votre story",
      type: "text",
      placeholder: "Ecrire un nom",
      errorMsg: "Le nom de la story est requis.",
      description: "Ce nom est interne à votre entreprise",
    },
    visualChoice: {
      name: "visualChoice",
      label: "Choix du visuel",
      type: "file",
      placeholder: "Cliquer pour télécharger",
      errorMsg: "Le choix du visuel est requis.",
      description: "Téléchargez une photo ou vidéo",
    },
    redirectURL: {
      name: "redirectURL",
      label: "URL de redirection",
      type: "text",
      placeholder: "Ecrire un lien web",
      errorMsg: "L'URL de redirection est requise.",
      description: "Insérez le lien où diriger vos potentiels clients",
    },
    budgetSplit: {
      name: "budgetSplit",
      label: "Répartition du budget",
      type: "number",
      placeholder: "Choisir une répartition",
      errorMsg: "La répartition du budget est requise.",
      description: "La story se coupera une fois le budget atteint",
    },
    countries: {
      name: "countries",
      label: "Pays ciblés",
      type: "select",
      placeholder: "Choisir des pays",
      errorMsg: "Les pays ciblés sont requis.",
      description: "Choisissez les pays où diffuser votre campagne",
    },
  },
};

export default form;
