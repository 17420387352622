import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { SIGN_IN } from "@sharecode/common/routes/constants";

export const useAuth = () => {
    return localStorage.getItem('user');
};

export const useRole = () => {
    return localStorage.getItem('role');
};

export const getUserPicture = () => {
    if (localStorage.getItem('user') === null) {
        return;
    } else {
        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        return user.user.picture;
    } 
};

const ProtectedRoutes = (props) => {
    const isAuth = useAuth();
    return !isAuth ? <Navigate to={SIGN_IN} /> : props.allowedRoles.includes(useRole()) ? <Outlet /> : <Navigate to="/" />
};

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps)(ProtectedRoutes);