import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import SuiTypography from "components/SuiTypography";

export function TicketsNoBrandFilter({ handleTicketsSignsFilter, searchParams }) {
  return (
    <FormGroup sx={{ paddingLeft: 2, paddingRight: 2, maxWidth: 265 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={searchParams.get("unassigned") === "true" ? true : false}
            onChange={handleTicketsSignsFilter}
          />
        }
        label={<SuiTypography variant="caption">Tickets sans enseignes reconnues</SuiTypography>}
      />
    </FormGroup>
  );
}
