import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, connect, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

// components
import SuiBox from "components/SuiBox";
import { DeleteModal } from "components/DeleteModal";
// redux actions
import { deletePosItem, setSelectedBusinessToEdit, getPosItemById, getPosItem } from "@sharecode/common/redux/actions/posActions";
// context
import { setOpenBusinessDrawer, useSoftUIController } from "context";


function ActionCell({ infoItemId }) {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [controller, softDispatch] = useSoftUIController();

  const selectedPartnerId = useSelector((state) => state?.posReducer?.selectedPartnerId);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  // Function to handle business editing
  const handleEditBusiness = () => {
    dispatch(setSelectedBusinessToEdit(infoItemId));
    dispatch(getPosItemById(selectedPartnerId, infoItemId));
    setOpenBusinessDrawer(softDispatch, true);
  };

  // Function to handle pos business deletion
  const handleDeleteBusiness = () => {
    setLoadingDelete(true);
    dispatch(deletePosItem(selectedPartnerId, infoItemId))
      .then(() => {
        setLoadingDelete(false);
        setDialogOpen(false);
        dispatch(getPosItem(selectedPartnerId));
        navigate("/manage-pos-partners/" + selectedPartnerId + "/business");
        // Update searchParams to trigger a refresh
        setSearchParams({ ...Object.fromEntries(searchParams), refresh: Date.now() });
      })
      .catch((error) => {
        setLoadingDelete(false);
        setDialogOpen(false);
        console.error("Error deleting business:", error);
      });
  };

  const iconStyle = {
    color: "white",
    backgroundColor: "#2794F9",
    borderRadius: "5px",
    fontSize: "20px",
    padding: "3px",
  };

  return (
    <>
      <DeleteModal
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleDelete={handleDeleteBusiness}
        objectId={businessId}
        loading={loadingDelete}
      />
      <SuiBox display="flex" alignItems="center">
        <SuiBox>
          <Tooltip title="Edit Business">
            <IconButton onClick={handleEditBusiness}>
              <FontAwesomeIcon icon={faPenToSquare} style={iconStyle} />
            </IconButton>
          </Tooltip>
        </SuiBox>
        <SuiBox>
          <Tooltip title="Delete Business">
            <IconButton onClick={() => setDialogOpen(true)}>
              <FontAwesomeIcon icon={faTrashCan} style={iconStyle} />
            </IconButton>
          </Tooltip>
        </SuiBox>
      </SuiBox>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
