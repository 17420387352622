/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */

import * as React from "react";
import { sharedVariable } from "@sharecode/common";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import Socials from "layouts/authentication/components/Socials";

// Image
import signin from "assets/images/clip-internet-security.png";

// MUI components
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Divider from "@mui/material/Divider";

import { DASHBOARD } from "@sharecode/common/routes/constants";
// utils
import checkEmail from "@sharecode/common/utils/validator";
import { useFormik } from "formik";

// redux
import { useDispatch, useSelector } from "react-redux";
import { login } from "@sharecode/common/redux/actions/auth";
import { DefaultServiceHandler } from "utils/http-common";

// Loading spinner
import { Oval } from "@agney/react-loading";
import { RESET_PASSWORD, SIGN_UP } from "@sharecode/common/routes/constants";
import { FCMService } from "@sharecode/common/services/FCMService";

function SignIn() {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const [values, setValues] = React.useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Requis";
    } else if (!checkEmail(values.email)) {
      errors.email = "Adresse email invalide";
    }

    if (!values.password) {
      errors.password = "Requis";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: () => {
      setLoading(true);
      dispatch(login(formik.values.email, formik.values.password))
        .then(() => {
          setTimeout(() => {
            navigate(DASHBOARD);
          }, 500);
        })
        .then(() => {
          // FCMService.registerDevice();
        })
        .catch((error) => {
          dispatch(DefaultServiceHandler("error", "error", error.toString()));
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  return (
    <IllustrationLayout
      title="Connexion"
      description="Entrez votre e-mail et votre mot de passe pour accéder à votre compte."
      illustration={{
        image: signin,
      }}
    >
      <SuiBox component="form" role="form" onSubmit={formik.handleSubmit}>
        <SuiBox mb={formik.touched.email && formik.errors.email != null ? 0 : 2}>
          <SuiInput
            id="email"
            type="email"
            placeholder="Email"
            size="large"
            error={formik.touched.email && formik.errors.email != null}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              {formik.touched.email && formik.errors.email ? formik.errors.email : <></>}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          position="relative"
          mb={formik.touched.password && formik.errors.password != null ? 0 : 2}
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <SuiInput
            id="password"
            type={values.showPassword ? "text" : "password"}
            placeholder="Mot de passe"
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password != null}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="large"
            icon={{
              direction: "right",
            }}
          />
          <SuiBox mt={0.5} position="absolute" top="0" right="0">
            <IconButton onClick={handleClickShowPassword} color="primary">
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              {formik.touched.password && formik.errors.password ? formik.errors.password : <></>}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          mt={formik.touched.password && formik.errors.password != null ? 0 : 4}
          mb={1}
          textAlign="center"
        >
          {loading ? (
            <Oval width="30" color="info" />
          ) : (
            <SuiButton variant="gradient" color="info" size="large" fullWidth type="submit">
              Connexion
            </SuiButton>
          )}
        </SuiBox>
        {/* <SuiBox mt={2} mb={1} textAlign="center">
          <SuiTypography variant="body2" color="text" fontWeight="regular">
            Ou connectez-vous avec
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={2}>
          <Socials />
        </SuiBox> */}
        <Divider />
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            Vous n'avez pas de compte ?{" "}
            <SuiTypography
              component={Link}
              to={SIGN_UP}
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              S'inscrire
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={1} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            <SuiTypography
              component={Link}
              to={RESET_PASSWORD}
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Mot de passe oublié ?
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  );
}

export default SignIn;
