import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Paper, Typography, Grid, Divider, List, ListItem, Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { styled } from "@mui/system";

import PropTypes from "prop-types";

// redux actions
import {
  validateCampaign,
  sendMessageRejection,
  rejectCampaign,
} from "@sharecode/common/redux/actions/campaignsActions";

// Styled components
const BudgetBox = styled("div")({
  backgroundColor: "#2794f9",
  borderRadius: "5px",
  height: "35px",
  width: "98px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  margin: "0 auto",
});

const NameBox = styled("div")(({ theme }) => ({
  backgroundColor: "#344767",
  borderRadius: theme.shape.borderRadius,
  height: "35px",
  width: "50%",
  maxWidth: "60%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  padding: theme.spacing(0, 2),
  margin: "0 auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  [theme.breakpoints.up("md")]: {
    maxWidth: "300px",
  },

  [theme.breakpoints.up("lg")]: {
    maxWidth: "400px",
  },

  [theme.breakpoints.up("xl")]: {
    maxWidth: "500px",
  },
}));

const RedColorButton = styled(Button)({
  backgroundColor: "#f44336",
  color: "white",
  "&:hover": {
    backgroundColor: "#d32f2f",
  },
});

const GreenColorButton = styled(Button)({
  backgroundColor: "#4caf50",
  color: "white",
  "&:hover": {
    backgroundColor: "#388e3c",
  },
});

const CampaignSummary = ({
  campaignName,
  maxBudget,
  costPerClick,
  createdStories,
  onCampaignValidation,
  changeActiveStep,
  onToggleForm,
  existingCampaignId,
  setIsLoading,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [showRejectInput, setShowRejectInput] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Toggle the visibility of the rejection text area
  const handleShowRejectInput = () => {
    setShowRejectInput(!showRejectInput);
  };

  // Clear the rejection reason and hide the rejection input
  const handleCancelRejection = () => {
    setRejectReason("");
    setShowRejectInput(false);
  };

  // Function to show the Snackbar with a custom message
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to handle closing the Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  // selector function to get the latest campaign
  const selectLatestCampaign = (state) => {
    const campaigns = state.campaignReducer.campaigns;
    return campaigns && campaigns.length > 0 ? campaigns[campaigns.length - 1] : null;
  };
  // Use the selector to get the latest campaign (used of campaign creation)
  const latestCampaign = useSelector(selectLatestCampaign);

  const maxBudgetInteger = parseInt(maxBudget);
  const clickBidIntegerCents = parseInt(costPerClick * 100);
  const maxBudgetIntegerCent = maxBudgetInteger * 100;
  const campaignClickLimit = Math.floor(maxBudgetIntegerCent / clickBidIntegerCents);

  const onEditStories = () => {
    onToggleForm(false); // Hide the story creation form
    changeActiveStep(1); // Go back to the story creation step
  };

  const handleValidateCampaign = () => {
    setIsLoading(true);
    showSnackbar("Validation de la campagne en cours");
    // dispatch the validateCampaign action
    dispatch(validateCampaign(existingCampaignId))
      .then((response) => {
        showSnackbar("Campaign validated successfully");
        setIsLoading(false);
        onCampaignValidation();
        navigate("/manage-campaigns-admin");
      })
      .catch((error) => {
        showSnackbar("Error validating campaign");
        setIsLoading(false);
      });
  };

  // function to reject the campaign
  const handleRejectCampaign = () => {
    if (!rejectReason) {
      showSnackbar("Veuillez entrer une raison de rejet");
      return;
    }
    setIsLoading(true);
    showSnackbar("Rejet de la campagne en cours");
    // dispatch the rejectCampaign action
    dispatch(rejectCampaign(existingCampaignId));
    dispatch(sendMessageRejection(existingCampaignId, rejectReason))
      .then((response) => {
        showSnackbar("Campagne rejetée avec succès");
        setIsLoading(false);
        navigate("/manage-campaigns-admin");
      })
      .catch((error) => {
        showSnackbar("Echec du rejet de la campagne");
        setIsLoading(false);
      });
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px", margin: "auto" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => changeActiveStep(0)}>
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{ color: "white", backgroundColor: "#2794F9", padding: "10px" }}
            />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ color: "#344767", textAlign: "left", fontFamily: "Inter", fontWeight: 700 }}
          >
            Étape 1: Création campagne
          </Typography>
        </Box>
        <Divider light />
        <List>
          <ListItem disableGutters sx={{ justifyContent: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#344767", fontFamily: "Inter", fontWeight: 300 }}
            >
              Nom campagne:{" "}
              <Typography component="span" sx={{ color: "#2794F9" }}>
                {campaignName}
              </Typography>
            </Typography>
          </ListItem>
          <ListItem disableGutters sx={{ justifyContent: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#344767", fontFamily: "Inter", fontWeight: 300 }}
            >
              Budget maximum:{" "}
              <Typography component="span" sx={{ color: "#2794F9" }}>
                {maxBudget}€
              </Typography>
            </Typography>
          </ListItem>
          <ListItem disableGutters sx={{ justifyContent: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#344767", fontFamily: "Inter", fontWeight: 300 }}
            >
              Coût par clic:{" "}
              <Typography component="span" sx={{ color: "#2794F9" }}>
                {costPerClick}€
              </Typography>
            </Typography>
          </ListItem>
        </List>
      </Paper>

      <Paper elevation={3} sx={{ padding: "20px", margin: "auto", marginTop: "20px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => onEditStories()}>
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{ color: "white", backgroundColor: "#2794F9", padding: "10px" }}
            />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ color: "#344767", textAlign: "left", fontFamily: "Inter", fontWeight: 700 }}
          >
            Étape 2: Création stories
          </Typography>
        </Box>

        <Divider light />
        <List>
          {createdStories.map((story, index) => (
            <ListItem
              key={index}
              disableGutters
              sx={{
                justifyContent: "center",
                display: "flex",
                gap: "16px", // Increase gap
                alignItems: "center",
                marginBottom: "10px", // Add space between list items
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "#344767", fontFamily: "Inter", fontWeight: 800 }}
              >
                {index + 1}.
              </Typography>
              <NameBox>{story.storyName}</NameBox>
              <BudgetBox>{story.budgetSplit}€</BudgetBox>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Grid container justifyContent={"space-around"} sx={{ marginTop: "20px" }}>
        <Grid item>
          {!showRejectInput && (
            <RedColorButton variant="contained" onClick={handleShowRejectInput}>
              Rejeter la campagne
            </RedColorButton>
          )}

          {showRejectInput && (
            <Box sx={{ mt: 2 }}>
              <label htmlFor="reject-reason">Motif:</label>
              <textarea
                id="reject-reason"
                style={{
                  width: "100%",
                  height: "100px",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              />
              <Box sx={{ display: "flex", gap: 2 }}>
                <GreenColorButton variant="contained" onClick={handleCancelRejection}>
                  Annuler le rejet
                </GreenColorButton>
                <RedColorButton variant="contained" onClick={handleRejectCampaign}>
                  Confirmer le rejet
                </RedColorButton>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item>
          {!showRejectInput && (
            <GreenColorButton variant="contained" onClick={handleValidateCampaign}>
              Valider la campagne
            </GreenColorButton>
          )}
        </Grid>
      </Grid>
      {/* Snackbar for displaying messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

CampaignSummary.propTypes = {
  campaignName: PropTypes.string.isRequired,
  maxBudget: PropTypes.number.isRequired,
  costPerClick: PropTypes.number.isRequired,
  createdStories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      budgetSplit: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default CampaignSummary;
