import axios from 'axios';
import { AUTH_BASE_URL } from './constantApi';

const AuthService = {
  updateToken: (userId, token) => {
    return axios.put(
      `${AUTH_BASE_URL}/user/${userId}/token`,
      { key: token },
      { headers: { 'Content-Type': 'application/json' } }
    );
  },
};

export default AuthService;
