/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import * as React from "react";

// services
import { AccountService } from "@sharecode/common/services";

// redux
import { useDispatch } from "react-redux";
import { DefaultServiceHandler } from "utils/http-common";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// MUI components
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// utils
import { checkPassword } from "@sharecode/common/utils/validator";
import { useFormik } from "formik";

import { Oval } from "@agney/react-loading";
import { SIGN_IN } from "@sharecode/common/routes/constants";

function ChangePassword() {
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const validate = (values) => {
    const errors = {};

    if (!values.new_password1) {
      errors.new_password1 = "Champs requis";
    } else if (!checkPassword(values.new_password1)) {
      errors.new_password1 =
        "Doit contenir au moins 8 caractères, 1 lettre majuscule, 1 lettre minuscule et 1 chiffre.";
    }

    if (!values.new_password2) {
      errors.new_password2 = "Champs requis";
    } else if (values.new_password2 != values.new_password1) {
      errors.new_password2 = "Les mots de passe ne correspondent pas";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      new_password1: "",
      new_password2: "",
    },
    validate,
    onSubmit: () => {
      setLoading(true);
      AccountService.ChangePassword(
        formik.values.new_password1,
        formik.values.new_password2,
        params.uid,
        params.key
      )
        .then(() => {
          dispatch(
            DefaultServiceHandler(
              "success",
              "success",
              "Mot de passe modifié avec succès, Vous pouvez retourner sur l'application."
            )
          );
          /*    setTimeout(() => {
            navigate(SIGN_IN);
          }, 3000); */
        })
        .catch((error) => {
          dispatch(DefaultServiceHandler("error", "error", error.toString()));
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  return (
    <IllustrationLayout
      color="info"
      illustration={{
        title: "Un e-mail de confirmation a été envoyé à votre adresse.",
        description: "Veuillez attendre la validation.",
      }}
    >
      <SuiBox component="form" role="form" onSubmit={formik.handleSubmit}>
        <SuiBox
          position="relative"
          mb={formik.touched.new_password1 && formik.errors.new_password1 != null ? 0 : 2}
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <SuiInput
            id="new_password1"
            type={values.showPassword ? "text" : "password"}
            placeholder="Mot de passe"
            value={formik.values.new_password1}
            error={formik.touched.new_password1 && formik.errors.new_password1 != null}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="large"
            icon={{
              direction: "right",
            }}
          />
          <SuiBox mt={0.5} position="absolute" top="0" right="0">
            <IconButton onClick={handleClickShowPassword} color="info">
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              {formik.touched.new_password1 && formik.errors.new_password1 ? (
                formik.errors.new_password1
              ) : (
                <></>
              )}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          mb={formik.touched.new_password2 && formik.errors.new_password2 != null ? 0 : 2}
          position="relative"
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <SuiInput
            id="new_password2"
            type={values.showConfirmPassword ? "text" : "password"}
            placeholder="Confirmer mot de passe"
            icon={{
              direction: "right",
            }}
            size="large"
            error={formik.touched.new_password2 && formik.errors.new_password2 != null}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.new_password2}
          />

          <SuiBox mt={0.5} position="absolute" top="0" right="0">
            <IconButton onClick={handleClickShowConfirmPassword} color="info">
              {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              {formik.touched.new_password2 && formik.errors.new_password2 ? (
                formik.errors.new_password2
              ) : (
                <></>
              )}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          mt={formik.touched.new_password2 && formik.errors.new_password2 != null ? 0 : 2}
          mb={1}
        >
          {loading ? (
            <SuiBox textAlign="center">
              <Oval width="30" color="info" />
            </SuiBox>
          ) : (
            <SuiButton variant="gradient" color="info" size="large" fullWidth type="submit">
              Changer mot de passe
            </SuiButton>
          )}
        </SuiBox>
      </SuiBox>
      {/* <SuiBox mt={2} textAlign="center">
        <SuiTypography variant="button" color="text" fontWeight="regular">
          return to{" "}
          <SuiTypography
            component={Link}
            to={SIGN_IN}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Sign in
          </SuiTypography>
        </SuiTypography>
      </SuiBox> */}
    </IllustrationLayout>
  );
}

export default ChangePassword;
