import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare, faEye } from "@fortawesome/free-solid-svg-icons";

// components
import SuiBox from "components/SuiBox";
import { DeleteModal } from "components/DeleteModal";
// redux actions
import { setSelectedPartnerId, deletePosPartner, getPosItem,setSelectedPartnerToEdit, getPosPartnerById } from "@sharecode/common/redux/actions/posActions";
// context
import { setOpenPartnerDrawer, useSoftUIController } from "context";


function ActionCell({ id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, softDispatch] = useSoftUIController();


  // Function to handle partner details viewing
  const handleViewDetails = () => {
    dispatch(setSelectedPartnerId(id));
    dispatch(getPosItem(id));
    navigate("/manage-pos-partners/" + id + "/business");
  };

  // Function to handle partner editing
const handleEditPartner = () => {
  dispatch(setSelectedPartnerToEdit(id));
  dispatch(getPosPartnerById(id));
  setOpenPartnerDrawer(softDispatch, true);
};


  // Function to handle partner deletion
  const handleDeletePartner = (partnerId) => {
    setLoadingDelete(true);
    dispatch(deletePosPartner(partnerId))
      .then(() => {
        setLoadingDelete(false);
        setDialogOpen(false);
        // Update searchParams to trigger a refresh
        setSearchParams({ ...Object.fromEntries(searchParams), refresh: Date.now() });
      })
      .catch((error) => {
        setLoadingDelete(false);
        setDialogOpen(false);
        console.error("Error deleting partner:", error);
      });
  };

  const iconStyle = {
    color: "white",
    backgroundColor: "#2794F9",
    borderRadius: "5px",
    fontSize: "20px",
    padding: "3px",
  };

  return (
    <>
      <DeleteModal
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleDelete={() => handleDeletePartner(id)}
        objectId={id}
        loading={loadingDelete}
      />
      <SuiBox display="flex" alignItems="center">
        <SuiBox>
          <Tooltip title="View Partner Details">
            <IconButton onClick={handleViewDetails}>
              <FontAwesomeIcon icon={faEye} style={iconStyle} />
            </IconButton>
          </Tooltip>
        </SuiBox>
        <SuiBox>
          <Tooltip title="Edit Partner">
            <IconButton onClick={handleEditPartner}>
              <FontAwesomeIcon icon={faPenToSquare} style={iconStyle} />
            </IconButton>
          </Tooltip>
        </SuiBox>
        <SuiBox>
          <Tooltip title="Delete Partner">
            <IconButton onClick={() => setDialogOpen(true)}>
              <FontAwesomeIcon icon={faTrashCan} style={iconStyle} />
            </IconButton>
          </Tooltip>
        </SuiBox>
      </SuiBox>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
