import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";

export function SizeSelectorFilter({ sizeValue, handleSizeChange, totalCount }) {
  return (
    <SuiBox display="flex" alignItems="center" gap={2} sx={{ flexShrink: 0 }}>
      <SuiSelect
        placeholder="Elements par page"
        options={[
          { value: "10", label: "10" },
          { value: "20", label: "20" },
          { value: "50", label: "50" },
          { value: "100", label: "100" },
          { value: "200", label: "200" },
        ]}
        onChange={(e) => handleSizeChange(e)}
        value={sizeValue}
      />

      <SuiBox
        style={{
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Total: {totalCount}
      </SuiBox>
    </SuiBox>
  );
}
