// @ts-nocheck
import axios from 'axios';
import {
  API_BASE_URL,
  getAuthorizationToken,
  RETAILER_ADS_BASE_URL,
  STATS_BY_DATE_BASE_URL,
  ADMIN_STATS_BY_DATE_BASE_URL,
  URL_NOTIFY_ADMIN_EMAIL_CAMPAIGN_APPROVAL
} from './constantApi';
import { sendEmailToAdmin } from '../redux/actions/campaignsActions';

const CampaignService = {
  getCampaigns: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${RETAILER_ADS_BASE_URL}/campaigns`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },

  createCampaign: (campaignData) => {
    return getAuthorizationToken().then((token) => {
      return axios.post(
        `${RETAILER_ADS_BASE_URL}/campaigns`,
        campaignData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getCampaignDetails: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/campaigns/${campaignId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getCampaignAds: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/campaigns/${campaignId}/ads`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },
  deleteCampaign: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.delete(
        `${RETAILER_ADS_BASE_URL}/campaigns/${campaignId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },
  editCampaign: (campaignId, campaignData) => {
    return getAuthorizationToken().then((token) => {
      return axios.put(
        `${RETAILER_ADS_BASE_URL}/campaigns/${campaignId}`,
        campaignData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },
  getCampaignStats: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/stat/campaigns/${campaignId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  updateCampaignClickBid: (campaignId, clickBid) => {
    return getAuthorizationToken().then((token) => {
      return axios.patch(
        `${RETAILER_ADS_BASE_URL}/campaigns/${campaignId}/clickbid`,
        { clickBid }, // Send the clickBid in the request body
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getAdminCampaigns: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${RETAILER_ADS_BASE_URL}/admin/campaigns`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },

  getAdminCampaign: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/admin/campaigns/${campaignId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getAdminCampaignDetails: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/admin/campaigns/${campaignId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getAdminCampaignAds: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/admin/campaigns/${campaignId}/ads`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getAdminCampaignsStats: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/stat/admin/campaigns/${campaignId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  deleteAdminCampaign: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.delete(
        `${RETAILER_ADS_BASE_URL}/admin/campaigns/${campaignId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  // Fetch daily stats for campaigns
  getDailyStats: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${STATS_BY_DATE_BASE_URL}/daily`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },
  // Fetch monthly stats for campaigns
  getMonthlyStats: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${STATS_BY_DATE_BASE_URL}/monthly`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },
  // Fetch weekly stats for campaigns
  getWeeklyStats: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${STATS_BY_DATE_BASE_URL}/weekly`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },

  // get brands with a a campaign
  getBrandsWithCampaign: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${API_BASE_URL}/api/v1/brand/admin/retailer/active`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  // fetch daily stats for admin campaigns by brand
  getDailyAdminStatsByBrand: (brandId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${ADMIN_STATS_BY_DATE_BASE_URL}/${brandId}/timeperiod/daily`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },
  // fetch monthly stats for admin campaigns by brand
  getMonthlyAdminStatsByBrand: (brandId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${ADMIN_STATS_BY_DATE_BASE_URL}/${brandId}/timeperiod/monthly`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },
  // fetch weekly stats for admin campaigns by brand
  getWeeklyAdminStatsByBrand: (brandId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${ADMIN_STATS_BY_DATE_BASE_URL}/${brandId}/timeperiod/weekly`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getAverageClickBidByCountry: (countryCode) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/campaign/clickbid/country/${countryCode}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  // validate campaign and update the status
  validateCampaign: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.patch(
        `${RETAILER_ADS_BASE_URL}/admin/campaigns/${campaignId}/status`,
        { toggle: true }, // Send the toggle in the request body
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  // reject campaign and update the status
  rejectCampaign: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.patch(
        `${RETAILER_ADS_BASE_URL}/admin/campaigns/${campaignId}/status`,
        { toggle: false }, // Send the toggle in the request body
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  // reject campaign with a message with a post request
  sendMessageRejection: (campaignId, message) => {
    return getAuthorizationToken().then((token) => {
      return axios.post(
        `${RETAILER_ADS_BASE_URL}/admin/campaigns/${campaignId}/rejection`,
        { message: message }, // Send the message in the request body
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  // send email to admin for campaign approval
sendEmailToAdmin: (subject, body, email) => {
  return getAuthorizationToken().then((token) => {
    return axios.post(
      `${URL_NOTIFY_ADMIN_EMAIL_CAMPAIGN_APPROVAL}`,
      { subject, body: body.replace(/\n/g, '<br>'), email },
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
  });
},

};

export default CampaignService;
