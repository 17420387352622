/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import { useDispatch, connect } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import { AccountService } from "@sharecode/common/services/AccountService";
import { useEffect, useState } from "react";
import { updateProfile } from "@sharecode/common/redux/actions/auth";
// Loading spinner
import { Oval } from "@agney/react-loading";

import { DefaultServiceHandler } from "utils/http-common";
import { listRole } from "@sharecode/common/redux/actions/actionTypes";
const Input = styled("input")({
  display: "none",
});

function Header(props) {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const dispatch = useDispatch();
  const [selectedPicture, setSelectedPicture] = useState("");

  useEffect(() => {
    AccountService.profile().then((r) => {
      setUserData(r);
      setSelectedPicture(r.picture);

      listRole.map((item) => {
        if (item.value === r.role) {
          setRole(item.label);
        }
      });
    });
  }, []);

  const change = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("picture", e.currentTarget.files[0]);

    dispatch(updateProfile(formData))
      .then((response) => {
        dispatch(
          DefaultServiceHandler("success", "success", "Votre photo de profil a été mise à jour!")
        );
        setTimeout(function () {
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          DefaultServiceHandler(
            "error",
            "error",
            "Erreur lors de la mise à jour de votre photo de profil."
          )
        );
      });
  };

  return (
    <Card id="profile">
      <SuiBox p={2} component="form">
        <Grid container spacing={3} alignItems="center">
          <Grid item mt={1}>
            {loading ? (
              <SuiBox display="flex" alignItems="flex-start">
                <Oval width="30" color="info" />
              </SuiBox>
            ) : (
              <SuiBox position="relative" height="max-content" mx="auto">
                <SuiAvatar
                  src={props.auth.picture}
                  alt="profile-image"
                  size="xl"
                  variant="rounded"
                  shadow="sm"
                />
                <SuiBox position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                  <label htmlFor="icon-button-file">
                    <Input accept="image/*" id="icon-button-file" type="file" onChange={change} />
                    <SuiButton
                      variant="gradient"
                      color="light"
                      size="small"
                      iconOnly
                      aria-label="upload picture"
                      component="span"
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </label>
                </SuiBox>
              </SuiBox>
            )}
          </Grid>
          <Grid item>
            <SuiBox height="100%" mt={0.5} lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="medium">
                {userData.first_name} {userData.last_name}
              </SuiTypography>
              <SuiTypography variant="button" color="text" fontWeight="medium">
                {userData.brand ? role : ""}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(Header);
