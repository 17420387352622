/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Provider, connect } from "react-redux";
import { store } from "@sharecode/common/redux/store";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import "assets/theme/styles/fade.css";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";

import Dashboard from "layouts/pages/dashboard";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import { useAuth } from "routes/ProtectedRoutes";
// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav } from "context";

import { SIGN_IN } from "@sharecode/common/routes/constants";
import CustomizedSnackbars from "examples/Snackbar";

import {
  IS_ADMIN,
  IS_EMPLOYEE_BRAND,
  IS_EMPLOYEE_SHOP_VALUE,
  IS_MANAGER_BRAND,
  IS_MANAGER_SHOP,
} from "@sharecode/common/redux/actions/actionTypes";
import { useAvailableRoutes } from "routes/routes";
// Images
import brand from "assets/images/logos/logo_black.png";
import { getUserInfos } from "@sharecode/common/services/constantApi";
import SuiAlert from "components/SuiAlert";
import SignIn from "layouts/authentication/sign-in";

import { employeeShopRoutes } from "routes/routes";
import { firebaseConfig } from "@sharecode/common/firebaseConfig";
import { getMessaging, onMessage } from "firebase/messaging";

const App = (props) => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, sidenavColor, layout } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  // Open sidenav when mouse enter on mini sidenav
  const authentifiedUser = useAuth();
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  //init
  firebaseConfig.app;
  // const messaging = getMessaging();
  // onMessage(messaging, (payload) => {
  //   console.log('Message received. ', payload);
  //   // ...
  // });

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location.pathname]);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {props.alert && Object.keys(props.alert).length !== 0 && (
          <SuiAlert
            color={props.alert.color}
            dismissible={props.alert.dismissible}
            style={{ position: "absolute", width: "100%", zIndex: 99999 }}
          >
            <div>{props.alert.children}</div>
          </SuiAlert>
        )}
        <CssBaseline />
        <CustomizedSnackbars />
        {layout === "dashboard" && (
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Soft UI Dashboard PRO"
            routes={useAvailableRoutes()}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        <div
          className={`${transitionStage}`}
          onAnimationEnd={() => {
            if (transitionStage === "fadeOut") {
              setTransistionStage("fadeIn");
              setDisplayLocation(location);
            }
          }}
        >
          <Routes location={displayLocation}>
            {getRoutes(useAvailableRoutes())}

            <Route path="*" element={authentifiedUser ? <Dashboard /> : <SignIn />} />
          </Routes>
        </div>
      </ThemeProvider>
    </Provider>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(App);
