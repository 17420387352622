import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const useCpcChartData = (
  periodicity,
  selectedCampaignIds,
  selectedStoryIds,
  selectedBrandIds,
  startDate,
  endDate
) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{ data: [], color: "info" }],
  });

  const campaigns = useSelector((state) => state.campaignReducer.campaigns.data);
  const campaignAdsById = useSelector((state) => state.campaignReducer.campaignAdsById);

  useEffect(() => {
    const relevantCampaignIds = [];

    Object.entries(campaignAdsById).forEach(([campaignId, ads]) => {
      const campaignIdNum = parseInt(campaignId, 10);

      if (
        selectedCampaignIds.includes(campaignIdNum) ||
        ads.data.some((ad) => selectedStoryIds.includes(ad.id)) ||
        ads.data.some((ad) => selectedBrandIds.includes(ad.brands_brand_id))
      ) {
        relevantCampaignIds.push(campaignIdNum);
      }
    });

    // Determine the campaigns to display
    const filteredCampaignData =
      relevantCampaignIds.length > 0
        ? campaigns.filter((campaign) => relevantCampaignIds.includes(campaign.id))
        : campaigns;

    setChartData({
      labels: filteredCampaignData?.map((campaign) => campaign.campaignName),
      datasets: [
        { data: filteredCampaignData?.map((campaign) => campaign.clickBid / 100), color: "info" },
      ],
    });
  }, [selectedCampaignIds, selectedStoryIds, selectedBrandIds, campaigns, campaignAdsById]);

  return chartData;
};

export default useCpcChartData;
