import { useState, useEffect, useCallback } from "react";
import { useDispatch, connect } from "react-redux";
import { getUsers } from "@sharecode/common/redux/actions/user";
import Table from "examples/Tables/Table";
import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { useRole } from "routes/ProtectedRoutes";
import { useSearchParams } from "react-router-dom";
import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
import { IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { Oval } from "@agney/react-loading";
import moment from "moment";
import SuiAvatar from "components/SuiAvatar";
import { Select, MenuItem } from "@mui/material";
import { debounce } from "lodash";

const TableRequest = (props) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const role = useRole();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRole, setSelectedRole] = useState("");

  const roleMapping = {
    "": "Tous",
    "admin": "Admin",
    "brand_manager": "Brand Manager"
  };

  const fetchUsers = useCallback(
    debounce((page, pagesize, search, roleParam) => {
      setLoading(true);

      dispatch(getUsers(page, pagesize, search, roleParam))
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    const page = searchParams.get("page") || 1;
    const pagesize = searchParams.get("pagesize") || 100;
    const search = searchParams.get("search") || '';
    const roleParam = selectedRole || '';

    fetchUsers(page, pagesize, search, roleParam);
  }, [window.location.search, fetchUsers, searchParams, selectedRole]);

  useEffect(() => {
    if (props.user.results && props.user.results.length > 0) {
      let objColumns = [
        { name: "Photo", align: "center" },
        { name: "Nom", align: "center" },
        { name: "Prénom", align: "center" },
        { name: "Sexe", align: "center" },
        { name: "Date d'inscription", align: "center" },
        { name: "Nb de tickets photo", align: "center" },
        { name: "Nb de tickets mail", align: "center" },
        { name: "Nb de cartes de fidélité", align: "center" },
        { name: "Etat", align: "center" },
        { name: "Role", align: "center" },
        { name: "Brand", align: "center" }
      ];
      if (
        role &&
        [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1
      ) {
        objColumns.push({ name: "Action", align: "center" });
      }
      setColumns(objColumns);

      const filteredUsers = selectedRole
        ? props.user.results.filter((user) => user.role === selectedRole)
        : props.user.results;

      setRows(
        filteredUsers.map((item) => ({
          Photo: item.picture ? <SuiAvatar src={`${item.picture}`} variant="rounded" /> : "-",
          Nom: <DefaultCell>{item.last_name || "-"}</DefaultCell>,
          Prénom: <DefaultCell>{item.first_name || "-"}</DefaultCell>,
          Sexe: <DefaultCell>{item.gender || "-"}</DefaultCell>,
          "Date d'inscription": (
            <DefaultCell>{moment(item.date_joined).format("DD-MM-YYYY")}</DefaultCell>
          ),
          "Nb de tickets photo": (
            <DefaultCell>{`${item.count_paper_tickets || "-"}`}</DefaultCell>
          ),
          "Nb de tickets mail": <DefaultCell>{`${item.count_email_tickets || "-"}`}</DefaultCell>,
          "Nb de cartes de fidélité": (
            <DefaultCell>{`${item.count_loyalty_cards || "-"}`}</DefaultCell>
          ),
          Etat: (
            <SuiBadge
              variant="contained"
              color={item.is_active ? "success" : "error"}
              size="xs"
              badgeContent={item.is_active ? "actif" : "inactif"}
              container
            />
          ),
          Role: <DefaultCell>{roleMapping[item.role] || "-"}</DefaultCell>,
          Brand: <DefaultCell>{item.brand ? item.brand.name : "-"}</DefaultCell>,
          ...(role && [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1 && {
            Action: <ActionCell id={item.id} />
          })
        }))
      );
    } else {
      setRows([]);
    }
  }, [props.user.results, selectedRole]);

  return (
    <>
      <SuiBox textAlign="center" marginBottom={3} width={200} marginLeft={2}>
        <SuiTypography htmlFor="select-role" style={{ marginRight: "40px", marginBottom: "10px" }}>
          Filtrer par rôle:
        </SuiTypography>
        <SuiBox display="flex" alignItems="center">
          <Select
            id="select-role"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            style={{ padding: "10px", borderRadius: "5px", outline: "none" }}
            displayEmpty
          >
            {Object.entries(roleMapping).map(([value, displayName]) => (
              <MenuItem key={value} value={value}>
                {displayName}
              </MenuItem>
            ))}
          </Select>
        </SuiBox>
      </SuiBox>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun utilisateur trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
