/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import IconButton from "@mui/material/IconButton";
import * as types from "@sharecode/common/redux/actions/actionTypes";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { connect, useDispatch } from "react-redux";
// icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { deleteBrand } from "@sharecode/common/redux/actions/brand";
import { DeleteModal } from "components/DeleteModal";
import { setOpenBrandDrawer, useSoftUIController } from "context";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { DefaultServiceHandler } from "utils/http-common";

function ActionCell(props) {
  const disp = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { openBrandDrawer } = controller;
  const [searchParams, setSearchParams] = useSearchParams();
  const handleBrandDrawerOpen = () => setOpenBrandDrawer(dispatch, !openBrandDrawer);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const editBrand = (id) => {
    const editedBrand = props.brand.results.filter((el) => {
      return el.id === parseInt(id);
    });
    disp({
      type: types.EDITED_BRAND,
      payload: editedBrand[0],
    });
    handleBrandDrawerOpen();
  };

  const removeBrand = (id) => {
    setLoadingDelete(true);
    disp(deleteBrand(id))
      .then(() => {
        disp(DefaultServiceHandler("success", "success", "Enseigne supprimée avec succès !"));
        setSearchParams({ size: searchParams.get('size') || 10, search: searchParams.get('search') || "", page: 1 });
      })
      .catch((error) => {
        disp(
          DefaultServiceHandler("error", "error", "Erreur lors de la suppression de l'enseigne.")
        );
      })
      .finally(() => {
        setLoadingDelete(false);
        setDialogOpen(false);
      });
  };

  return (
    <>
      <DeleteModal
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleDelete={removeBrand}
        objectId={props.id}
        loading={loadingDelete}
      />
      <SuiBox display="flex" alignItems="center" flexDirection="row">
        <SuiBox></SuiBox>
        <SuiBox>
          <IconButton color="info" onClick={() => editBrand(props.id)}>
            <EditIcon />
          </IconButton>
        </SuiBox>
        <SuiBox>
          <IconButton color="error" onClick={() => setDialogOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </SuiBox>
        <SuiBox>
          <Link to={`/brands/${props.id}/tickets/`}>
            <IconButton color="success">
              <ReceiptLongIcon />
            </IconButton>
          </Link>
        </SuiBox>
      </SuiBox>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
