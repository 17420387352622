/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

function DeleteAccount() {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Card id="delete-account">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Suppression du compte</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          Une fois que vous avez supprimé votre compte, il n'y a plus de retour en arrière.
        </SuiTypography>
      </SuiBox>
      <SuiBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <SuiBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
          <Switch checked={checked} onChange={handleChange} />
          <SuiBox ml={2} lineHeight={0}>
            <SuiTypography display="block" variant="button" fontWeight="medium">
              Êtes-vous sûr ?
            </SuiTypography>
            <SuiTypography variant="caption" color="text">
              Je souhaite supprimer mon compte.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <SuiBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <SuiButton
              variant="gradient"
              color="error"
              sx={{ height: "100%" }}
              disabled={!checked}
              onClick={() =>
                window.open(
                  "https://ch3ck.fr/politique-de-confidentialite-application-mobile/",
                  "_blank"
                )
              }
            >
              Supprimer
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default DeleteAccount;
