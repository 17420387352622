/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import IconButton from "@mui/material/IconButton";
import * as types from "@sharecode/common/redux/actions/actionTypes";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { useDispatch, connect } from "react-redux";
import { useState, useEffect } from "react";
// icons
import EditIcon from "@mui/icons-material/Edit";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useSoftUIController, setOpenTicketDrawer } from "context";

function ActionCell(props) {
  const disp = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { openTicketDrawer } = controller;
  const handleTicketDrawerOpen = () => setOpenTicketDrawer(dispatch, !openTicketDrawer);

  useEffect(() => {});

  const editTicket = (id) => {
    const editedTicket = props.tickets.results.filter((el) => {
      return el.id === parseInt(id);
    });
    disp({
      type: types.EDITED_TICKET,
      payload: editedTicket[0],
    });
    handleTicketDrawerOpen();
  };

  return (
    <SuiBox display="flex" alignItems="center">
      <SuiBox>
        <IconButton color="info" onClick={() => editTicket(props.id)}>
          <EditIcon />
        </IconButton>
        <div>{props.file}</div>
        <IconButton color="info">
          <a
            href={props.fileUrl}
            target="_blank"
            style={{ color: "#17c1e8", margin: 0, padding: 0 }}
          >
            <ReceiptLongIcon />
          </a>
        </IconButton>
      </SuiBox>
    </SuiBox>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
