const initialState = [
  {
    id: '',
    name: '',
    logo: '',
  },
];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'GET_BRAND':
      return payload || state;
    case 'GET_BRAND_CATEGORIES':
      return payload || state;
    case 'GET_BRAND_TICKETS':
      return payload || state;
    case 'GET_SINGLE_BRAND':
      const { brandId, response } = payload;
      return {
        ...state,
        [brandId]: response,
      };
    case 'POST_BRAND':
      return state;
    case 'POST_BRAND_CATEGORY':
      return state;
    case 'UPDATE_BRAND':
      return state;
    case 'DELETE_BRAND':
      return state;
    case 'DELETE_BRAND_CATEGORY':
      return state;
    default:
      return state;
  }
}
