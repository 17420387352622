import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const useCpcChartData = (selectedCampaignIds) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{ data: [], color: "info" }],
  });

  const campaigns = useSelector((state) => state.campaignReducer.campaigns.data);

  useEffect(() => {
    const labels = [];
    const data = [];

    campaigns
      ?.filter(
        (campaign) => selectedCampaignIds.length === 0 || selectedCampaignIds.includes(campaign.id)
      )
      .forEach((campaign) => {
        labels.push(campaign.campaignName);
        data.push(campaign.clickBid / 100);
      });

    setChartData({
      labels: labels,
      datasets: [{ data: data, color: "info" }],
    });
  }, [selectedCampaignIds, campaigns]);

  return chartData;
};

export default useCpcChartData;
