import * as types from '../actions/actionTypes';
import { mapRole, IS_ADMIN } from '../actions/actionTypes';
import { getAuthorizationToken } from '../../services/constantApi';
import StorageUtil from '../../utils/storageUtil';
import { getUserPicture } from '../../services/constantApi';
import { isMobile } from '../../utils/isMobile';

const user = getAuthorizationToken;
const picture = isMobile ? null : getUserPicture();
const initialState = user
  ? { isLoggedIn: true, user, role: null, picture: picture }
  : { isLoggedIn: false, user: null, role: null, picture: null };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case types.REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user.id,
        role:
          payload.user && payload.user.role
            ? mapRole[payload.user.role]
            : null,
        picture: payload.user.picture ?? payload.user.picture,
      };
    case types.UPDATE_PROFILE:
      let user = StorageUtil.getItem('user');
      user = JSON.parse(user);
      user.user.picture =
        payload.user.data.picture ?? payload.user.data.picture;
      StorageUtil.setItem('user', JSON.stringify(user));
      return {
        ...state,
        isLoggedIn: true,
        picture:
          payload.user.data.picture ?? payload.user.data.picture,
      };
    case types.UPDATE_PROFILE_MOBILE:
      StorageUtil.getItemTest('user').then((userMobile) => {
        userMobile = JSON.parse(userMobile);
        userMobile.user = payload.user;
        StorageUtil.setItem('user', JSON.stringify(userMobile));
        return {
          ...state,
          isLoggedIn: true,
          picture: payload.user.picture ?? payload.user.picture,
        };
      });
    case types.LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case types.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
