import React from "react";
import { connect } from "react-redux";
// TEMPLATE COMPONENTS
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";
import SuiTypography from "components/SuiTypography";
// MUI COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Rating } from "@mui/material";
import Logo from "assets/images/logos/logo_black.png";

import {
  IS_EMPLOYEE_BRAND_VALUE,
  IS_ADMIN_VALUE,
} from "@sharecode/common/redux/actions/actionTypes";
import { useRole } from "routes/ProtectedRoutes";
import moment from "moment";

const DetailModal = (props) => {
  const role = useRole();
  return (
    <div>
      <Dialog
        open={() => props.closeModal(true)}
        onClose={() => props.closeModal(false)}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center">
          <SuiBox component="img" src={Logo} height="60px" />
        </DialogTitle>
        <DialogContent sx={{ width: "500px" }}>
          <SuiBox textAlign="center">
            <SuiTypography variant="h5">Récapitulatif</SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="center" mt={2}>
            <SuiAvatar
              src={props.userReview ? props.userReview.user.picture : " "}
              variant="rounded"
              size="xl"
            />
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" mt={2}>
            <SuiTypography variant="h5" fontWeight="light">
              {props.userReview ? props.userReview.user.fullname : " "}
            </SuiTypography>
          </SuiBox>
          {role === IS_EMPLOYEE_BRAND_VALUE ? (
            <SuiBox display="flex" justifyContent="center" mt={2}>
              <SuiTypography variant="h5" fontWeight="light">
                {props.userReview ? props.userReview.shop : " "}
              </SuiTypography>
            </SuiBox>
          ) : (
            " "
          )}
          {role === IS_ADMIN_VALUE ? (
            <>
              <SuiBox display="flex" justifyContent="center" mt={2}>
                <SuiAvatar src={props.userReview?.brand?.logo || ""} variant="rounded" size="xl" />
              </SuiBox>
              <SuiBox display="flex" justifyContent="center" mt={2}>
                <SuiTypography variant="h5" fontWeight="light">
                  {props.userReview?.brand?.name || ""}
                </SuiTypography>
              </SuiBox>
            </>
          ) : (
            " "
          )}
          <SuiBox display="flex" justifyContent="center" mt={2}>
            <SuiTypography variant="h5" fontWeight="light">
              {props.userReview ? moment(props.userReview.created_at).format("DD-MM-YYYY") : " "}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" mt={2}>
            <Rating value={props.userReview ? parseFloat(props.userReview.rating) : " "} readOnly />
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" mt={2}>
            <SuiTypography variant="h5" fontWeight="light">
              {props.userReview ? props.userReview.description : " "}
            </SuiTypography>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <Grid container display="flex" justifyContent="center">
            <Grid item>
              <SuiButton variant="gradient" color="error" onClick={() => props.closeModal(false)}>
                Fermer
              </SuiButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps)(DetailModal);
