/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as types from "@sharecode/common/redux/actions/actionTypes";
import { useDispatch, connect } from "react-redux";
// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components

import SuiButton from "components/SuiButton";
import ChatModal from "examples/Modals/ChatModal";
import DetailModal from "examples/Modals/DetailModal";

import { IS_ADMIN_VALUE } from "@sharecode/common/redux/actions/actionTypes";

import { useState } from "react";
import { useRole } from "routes/ProtectedRoutes";

function ActionCell(props) {
  const [openChatModal, setOpenChatModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const disp = useDispatch();
  const role = useRole();

  const getClientReview = (id) => {
    const userReview = props.reviews.results.filter((el) => {
      return el.id === parseInt(id);
    });
    disp({
      type: types.USER_REVIEW,
      payload: userReview[0],
    });
    setOpenDetailModal(true);
  };

  const contactClient = (id) => {
    const userReview = props.reviews.results.filter((el) => {
      return el.id === parseInt(id);
    });
    disp({
      type: types.USER_REVIEW,
      payload: userReview[0],
    });
    setOpenChatModal(true);
  };

  return (
    <Grid container spacing={0.5}>
      <Grid item>
        <SuiButton
          type="submit"
          variant="gradient"
          color="info"
          size="small"
          onClick={() => {
            getClientReview(props.id);
          }}
        >
          Details
        </SuiButton>
        {openDetailModal && <DetailModal closeModal={setOpenDetailModal} />}
      </Grid>
      {role != IS_ADMIN_VALUE ? (
        <Grid item>
          <SuiButton
            type="submit"
            variant="gradient"
            color="warning"
            size="small"
            onClick={() => {
              contactClient(props.id);
            }}
          >
            Chat
          </SuiButton>
          {openChatModal && <ChatModal closeModal={setOpenChatModal} />}
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}
function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
