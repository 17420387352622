/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import team1 from "assets/images/team-1.jpg";

// Components
import ProductCell from "../components/UserCell";
import DefaultCell from "../components/DefaultCell";

// services
import { CheckService } from "@sharecode/common/services";
import { useEffect, useState } from "react";

export const useTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    CheckService.get().then((r) => {
      setData(r.clientList);
    });
  }, []);

  const tableData = {
    columns: [
      { Header: "Profil client", align: "left", accessor: "name" },
      { Header: "Dernière visite", align: "center", accessor: "lastVisit" },
      { Header: "Date de souscription", align: "center", accessor: "subscriptionDate" },
      { Header: "Dernières offres envoyées", align: "center", accessor: "lastOfferSent" },
    ],

    rows: data.map((item) => {
      return {
        name: <ProductCell image={team1} name={item.name} />,
        lastVisit: <DefaultCell>{item.lastVisit}</DefaultCell>,
        subscriptionDate: <DefaultCell>{item.subscriptionDate}</DefaultCell>,
        lastOfferSent: <DefaultCell>{item.lastOfferSent}</DefaultCell>,
      };
    }),
  };

  return tableData;
};

export default useTable;
