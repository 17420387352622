/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState } from "react";
// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import maria from "assets/images/marie.jpg";
import bruceMars from "assets/images/bruce-mars.jpg";
import ivana from "assets/images/ivana-squares.jpg";
import kal from "assets/images/kal-visuals-square.jpg";
import ivanaSquare from "assets/images/ivana-square.jpg";
import ivancik from "assets/images/ivancik.jpg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBrand } from "@sharecode/common/redux/actions/brand";
import { resolveConfig } from "prettier";
import { BrandService } from "@sharecode/common/services/BrandService";
import { isRejected } from "@reduxjs/toolkit";

export function useBrandData(page = 1, size = 500, search = "") {
  const dispatch = useDispatch();
  useEffect(() => {
    new Promise(async (resolve, reject) => {
      dispatch(getBrand(page, size, search))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);
}