import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
// redux
import { DefaultServiceHandler } from "utils/http-common";
import { useDispatch } from "react-redux";

// services
import { AccountService } from "@sharecode/common/services";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import Logo from "assets/images/logos/logo_black.png";
import { SIGN_IN } from "@sharecode/common/routes/constants";
import { postCodeSocial } from "@sharecode/common/services/AccountService";
import Activate from "assets/images/illustrations/activate.png";

function SignupActivationEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.key) {
      AccountService.registerEmailValidation(params.key)
        .then((r) => {
          dispatch(
            DefaultServiceHandler("success", "success", "Votre compte à été activé avec succès !")
          );
          /*        setTimeout(() => {
            navigate(SIGN_IN);
          }, 5000); */
        })
        .catch((error) => {
          dispatch(DefaultServiceHandler("error", "error", error.toString()));
        });
    }
  }, []);
  return (
    <IllustrationLayout
      title="Activation de votre compte"
      description="Votre compte a été activé, vous pouvez maintenant accéder à l'application !"
      color="info"
      illustration={{
        image: Activate,
      }}
      centerText
    >
      {/* <SuiBox mt={2} textAlign="center">
        <SuiTypography variant="button" color="text" fontWeight="regular">
          retour à la{" "}
          <SuiTypography
            component={Link}
            to={SIGN_IN}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Connexion
          </SuiTypography>
        </SuiTypography>
      </SuiBox> */}
    </IllustrationLayout>
  );
}

export default SignupActivationEmail;
