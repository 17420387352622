import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faChartSimple, faCheck } from "@fortawesome/free-solid-svg-icons";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { DeleteModal } from "components/DeleteModal";
// redux
import {
  deleteAdminCampaign,
  setSelectedCampaignId,
  fetchAdminCampaignDetails,
  fetchAdminCampaignAds,
  fetchAdminCampaignStats,
} from "@sharecode/common/redux/actions/campaignsActions";

function ActionCell(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleSelectCampaign = () => {
    dispatch(setSelectedCampaignId(props.id));
    dispatch(fetchAdminCampaignDetails(props.id));
    dispatch(fetchAdminCampaignAds(props.id));

    // Redirect to OfferCreateAdmin with campaign ID for editing
    navigate(`/create-offer-admin`);
  };

  // Function to handle campaign deletion
  const handleDeleteAdminCampaign = (id) => {
    setLoadingDelete(true);
    dispatch(deleteAdminCampaign(id))
      .then(() => {
        setLoadingDelete(false);
        setDialogOpen(false);
      })
      .catch((error) => {
        setLoadingDelete(false);
        setDialogOpen(false);
        console.error("Error deleting campaign:", error);
      });
  };

  // function to navigate to the dashboard page when the user clicks on the campaign
  const handleNavigateToDashboard = () => {
    dispatch(setSelectedCampaignId(props.id));
    dispatch(fetchAdminCampaignDetails(props.id));
    dispatch(fetchAdminCampaignAds(props.id));
    dispatch(fetchAdminCampaignStats(props.id));
    // Redirect to dashboard admin page
    navigate(`/dashboard-retailer-admin`);
  };

  // Determine if the faCheck icon should be disabled based on the campaign status
  const isSelectDisabled = ["Terminée", "Rejetée", "Active"].includes(props.status);

  // Determine if the faChartSimple icon should be disabled based on the campaign status
  const isChartDisabled = ["En attente", "Rejetée"].includes(props.status);

  const IconStyle = {
    color: "white",
    backgroundColor: "#2794F9",
    borderRadius: "5px",
    fontSize: "20px",
    padding: "3px",
  };

  const disabledIconStyle = {
    color: "white",
    backgroundColor: "#2794F9",
    borderRadius: "5px",
    fontSize: "20px",
    padding: "3px",
    opacity: 0.5,
  };

  return (
    <>
      <DeleteModal
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleDelete={() => handleDeleteAdminCampaign(props.id)}
        objectId={props.id}
        loading={loadingDelete}
      />
      <SuiBox display="flex" alignItems="center">
        <SuiBox>
          <Tooltip
            title={
              isSelectDisabled
                ? "Impossible de modifier une campagne terminée, rejetée ou active."
                : " Vérifier les détails de la campagne"
            }
          >
            <span>
              <IconButton onClick={handleSelectCampaign} disabled={isSelectDisabled}>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={isSelectDisabled ? disabledIconStyle : IconStyle}
                />
              </IconButton>
            </span>
          </Tooltip>
        </SuiBox>
        <SuiBox>
          <IconButton onClick={() => setDialogOpen(true)}>
            <FontAwesomeIcon icon={faTrashCan} style={IconStyle} />
          </IconButton>
        </SuiBox>
        <SuiBox>
          <Tooltip
            title={
              isChartDisabled ? "Campagne en attente ou rejetée" : "Statistiques de la campagne"
            }
          >
            <span>
              <IconButton onClick={handleNavigateToDashboard} disabled={isChartDisabled}>
                <FontAwesomeIcon
                  icon={faChartSimple}
                  style={isChartDisabled ? disabledIconStyle : IconStyle}
                />
              </IconButton>
            </span>
          </Tooltip>
        </SuiBox>
      </SuiBox>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
