import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

const LoadingOverlay = ({ message }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        zIndex: theme.zIndex.modal,
      }}
    >
      <Box
        sx={{
          width: "497px",
          height: "146px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "15px",
          border: "0.5px solid #FFF",
          background: "#B4FFB7",
          padding: theme.spacing(2),
        }}
      >
        <CircularProgress size={50} sx={{ color: "#333" }} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingOverlay;
