import axios from 'axios';
import {
  getAuthorizationToken,
  RETAILER_ADS_BASE_URL,
} from './constantApi';

const AdService = {
  getAds: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${RETAILER_ADS_BASE_URL}/ads`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },

  createAd: (adData, campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.post(
        `${RETAILER_ADS_BASE_URL}/ad/campaign/${campaignId}`,
        adData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getAdDetails: (adId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${RETAILER_ADS_BASE_URL}/ads/${adId}`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },

  uploadAdContent: (adId, content) => {
    return getAuthorizationToken().then((token) => {
      return axios.post(
        `${RETAILER_ADS_BASE_URL}/ads/${adId}/upload`,
        content,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getCampaignAds: (campaignId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(
        `${RETAILER_ADS_BASE_URL}/campaign/ads/${campaignId}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },
  deleteAd: (adId) => {
    return getAuthorizationToken().then((token) => {
      return axios.delete(`${RETAILER_ADS_BASE_URL}/ads/${adId}`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },
  editAd: (adId, adData) => {
    return getAuthorizationToken().then((token) => {
      return axios.put(
        `${RETAILER_ADS_BASE_URL}/ads/${adId}`,
        adData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },
};

export default AdService;
