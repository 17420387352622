// @ts-nocheck
import * as types from './actionTypes';
import CampaignService from '../../../common/services/CampaignService';

// Fetch Campaigns
export const fetchCampaigns = () => (dispatch) => {
  dispatch({ type: types.FETCH_CAMPAIGNS_REQUEST });
  return CampaignService.getCampaigns()
    .then((response) => {
      dispatch({
        type: types.FETCH_CAMPAIGNS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error('Error fetching Campaigns:', error);
      console.log('Error details:', error.response);

      // Extract the token from the error response
      const token = error.response?.config?.headers?.Authorization;

      dispatch({
        type: types.FETCH_CAMPAIGNS_FAILURE,
        payload: {
          originalError: error,
          token: token,
        },
      });
    });
};

// Create Campaign
export const createCampaign = (campaignData) => (dispatch) => {
  dispatch({ type: types.CREATE_CAMPAIGN_REQUEST });
  // Return the promise chain here
  return CampaignService.createCampaign(campaignData)
    .then((response) => {
      dispatch({
        type: types.CREATE_CAMPAIGN_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.CREATE_CAMPAIGN_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};

// Fetch Campaign Ads
export const fetchCampaignAds = (campaignId) => (dispatch) => {
  dispatch({ type: types.FETCH_CAMPAIGN_ADS_REQUEST });
  return CampaignService.getCampaignAds(campaignId)
    .then((response) => {
      dispatch({
        type: types.FETCH_CAMPAIGN_ADS_SUCCESS,
        payload: { campaignId, ads: response.data },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_CAMPAIGN_ADS_FAILURE,
        payload: error,
      });
    });
};

// Fetch Campaign Details
export const fetchCampaignDetails = (campaignId) => (dispatch) => {
  dispatch({ type: types.FETCH_CAMPAIGN_DETAILS_REQUEST });
  return CampaignService.getCampaignDetails(campaignId)
    .then((response) => {
      dispatch({
        type: types.FETCH_CAMPAIGN_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_CAMPAIGN_DETAILS_FAILURE,
        payload: error,
      });
    });
};

// Delete Campaign Action
export const deleteCampaign = (campaignId) => (dispatch) => {
  dispatch({ type: types.DELETE_CAMPAIGN_REQUEST });
  return CampaignService.deleteCampaign(campaignId)
    .then((response) => {
      if (response.status === 200) {
        console.log(
          'Campaign status changed to has_ended successfully'
        );
        // After a successful delete, re-fetch the campaigns to get the updated list
        dispatch(fetchCampaigns());
      } else {
        // If the status code is not 200, handle accordingly
        throw new Error(
          'Failed to update campaign status to has_ended'
        );
      }
    })
    .catch((error) => {
      console.error(
        'Error updating campaign status to has_ended:',
        error
      );
      dispatch({
        type: types.DELETE_CAMPAIGN_FAILURE,
        payload: error.message || 'Unknown error',
      });
    });
};

// Update Campaign Action
export const editCampaign =
  (campaignId, campaignData) => async (dispatch) => {
    dispatch({ type: types.EDIT_CAMPAIGN_REQUEST });

    try {
      const response = await CampaignService.editCampaign(
        campaignId,
        campaignData
      );

      if (response.status >= 200 && response.status < 300) {
        dispatch({
          type: types.EDIT_CAMPAIGN_SUCCESS,
          payload: { campaignId, ...campaignData }, // Send the updated campaign data along with its ID
        });
      } else {
        throw new Error(
          `Failed to edit campaign: ${response.status}`
        );
      }
    } catch (error) {
      dispatch({
        type: types.EDIT_CAMPAIGN_FAILURE,
        payload:
          error.message ||
          'Unknown error occurred while editing campaign',
      });
    }
  };

export const fetchCampaignStats = (campaignId) => (dispatch) => {
  dispatch({ type: types.FETCH_CAMPAIGN_STATS_REQUEST });
  return CampaignService.getCampaignStats(campaignId)
    .then((response) => {
      dispatch({
        type: types.FETCH_CAMPAIGN_STATS_SUCCESS,
        payload: { campaignId, stats: response.data }, // Include campaignId in the payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_CAMPAIGN_STATS_FAILURE,
        payload: error,
      });
    });
};

export const setSelectedCampaignId = (id) => ({
  type: types.SET_SELECTED_CAMPAIGN_ID,
  payload: id,
});

// Update Campaign ClickBid Action
export const updateCampaignClickBid =
  (campaignId, clickBid) => async (dispatch) => {
    dispatch({ type: types.UPDATE_CAMPAIGN_CLICKBID_REQUEST });

    try {
      const response = await CampaignService.updateCampaignClickBid(
        campaignId,
        { clickBid }
      );

      if (response.status >= 200 && response.status < 300) {
        const payload = response.data; // Access the payload from the response
        dispatch({
          type: types.UPDATE_CAMPAIGN_CLICKBID_SUCCESS,
          payload: { campaignId, clickBid, ...payload }, // Include the payload in the action payload
        });
      } else {
        throw new Error(
          `Failed to update campaign click bid: ${response.status}`
        );
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_CAMPAIGN_CLICKBID_FAILURE,
        payload: response.data || error.message || 'Unknown error',
      });
    }
  };

// Fetch Admin Campaigns
export const fetchAdminCampaigns = () => (dispatch) => {
  dispatch({ type: types.FETCH_ADMIN_CAMPAIGNS_REQUEST });
  return CampaignService.getAdminCampaigns()
    .then((response) => {
      dispatch({
        type: types.FETCH_ADMIN_CAMPAIGNS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error('Error fetching Campaigns:', error);
      console.log('Error details:', error.response);

      // Extract the token from the error response
      const token = error.response?.config?.headers?.Authorization;

      dispatch({
        type: types.FETCH_ADMIN_CAMPAIGNS_FAILURE,
        payload: {
          originalError: error,
          token: token,
        },
      });
    });
};

// fetch single admin campaign
export const fetchAdminCampaign = (campaignId) => (dispatch) => {
  dispatch({ type: types.FETCH_ADMIN_CAMPAIGN_REQUEST });
  return CampaignService.getAdminCampaign(campaignId)
    .then((response) => {
      dispatch({
        type: types.FETCH_ADMIN_CAMPAIGN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_ADMIN_CAMPAIGN_FAILURE,
        payload: error,
      });
    });
};

// fetch admin campaign details
export const fetchAdminCampaignDetails =
  (campaignId) => (dispatch) => {
    dispatch({ type: types.FETCH_ADMIN_CAMPAIGN_DETAILS_REQUEST });
    return CampaignService.getAdminCampaignDetails(campaignId)

      .then((response) => {
        dispatch({
          type: types.FETCH_ADMIN_CAMPAIGN_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_ADMIN_CAMPAIGN_DETAILS_FAILURE,
          payload: error,
        });
      });
  };

// fetch Admin Campaign Ads

export const fetchAdminCampaignAds = (campaignId) => (dispatch) => {
  dispatch({ type: types.FETCH_ADMIN_CAMPAIGNS_ADS_REQUEST });
  return CampaignService.getAdminCampaignAds(campaignId)
    .then((response) => {
      dispatch({
        type: types.FETCH_ADMIN_CAMPAIGNS_ADS_SUCCESS,
        payload: { campaignId, ads: response.data },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_ADMIN_CAMPAIGNS_ADS_FAILURE,
        payload: error,
      });
    });
};

// Fetch Admin Campaigns Stats
export const fetchAdminCampaignStats = (campaignId) => (dispatch) => {
  dispatch({ type: types.FETCH_ADMIN_CAMPAIGNS_STATS_REQUEST });
  return CampaignService.getAdminCampaignsStats(campaignId)
    .then((response) => {
      dispatch({
        type: types.FETCH_ADMIN_CAMPAIGNS_STATS_SUCCESS,
        payload: { campaignId, stats: response.data },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_ADMIN_CAMPAIGNS_STATS_FAILURE,
        payload: error,
      });
    });
};

// delete Admin Campaign
export const deleteAdminCampaign = (campaignId) => (dispatch) => {
  dispatch({ type: types.DELETE_ADMIN_CAMPAIGN_REQUEST });
  return CampaignService.deleteAdminCampaign(campaignId)
    .then((response) => {
      if (response.status === 204) {
        dispatch(fetchAdminCampaigns());
      } else {
        throw new Error('Failed to delete campaign');
      }
    })
    .catch((error) => {
      console.error('Error deleting campaign:', error);
      dispatch({
        type: types.DELETE_ADMIN_CAMPAIGN_FAILURE,
        payload: error.message || 'Unknown error',
      });
    });
};

// fetch daily stats
export const fetchDailyStats = () => (dispatch) => {
  dispatch({ type: types.FETCH_DAILY_STATS_REQUEST });
  return CampaignService.getDailyStats()
    .then((response) => {
      dispatch({
        type: types.FETCH_DAILY_STATS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_DAILY_STATS_FAILURE,
        payload: error,
      });
    });
};

// fetch monthly stats
export const fetchMonthlyStats = () => (dispatch) => {
  dispatch({ type: types.FETCH_MONTHLY_STATS_REQUEST });
  return CampaignService.getMonthlyStats()
    .then((response) => {
      dispatch({
        type: types.FETCH_MONTHLY_STATS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_MONTHLY_STATS_FAILURE,
        payload: error,
      });
    });
};

// fetching weekly stats
export const fetchWeeklyStats = () => (dispatch) => {
  dispatch({ type: types.FETCH_WEEKLY_STATS_REQUEST });
  return CampaignService.getWeeklyStats()
    .then((response) => {
      dispatch({
        type: types.FETCH_WEEKLY_STATS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_WEEKLY_STATS_FAILURE,
        payload: error,
      });
    });
};

// fetch brands with campaign
export const fetchBrandsWithCampaign = () => (dispatch) => {
  dispatch({ type: types.FETCH_BRANDS_WITH_CAMPAIGN_REQUEST });
  return CampaignService.getBrandsWithCampaign()
    .then((response) => {
      dispatch({
        type: types.FETCH_BRANDS_WITH_CAMPAIGN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.FETCH_BRANDS_WITH_CAMPAIGN_FAILURE,
        payload: error,
      });
    });
};

// fetch daily admin stats by brand
export const fetchDailyAdminStatsByBrand =
  (brandId) => (dispatch) => {
    dispatch({
      type: types.FETCH_DAILY_ADMIN_STATS_BY_BRAND_REQUEST,
    });
    return CampaignService.getDailyAdminStatsByBrand(brandId)
      .then((response) => {
        dispatch({
          type: types.FETCH_DAILY_ADMIN_STATS_BY_BRAND_SUCCESS,
          payload: { brandId, stats: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_DAILY_ADMIN_STATS_BY_BRAND_FAILURE,
          payload: error,
        });
      });
  };

// fetch monthly admin stats by brand
export const fetchMonthlyAdminStatsByBrand =
  (brandId) => (dispatch) => {
    dispatch({
      type: types.FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_REQUEST,
    });
    return CampaignService.getMonthlyAdminStatsByBrand(brandId)
      .then((response) => {
        dispatch({
          type: types.FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_SUCCESS,
          payload: { brandId, stats: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_FAILURE,
          payload: error,
        });
      });
  };

// fetch weekly admin stats by brand
export const fetchWeeklyAdminStatsByBrand =
  (brandId) => (dispatch) => {
    dispatch({
      type: types.FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_REQUEST,
    });
    return CampaignService.getWeeklyAdminStatsByBrand(brandId)
      .then((response) => {
        dispatch({
          type: types.FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_SUCCESS,
          payload: { brandId, stats: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_FAILURE,
          payload: error,
        });
      });
  };

// fetch average click bid by country
export const fetchAverageClickBidByCountry =
  (countryCode) => (dispatch) => {
    dispatch({
      type: types.FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_REQUEST,
    });
    return CampaignService.getAverageClickBidByCountry(countryCode)
      .then((response) => {
        dispatch({
          type: types.FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_SUCCESS,
          payload: {
            country: countryCode,
            averageClickBid: response.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_FAILURE,
          payload: error,
        });
      });
  };

// validate campaign
export const validateCampaign = (campaignId) => (dispatch) => {
  dispatch({ type: types.VALIDATE_CAMPAIGN_REQUEST });
  return CampaignService.validateCampaign(campaignId)
    .then((response) => {
      dispatch({
        type: types.VALIDATE_CAMPAIGN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.VALIDATE_CAMPAIGN_FAILURE,
        payload: error,
      });
    });
};

// reject campaign
export const rejectCampaign = (campaignId) => (dispatch) => {
  dispatch({ type: types.REJECT_CAMPAIGN_REQUEST });
  return CampaignService.rejectCampaign(campaignId)
    .then((response) => {
      dispatch({
        type: types.REJECT_CAMPAIGN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.REJECT_CAMPAIGN_FAILURE,
        payload: error,
      });
    });
};

// reject campaign message
export const sendMessageRejection =
  (campaignId, message) => (dispatch) => {
    dispatch({ type: types.REJECT_CAMPAIGN_REQUEST });
    return CampaignService.sendMessageRejection(campaignId, message)
      .then((response) => {
        dispatch({
          type: types.REJECT_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.REJECT_CAMPAIGN_FAILURE,
          payload: error,
        });
      });
  };

// send email to admin for campaign approval
export const sendEmailToAdmin = (brandName, campaignName, storiesCount, maxBudget, costPerClick, countries, email) => (dispatch) => {
  const subject = "RETAILER ADS | Nouvelle campagne à valider";
  const body = `
    Hello l'équipe,

    ${brandName} vient de publier une nouvelle campagne sur le Retailer Ads.

    Ci-dessous les infos la concernant :

    ${campaignName}
    ${storiesCount} stories
    Budget campagne: ${maxBudget} €
    CPC: ${costPerClick} €
    Pays de diffusion: ${countries.join(', ')}

    Merci de procéder à sa vérification dès que possible !
  `;

  dispatch({ type: types.SEND_EMAIL_TO_ADMIN_REQUEST });

  return CampaignService.sendEmailToAdmin(subject, body, email)
    .then((response) => {
      dispatch({
        type: types.SEND_EMAIL_TO_ADMIN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.SEND_EMAIL_TO_ADMIN_FAILURE,
        payload: error,
      });
    });
};
