import * as types from "./actionTypes";
import { TicketService } from "@sharecode/common/services/TicketService";

export const getTickets =
  (page, size, search, startDate, endDate, area, unassigned, paperTickets, cancelToken) =>
  (dispatch) => {
    return TicketService.getTicketsAdmin(
      page,
      size,
      search,
      startDate,
      endDate,
      area,
      unassigned,
      paperTickets,
      cancelToken
    ).then(
      (response) => {
        dispatch({
          type: types.GET_TICKETS,
          payload: response,
        });
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

export const getTicket = () => (dispatch) => {
  console.log("ToDo");
  return null;
};

export const updateTicket = (id, data) => (dispatch) => {
  return TicketService.updateTicket(id, data).then(
    (response) => {
      dispatch({
        type: types.EDITED_TICKET,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getSingleUserTickets =
  (
    page,
    size,
    search,
    startDate,
    endDate,
    area,
    userId,
    unassigned,
    paperTickets
  ) =>
  (dispatch) => {
    return TicketService.getSingleUserTickets(
      page,
      size,
      search,
      startDate,
      endDate,
      area,
      userId,
      unassigned,
      paperTickets
    ).then(
      (response) => {
        dispatch({
          type: types.GET_SINGLE_USER_TICKETS,
          payload: response,
        });
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

export const createGenericEreceiptCarrepos = () => (dispatch) => {
  return TicketService.createGenericEreceiptCarrepos().then(
    (response) => {
      dispatch({
        type: types.CREATE_GENERIC_RECEIPT_CARREPOS,
        payload: response,
      });
    }
  );
};

export const createGenericEreceiptCsi = () => (dispatch) => {
  return TicketService.createGenericEreceiptCsi().then(
    (response) => {
      dispatch({
        type: types.CREATE_GENERIC_RECEIPT_CSI,
        payload: response,
      });
    }
  );
};

export const deleteGenericEreceiptCarrepos = () => (dispatch) => {
  return TicketService.deleteGenericEreceiptCarrepos().then(
    (response) => {
      dispatch({
        type: types.DELETE_GENERIC_RECEIPT_CARREPOS,
        payload: response,
      });
    }
  );
};

export const deleteGenericEreceiptCsi = () => (dispatch) => {
  return TicketService.deleteGenericEreceiptCsi().then(
    (response) => {
      dispatch({
        type: types.DELETE_GENERIC_RECEIPT_CSI,
        payload: response,
      });
    }
  );
};
