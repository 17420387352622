import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";

export function DatePeriodFilter({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handlePeriodFilter,
  handleClearPeriodFilter,
  hideFilterButton,
}) {
  return (
    <SuiBox display="flex" flexDirection="column">
      <SuiBox
        display="flex"
        gap={2}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "flex-end" },
          flexWrap: "wrap",
        }}
      >
        <SuiBox>
          <SuiTypography variant="caption" mb={1}>
            Date de début
          </SuiTypography>
          <SuiInput type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </SuiBox>
        <SuiBox>
          <SuiTypography variant="caption" mb={1}>
            Date de fin
          </SuiTypography>
          <SuiInput type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </SuiBox>
        <SuiBox display="flex" gap={2} sx={{ alignSelf: { xs: "flex-start", sm: "flex-end" } }}>
          {hideFilterButton ? (
            <></>
          ) : (
            <SuiButton
              disabled={!startDate || !endDate}
              variant="gradient"
              color="dark"
              onClick={handlePeriodFilter}
              size="small"
            >
              Filtrer date&nbsp;
            </SuiButton>
          )}
          <SuiButton onClick={handleClearPeriodFilter} variant="gradient" color="error">
            Effacer les dates
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}
