/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// services
import { ChartService } from "@sharecode/common/services";
import { useEffect, useState } from "react";

export const useComplexSimpleDoughnutChart = () => {
  const [data, setData] = useState([]);

  const palette = {
    dark: "dark",
    primary: "primary",
    info: "info",
    warning: "warning",
    black: "black",
    grey: "grey",
    secondary: "secondary",
    success: "success",
    orchid: "orchid",
    portland: "portland",
    bluepurple: "bluepurple",
    liberty: "liberty",
    aquamarine: "aquamarine",
    emerald: "emerald",
    grape: "grape",
  };

  useEffect(() => {
    ChartService.getActivityPerArea().then((r) => {
      setData(r);
    });
  }, []);

  const complexReportsDoughnutChartData = {
    labels: Object.keys(data),
    datasets: {
      backgroundColors: Object.values(palette),
      data: Object.values(data),
    },
  };

  return complexReportsDoughnutChartData;
};

export default useComplexSimpleDoughnutChart;
