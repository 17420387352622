import * as types from './actionTypes';
import AuthService from '../../services/AuthService';

// update token in uat
export const updateToken = (userId, token) => (dispatch) => {
  dispatch({ type: types.UPDATE_TOKEN_REQUEST });
  return AuthService.updateToken(userId, token)
    .then((response) => {
      dispatch({
        type: types.UPDATE_TOKEN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_TOKEN_FAILURE,
        payload: error,
      });
    });
};
