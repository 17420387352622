/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { createSearchParams } from "react-router-dom";
// @mui material components
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiButton from "components/SuiButton";
import ShopCreateUpdate from "examples/Drawers/shopCreateUpdate";
import Table from "examples/Tables/Table";
import DefaultCell from "./components/DefaultCell";
// Soft UI Dashboard PRO React context
import SuiBadge from "components/SuiBadge";
import { useSoftUIController, setOpenShopDrawer } from "context";
import ActionCell from "./components/ActionCell";

import useTable from "./data/dataTableData";
import { IS_EMPLOYEE_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { useRole } from "routes/ProtectedRoutes";
import { useSearchParams } from "react-router-dom";
import SuiPagination from "components/SuiPagination";
import icon from "assets/theme/components/icon";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import { useNavigate } from "react-router-dom";
import TableRequest from "./components/TableRequest";
import { SHOP_LIST } from "@sharecode/common/routes/constants";
import { IS_MANAGER_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_BRAND, IS_ADMIN } from "@sharecode/common/redux/actions/actionTypes";
import PageListing from "components/PagesListing";

const ShopList = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState();
  const [columns, setColumns] = useState();
  const [sizeSelected, setSizeSelected] = useState();
  const [rows, setRows] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { openShopDrawer } = controller;
  const role = useRole();
  const history = useNavigate();
  const handleShopDrawerOpen = () => setOpenShopDrawer(dispatch, !openShopDrawer);
  //const data = useTable(searchParams.get('page'), searchParams.get('size'), searchParams.get('search'));

  const handleChangeSize = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", e.value);
    searchParams.set("search", "");
    setSizeSelected({ value: e.value, label: e.label });
    setSearchParams(searchParams);
  };

  const searchCtn = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", searchParams.get("size") ? searchParams.get("size") : 10);
    searchParams.set("search", e.target.value);
    setSearchParams(searchParams);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar absolute={false} light={false} false isMini={false} />
        <SuiBox py={2}>
          <Card>
            {[IS_MANAGER_BRAND, IS_ADMIN].indexOf(role) > -1 && (
              <SuiBox p={2} display="flex" justifyContent="right">
                <SuiBox>
                  <SuiButton variant="gradient" color="info" onClick={handleShopDrawerOpen}>
                    <Icon>add</Icon>Ajouter un point de vente
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            )}
            <SuiBox p={2} display="flex">
              <SuiBox display="flex">
                <SuiBox justifyContent="left">
                  <SuiSelect
                    placeholder="Elements par page"
                    options={[
                      { value: "10", label: "10" },
                      { value: "20", label: "20" },
                      { value: "50", label: "50" },
                      { value: "100", label: "100" },
                      { value: "200", label: "200" },
                    ]}
                    onChange={(e) => handleChangeSize(e)}
                    value={{
                      value: searchParams.get("size") ? searchParams.get("size") : "10",
                      label: searchParams.get("size") ? searchParams.get("size") : "10",
                    }}
                  />
                </SuiBox>
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "16px",
                  }}
                >
                  Total: {props.shop.count}
                </div>
              </SuiBox>
              <SuiBox style={{ flex: "50%" }}>
                <SuiBox justifyContent="right" style={{ float: "right" }}>
                  <SuiInput
                    placeholder="Recherche..."
                    icon={{
                      component: "search",
                      direction: "right",
                    }}
                    onKeyUp={(e) => searchCtn(e)}
                  />
                </SuiBox>
              </SuiBox>
            </SuiBox>
            <TableRequest />
          </Card>
          <PageListing data={props.shop} />
        </SuiBox>
      </DashboardLayout>
      <ShopCreateUpdate />
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ShopList);
