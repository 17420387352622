/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

function ChangePassword() {
  const passwordRequirements = [
    "Min 8 caractères",
    "Au moins 1 majuscule, 1 minuscule, 1 chiffre ou 1 caractère spécial",
  ];

  const [values, setValues] = React.useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const verifyPasswords = () => {
    if (values.newPassword !== values.confirmNewPassword) {
      alert("passwords do not match");
    }
  };

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SuiBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <SuiTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </SuiTypography>
      </SuiBox>
    );
  });

  return (
    <Card id="change-password">
      <SuiBox p={3}>
        <SuiTypography variant="h5">Modification du mot de passe</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="Mot de passe actuel"
              placeholder="Mot de passe actuel"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Nouveau mot de passe"
              placeholder="Nouveau mot de passe"
              value={values.newPassword}
              onChange={handleChange("newPassword")}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Confirmation du Nouveau mot de passe"
              placeholder="Confirmation du Nouveau mot de passe"
              onChange={handleChange("confirmNewPassword")}
              value={values.confirmNewPassword}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <SuiBox mt={6} mb={1}>
          <SuiTypography variant="h5">Prérequis</SuiTypography>
        </SuiBox>
        <SuiBox mb={1}>
          <SuiTypography variant="body2" color="text">
            Veuillez suivre ce guide pour un mot de passe sécurisé
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <SuiBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </SuiBox>
          <SuiBox ml="auto">
            <SuiButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={verifyPasswords}
              disabled
            >
              Enregistrer
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default ChangePassword;
