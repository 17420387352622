export const getCountries = async () => {
    let list = [];
    countries.map( async (item) => {
         list.push({ value: await capitalizeFirstLetter(item.isoCode), label: await capitalizeFirstLetter(item.nom) });
    });
    return new Promise((resolve, reject) => resolve(list));
}

export const getCountryFromIndicatif = (indicatif) => {
    const item = countries.filter((item) => {
        return item.indicatif.trim() === indicatif;
    });
    return new Promise((resolve, reject) => resolve(item[0]));
};

export const getCountryFromIso = async (isoCode) => {
    const item = countries.filter((item) => {
        return item.isoCode.toUpperCase().trim() === isoCode;
    });
    return new Promise((resolve, reject) => resolve(item[0]));
};

const capitalizeFirstLetter = (string) =>
{ 
    if (string) {
        const str = string.toString();
        return str?.charAt(0)?.toUpperCase() + str?.slice(1).toLowerCase();
    }
}

export const countries = [
    {
        "nom" :"Anguilla",
        "indicatif" :"+1",
        "isoCode" :"AI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/af.svg "
    }, 
    {
        "nom" :"Afghanistan",
        "indicatif" :"+93",
        "isoCode" :"AF",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/af.svg "
    },
    {
        "nom" :"Îles Åland",
        "indicatif" :"+358",
        "isoCode" :"AX",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ax.svg "
    },
    {
        "nom" :"Albanie",
        "indicatif" :"+355",
        "isoCode" :"AL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/al.svg "
    },
    {
        "nom" :"Algérie",
        "indicatif" :"+213",
        "isoCode" :"DZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/dz.svg "
    },
    {
        "nom" :"Samoa américaines",
        "indicatif" :"+1684",
        "isoCode" :"AS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/as.svg "
    },
    {
        "nom" :"Andorre",
        "indicatif" :"+376",
        "isoCode" :"AD",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ad.svg "
    },
    {
        "nom" :"Angola",
        "indicatif" :"+244",
        "isoCode" :"AO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ao.svg "
    },
    {
        "nom" :"Anguilla",
        "indicatif" :"+1264",
        "isoCode" :"IA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ai.svg "
    },
    {
        "nom" :"Antarctique",
        "indicatif" :"+672",
        "isoCode" :"AQ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/aq.svg "
    },
    {
        "nom" :"Antigua-et-Barbuda",
        "indicatif" :"+1268",
        "isoCode" :"AG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ag.svg "
    },
    {
        "nom" :"Argentine",
        "indicatif" :"+54",
        "isoCode" :"AR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ar.svg "
    },
    {
        "nom" :"Arménie",
        "indicatif" :"+374",
        "isoCode" :"AM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/am.svg "
    },
    {
        "nom" :"Aruba",
        "indicatif" :"+297",
        "isoCode" :"AW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/aw.svg "
    },
    {
        "nom" :"L'île de l'Ascension",
        "indicatif" :"+247",
        "isoCode" :"AC",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ac.svg "
    },
    {
        "nom" :"Australie",
        "indicatif" :"+61",
        "isoCode" :"AU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/au.svg "
    },
    {
        "nom" :"Autriche",
        "indicatif" :"+43",
        "isoCode" :"AT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/at.svg "
    },
    {
        "nom" :"Azerbaïdjan",
        "indicatif" :"+994",
        "isoCode" :"AZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/az.svg "
    },
    {
        "nom" :"Bahamas",
        "indicatif" :"+1242",
        "isoCode" :"BS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bs.svg "
    },
    {
        "nom" :"Bahreïn",
        "indicatif" :"+973",
        "isoCode" :"BH",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bh.svg "
    },
    {
        "nom" :"Bangladesh",
        "indicatif" :"+880",
        "isoCode" :"BD",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bd.svg "
    },
    {
        "nom" :"Barbade",
        "indicatif" :"+1246",
        "isoCode" :"BB",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bb.svg "
    },
    {
        "nom" :"Biélorussie",
        "indicatif" :"+375",
        "isoCode" :"PAR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/by.svg "
    },
    {
        "nom" :"Belgique",
        "indicatif" :"+32",
        "isoCode" :"BE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/be.svg "
    },
    {
        "nom" :"Bélize",
        "indicatif" :"+501",
        "isoCode" :"BZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bz.svg "
    },
    {
        "nom" :"Bénin",
        "indicatif" :"+229",
        "isoCode" :"BJ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bj.svg "
    },
    {
        "nom" :"Bermudes",
        "indicatif" :"+1441",
        "isoCode" :"BM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bm.svg "
    },
    {
        "nom" :"Bhoutan",
        "indicatif" :"+975",
        "isoCode" :"BT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bt.svg "
    },
    {
        "nom" :"Bolivie",
        "indicatif" :"+591",
        "isoCode" :"BO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bo.svg "
    },
    {
        "nom" :"Bosnie-Herzégovine",
        "indicatif" :"+387",
        "isoCode" :"BA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ba.svg "
    },
    {
        "nom" :"Botswana",
        "indicatif" :"+267",
        "isoCode" :"BW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bw.svg "
    },
    {
        "nom" :"Brésil",
        "indicatif" :"+55",
        "isoCode" :"BR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/br.svg "
    },
    {
        "nom" :"Territoire britannique de l'océan Indien",
        "indicatif" :"+246",
        "isoCode" :"IO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/io.svg "
    },
    {
        "nom" :"Brunéi Darussalam",
        "indicatif" :"+673",
        "isoCode" :"BN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bn.svg "
    },
    {
        "nom" :"Bulgarie",
        "indicatif" :"+359",
        "isoCode" :"BG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bg.svg "
    },
    {
        "nom" :"Burkina Faso",
        "indicatif" :"+226",
        "isoCode" :"BF",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bf.svg "
    },
    {
        "nom" :"Burundi",
        "indicatif" :"+257",
        "isoCode" :"BI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bi.svg "
    },
    {
        "nom" :"Cambodge",
        "indicatif" :"+855",
        "isoCode" :"KH",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/kh.svg "
    },
    {
        "nom" :"Cameroun",
        "indicatif" :"+237",
        "isoCode" :"CM",
        "drapeau" :"https://cdn.kcak11.com/CountryFlags/countries/cm.svg "
    },
    {
        "nom" :"Canada",
        "indicatif" :"+1",
        "isoCode" :"CA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ca.svg "
    },
    {
        "nom" :"Cap Vert",
        "indicatif" :"+238",
        "isoCode" :"CV",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cv.svg "
    },
    {
        "nom" :"Îles Caïmans",
        "indicatif" :"+1345",
        "isoCode" :"KY",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ky.svg "
    },
    {
        "nom" :"République centrafricaine",
        "indicatif" :"+236",
        "isoCode" :"CF",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cf.svg "
    },
    {
        "nom" :"Tchad",
        "indicatif" :"+235",
        "isoCode" :"TD",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/td.svg "
    },
    {
        "nom" :"Chili",
        "indicatif" :"+56",
        "isoCode" :"CL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cl.svg "
    },
    {
        "nom" :"Chine",
        "indicatif" :"+86",
        "isoCode" :"CN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cn.svg "
    },
    {
        "nom" :"L'île Christmas",
        "indicatif" :"+61",
        "isoCode" :"CX",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cx.svg "
    },
    {
        "nom" :"Îles Cocos (Keeling)",
        "indicatif" :"+61",
        "isoCode" :"CC",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cc.svg "
    },
    {
        "nom" :"Colombie",
        "indicatif" :"+57",
        "isoCode" :"CO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/co.svg "
    },
    {
        "nom" :"Comores",
        "indicatif" :"+269",
        "isoCode" :"KM",
        "drapeau" :"https://cdn.kcak11.com/CountryFlags/countries/km.svg "
    },
    {
        "nom" :"Congo",
        "indicatif" :"+242",
        "isoCode" :"CG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cg.svg "
    },
    {
        "nom" :"Îles Cook",
        "indicatif" :"+682",
        "isoCode" :"CK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ck.svg "
    },
    {
        "nom" :"Costa Rica",
        "indicatif" :"+506",
        "isoCode" :"CR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cr.svg "
    },
    {
        "nom" :"Croatie",
        "indicatif" :"+385",
        "isoCode" :"RH",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/hr.svg "
    },
    {
        "nom" :"Cuba",
        "indicatif" :"+53",
        "isoCode" :"CU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cu.svg "
    },
    {
        "nom" :"Chypre",
        "indicatif" :"+357",
        "isoCode" :"CY",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cy.svg "
    },
    {
        "nom" :"République Tchèque",
        "indicatif" :"+420",
        "isoCode" :"CZ",
        "drapeau" :"https://cdn.kcak11.com/CountryFlags/countries/cz.svg "
    },
    {
        "nom" :"République Démocratique du Congo",
        "indicatif" :"+243",
        "isoCode" :"CD",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/cd.svg "
    },
    {
        "nom" :"Danemark",
        "indicatif" :"+45",
        "isoCode" :"NSP",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/dk.svg "
    },
    {
        "nom" :"Djibouti",
        "indicatif" :"+253",
        "isoCode" :"DJ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/dj.svg "
    },
    {
        "nom" :"Dominique",
        "indicatif" :"+1767",
        "isoCode" :"DM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/dm.svg "
    },
    {
        "nom" :"République Dominicaine",
        "indicatif" :"+1849",
        "isoCode" :"FAIRE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/do.svg "
    },
    {
        "nom" :"Equateur",
        "indicatif" :"+593",
        "isoCode" :"CE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ec.svg "
    },
    {
        "nom" :"Egypte",
        "indicatif" :"+20",
        "isoCode" :"EG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/eg.svg "
    },
    {
        "nom" :"Salvador",
        "indicatif" :"+503",
        "isoCode" :"SV",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sv.svg "
    },
    {
        "nom" :"Guinée équatoriale",
        "indicatif" :"+240",
        "isoCode" :"GQ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gq.svg "
    },
    {
        "nom" :"Erythrée",
        "indicatif" :"+291",
        "isoCode" :"ER",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/er.svg "
    },
    {
        "nom" :"Estonie",
        "indicatif" :"+372",
        "isoCode" :"EE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ee.svg "
    },
    {
        "nom" :"Eswatini",
        "indicatif" :"+268",
        "isoCode" :"SZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sz.svg "
    },
    {
        "nom" :"Ethiopie",
        "indicatif" :"+251",
        "isoCode" :"ET",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/et.svg "
    },
    {
        "nom" :"Îles Falkland (Malvinas)",
        "indicatif" :"+500",
        "isoCode" :"FK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/fk.svg "
    },
    {
        "nom" :"Îles Féroé",
        "indicatif" :"+298",
        "isoCode" :"FO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/fo.svg "
    },
    {
        "nom" :"Fidji",
        "indicatif" :"+679",
        "isoCode" :"FJ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/fj.svg "
    },
    {
        "nom" :"Finlande",
        "indicatif" :"+358",
        "isoCode" :"FI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/fi.svg "
    },
    {
        "nom" :"France",
        "indicatif" :"+33",
        "isoCode" :"FR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/fr.svg "
    },
    {
        "nom" :"Guyane Française",
        "indicatif" :"+594",
        "isoCode" :"GF",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gf.svg "
    },
    {
        "nom" :"Polynésie française",
        "indicatif" :"+689",
        "isoCode" :"PF",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pf.svg "
    },
    {
        "nom" :"Gabon",
        "indicatif" :"+241",
        "isoCode" :"GA",
        "drapeau" :"https://cdn.kcak11.com/CountryFlags/countries/ga.svg "
    },
    {
        "nom" :"Gambie",
        "indicatif" :"+220",
        "isoCode" :"GM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gm.svg "
    },
    {
        "nom" :"Géorgie",
        "indicatif" :"+995",
        "isoCode" :"GE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ge.svg "
    },
    {
        "nom" :"Allemagne",
        "indicatif" :"+49",
        "isoCode" :"DE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/de.svg "
    },
    {
        "nom" :"Ghana",
        "indicatif" :"+233",
        "isoCode" :"GH",
        "drapeau" :"https://cdn.kcak11.com/CountryFlags/countries/gh.svg "
    },
    {
        "nom" :"Gibraltar",
        "indicatif" :"+350",
        "isoCode" :"GI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gi.svg "
    },
    {
        "nom" :"Grèce",
        "indicatif" :"+30",
        "isoCode" :"GR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gr.svg "
    },
    {
        "nom" :"Groenland",
        "indicatif" :"+299",
        "isoCode" :"GL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gl.svg "
    },
    {
        "nom" :"Grenade",
        "indicatif" :"+1473",
        "isoCode" :"GD",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gd.svg "
    },
    {
        "nom" :"Guadeloupe",
        "indicatif" :"+590",
        "isoCode" :"GP",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gp.svg "
    },
    {
        "nom" :"Guam",
        "indicatif" :"+1671",
        "isoCode" :"GU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gu.svg "
    },
    {
        "nom" :"Guatémala",
        "indicatif" :"+502",
        "isoCode" :"GT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gt.svg "
    },
    {
        "nom" :"Guernesey",
        "indicatif" :"+44",
        "isoCode" :"GG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gg.svg "
    },
    {
        "nom" :"Guinée",
        "indicatif" :"+224",
        "isoCode" :"GN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gn.svg "
    },
    {
        "nom" :"Guinée-Bissau",
        "indicatif" :"+245",
        "isoCode" :"GW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gw.svg "
    },
    {
        "nom" :"Guyane",
        "indicatif" :"+592",
        "isoCode" :"GY",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gy.svg "
    },
    {
        "nom" :"Haïti",
        "indicatif" :"+509",
        "isoCode" :"HT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ht.svg "
    },
    {
        "nom" :"Saint-Siège (État de la Cité du Vatican)",
        "indicatif" :"+379",
        "isoCode" :"VA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/va.svg "
    },
    {
        "nom" :"Honduras",
        "indicatif" :"+504",
        "isoCode" :"HN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/hn.svg "
    },
    {
        "nom" :"Hong Kong",
        "indicatif" :"+852",
        "isoCode" :"HK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/hk.svg "
    },
    {
        "nom" :"Hongrie",
        "indicatif" :"+36",
        "isoCode" :"HU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/hu.svg "
    },
    {
        "nom" :"Islande",
        "indicatif" :"+354",
        "isoCode" :"EST",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/is.svg "
    },
    {
        "nom" :"Inde",
        "indicatif" :"+91",
        "isoCode" :"IN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/in.svg "
    },
    {
        "nom" :"Indonésie",
        "indicatif" :"+62",
        "isoCode" :"ID",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/id.svg "
    },
    {
        "nom" :"Iran",
        "indicatif" :"+98",
        "isoCode" :"IR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ir.svg "
    },
    {
        "nom" :"Irak",
        "indicatif" :"+964",
        "isoCode" :"QI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/iq.svg "
    },
    {
        "nom" :"Irlande",
        "indicatif" :"+353",
        "isoCode" :"IE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ie.svg "
    },
    {
        "nom" :"Île de Man",
        "indicatif" :"+44",
        "isoCode" :"IM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/im.svg "
    },
    {
        "nom" :"Israël",
        "indicatif" :"+972",
        "isoCode" :"IL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/il.svg "
    },
    {
        "nom" :"Italie",
        "indicatif" :"+39",
        "isoCode" :"IL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/it.svg "
    },
    {
        "nom" :"Côte d'Ivoire / Côte d'Ivoire",
        "indicatif" :"+225",
        "isoCode" :"CI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ci.svg "
    },
    {
        "nom" :"Jamaïque",
        "indicatif" :"+1876",
        "isoCode" :"JM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/jm.svg "
    },
    {
        "nom" :"Japon",
        "indicatif" :"+81",
        "isoCode" :"JP",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/jp.svg "
    },
    {
        "nom" :"Jersey",
        "indicatif" :"+44",
        "isoCode" :"JE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/je.svg "
    },
    {
        "nom" :"Jordanie",
        "indicatif" :"+962",
        "isoCode" :"JO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/jo.svg "
    },
    {
        "nom" :"Kazakhstan",
        "indicatif" :"+77",
        "isoCode" :"KZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/kz.svg "
    },
    {
        "nom" :"Kenya",
        "indicatif" :"+254",
        "isoCode" :"KE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ke.svg "
    },
    {
        "nom" :"Kiribati",
        "indicatif" :"+686",
        "isoCode" :"KI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ki.svg "
    },
    {
        "nom" :"Corée, République Populaire Démocratique de Corée",
        "indicatif" :"+850",
        "isoCode" :"KP",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/kp.svg "
    },
    {
        "nom" :"Corée, République de Corée du Sud",
        "indicatif" :"+82",
        "isoCode" :"KR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/kr.svg "
    },
    {
        "nom" :"Kosovo",
        "indicatif" :"+383",
        "isoCode" :"XK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/xk.svg "
    },
    {
        "nom" :"Koweït",
        "indicatif" :"+965",
        "isoCode" :"KW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/kw.svg "
    },
    {
        "nom" :"Kirghizistan",
        "indicatif" :"+996",
        "isoCode" :"KG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/kg.svg "
    },
    {
        "nom" :"Laos",
        "indicatif" :"+856",
        "isoCode" :"LA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/la.svg "
    },
    {
        "nom" :"Lettonie",
        "indicatif" :"+371",
        "isoCode" :"LV",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/lv.svg "
    },
    {
        "nom" :"Liban",
        "indicatif" :"+961",
        "isoCode" :"LB",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/lb.svg "
    },
    {
        "nom" :"Lesotho",
        "indicatif" :"+266",
        "isoCode" :"LS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ls.svg "
    },
    {
        "nom" :"Libéria",
        "indicatif" :"+231",
        "isoCode" :"LR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/lr.svg "
    },
    {
        "nom" :"Libye",
        "indicatif" :"+218",
        "isoCode" :"LY",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ly.svg "
    },
    {
        "nom" :"Liechtenstein",
        "indicatif" :"+423",
        "isoCode" :"LI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/li.svg "
    },
    {
        "nom" :"Lituanie",
        "indicatif" :"+370",
        "isoCode" :"LT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/lt.svg "
    },
    {
        "nom" :"Luxembourg",
        "indicatif" :"+352",
        "isoCode" :"LU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/lu.svg "
    },
    {
        "nom" :"Macao",
        "indicatif" :"+853",
        "isoCode" :"MO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mo.svg "
    },
    {
        "nom" :"Madagascar",
        "indicatif" :"+261",
        "isoCode" :"MG",
        "drapeau" :"https://cdn.kcak11.com/CountryFlags/countries/mg.svg "
    },
    {
        "nom" :"Mali",
        "indicatif" :"+265",
        "isoCode" :"MW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mw.svg "
    },
    {
        "nom" :"Malaisie",
        "indicatif" :"+60",
        "isoCode" :"MON",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/my.svg "
    },
    {
        "nom" :"Maldives",
        "indicatif" :"+960",
        "isoCode" :"MV",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mv.svg "
    },
    {
        "nom" :"Mali",
        "indicatif" :"+223",
        "isoCode" :"ML",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ml.svg "
    },
    {
        "nom" :"Malte",
        "indicatif" :"+356",
        "isoCode" :"MT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mt.svg "
    },
    {
        "nom" :"Îles Marshall",
        "indicatif" :"+692",
        "isoCode" :"MH",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mh.svg "
    },
    {
        "nom" :"Martinique",
        "indicatif" :"+596",
        "isoCode" :"MQ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mq.svg "
    },
    {
        "nom" :"Mauritanie",
        "indicatif" :"+222",
        "isoCode" :"MR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mr.svg "
    },
    {
        "nom" :"Maurice",
        "indicatif" :"+230",
        "isoCode" :"MU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mu.svg "
    },
    {
        "nom" :"Mayotte",
        "indicatif" :"+262",
        "isoCode" :"YT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/yt.svg "
    },
    {
        "nom" :"Mexique",
        "indicatif" :"+52",
        "isoCode" :"MX",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mx.svg "
    },
    {
        "nom" :"Micronésie, États fédérés de Micronésie",
        "indicatif" :"+691",
        "isoCode" :"FM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/fm.svg "
    },
    {
        "nom" :"Moldavie",
        "indicatif" :"+373",
        "isoCode" :"MD",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/md.svg "
    },
    {
        "nom" :"Monaco",
        "indicatif" :"+377",
        "isoCode" :"MC",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mc.svg "
    },
    {
        "nom" :"Mongolie",
        "indicatif" :"+976",
        "isoCode" :"MN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mn.svg "
    },
    {
        "nom" :"Monténégro",
        "indicatif" :"+382",
        "isoCode" :"MOI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/me.svg "
    },
    {
        "nom" :"Montserrat",
        "indicatif" :"+1664",
        "isoCode" :"MS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ms.svg "
    },
    {
        "nom" :"Maroc",
        "indicatif" :"+212",
        "isoCode" :"MA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ma.svg "
    },
    {
        "nom" :"Mozambique",
        "indicatif" :"+258",
        "isoCode" :"MZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mz.svg "
    },
    {
        "nom" :"Birmanie",
        "indicatif" :"+95",
        "isoCode" :"MM",
        "drapeau" :"https://cdn.kcak11.com/CountryFlags/countries/mm.svg "
    },
    {
        "nom" :"Namibie",
        "indicatif" :"+264",
        "isoCode" :"NA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/na.svg "
    },
    {
        "nom" :"Nauru",
        "indicatif" :"+674",
        "isoCode" :"NR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/nr.svg "
    },
    {
        "nom" :"Népal",
        "indicatif" :"+977",
        "isoCode" :"NP",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/np.svg "
    },
    {
        "nom" :"Pays- Bas",
        "indicatif" :"+31",
        "isoCode" :"NL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/nl.svg "
    },
    {
        "nom" :"Antilles Néerlandaises",
        "indicatif" :"+599",
        "isoCode" :"UN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/an.svg "
    },
    {
        "nom" :"Nouvelle Calédonie",
        "indicatif" :"+687",
        "isoCode" :"NC",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/nc.svg "
    },
    {
        "nom" :"Nouvelle-Zélande",
        "indicatif" :"+64",
        "isoCode" :"NZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/nz.svg "
    },
    {
        "nom" :"Nicaragua",
        "indicatif" :"+505",
        "isoCode" :"NI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ni.svg "
    },
    {
        "nom" :"Niger",
        "indicatif" :"+227",
        "isoCode" :"NE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ne.svg "
    },
    {
        "nom" :"Nigéria",
        "indicatif" :"+234",
        "isoCode" :"NG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ng.svg "
    },
    {
        "nom" :"Nioué",
        "indicatif" :"+683",
        "isoCode" :"NU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/nu.svg "
    },
    {
        "nom" :"Île Norfolk",
        "indicatif" :"+672",
        "isoCode" :"NF",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/nf.svg "
    },
    {
        "nom" :"Macédoine du Nord",
        "indicatif" :"+389",
        "isoCode" :"MK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mk.svg "
    },
    {
        "nom" :"Îles Mariannes du Nord",
        "indicatif" :"+1670",
        "isoCode" :"MP",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mp.svg "
    },
    {
        "nom" :"Norvège",
        "indicatif" :"+47",
        "isoCode" :"NON",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/no.svg "
    },
    {
        "nom" :"Oman",
        "indicatif" :"+968",
        "isoCode" :"OM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/om.svg "
    },
    {
        "nom" :"Pakistan",
        "indicatif" :"+92",
        "isoCode" :"PK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pk.svg "
    },
    {
        "nom" :"Palaos",
        "indicatif" :"+680",
        "isoCode" :"PW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pw.svg "
    },
    {
        "nom" :"Palestine",
        "indicatif" :"+970",
        "isoCode" :"PS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ps.svg "
    },
    {
        "nom" :"Panamá",
        "indicatif" :"+507",
        "isoCode" :"PA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pa.svg "
    },
    {
        "nom" :"Papouasie-Nouvelle-Guinée",
        "indicatif" :"+675",
        "isoCode" :"PG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pg.svg "
    },
    {
        "nom" :"Paraguay",
        "indicatif" :"+595",
        "isoCode" :"PY",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/py.svg "
    },
    {
        "nom" :"Pérou",
        "indicatif" :"+51",
        "isoCode" :"PE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pe.svg "
    },
    {
        "nom" :"Philippines",
        "indicatif" :"+63",
        "isoCode" :"PH",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ph.svg "
    },
    {
        "nom" :"Pitcairn",
        "indicatif" :"+872",
        "isoCode" :"PN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pn.svg "
    },
    {
        "nom" :"Pologne",
        "indicatif" :"+48",
        "isoCode" :"PL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pl.svg "
    },
    {
        "nom" :"Portugal",
        "indicatif" :"+351",
        "isoCode" :"PT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pt.svg "
    },
    {
        "nom" :"Porto Rico",
        "indicatif" :"+1939",
        "isoCode" :"PR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pr.svg "
    },
    {
        "nom" :"Qatar",
        "indicatif" :"+974",
        "isoCode" :"QA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/qa.svg "
    },
    {
        "nom" :"Réunion",
        "indicatif" :"+262",
        "isoCode" :"RE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/re.svg "
    },
    {
        "nom" :"Roumanie",
        "indicatif" :"+40",
        "isoCode" :"RO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ro.svg "
    },
    {
        "nom" :"Russie",
        "indicatif" :"+7",
        "isoCode" :"RU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ru.svg "
    },
    {
        "nom" :"Rwanda",
        "indicatif" :"+250",
        "isoCode" :"RW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/rw.svg "
    },
    {
        "nom" :"Saint Barthélemy",
        "indicatif" :"+590",
        "isoCode" :"BL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/bl.svg "
    },
    {
        "nom" :"Sainte Hélène, Ascension et Tristan Da Cunha",
        "indicatif" :"+290",
        "isoCode" :"SH",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sh.svg "
    },
    {
        "nom" :"Saint-Kitts-et-Nevis",
        "indicatif" :"+1869",
        "isoCode" :"KN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/kn.svg "
    },
    {
        "nom" :"Sainte Lucie",
        "indicatif" :"+1758",
        "isoCode" :"LC",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/lc.svg "
    },
    {
        "nom" :"Saint-Martin",
        "indicatif" :"+590",
        "isoCode" :"MF",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/mf.svg "
    },
    {
        "nom" :"Saint Pierre et Miquelon",
        "indicatif" :"+508",
        "isoCode" :"PM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/pm.svg "
    },
    {
        "nom" :"Saint Vincent et les Grenadines",
        "indicatif" :"+1784",
        "isoCode" :"VC",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/vc.svg "
    },
    {
        "nom" :"Samoa",
        "indicatif" :"+685",
        "isoCode" :"WS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ws.svg "
    },
    {
        "nom" :"Saint-Marin",
        "indicatif" :"+378",
        "isoCode" :"SM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sm.svg "
    },
    {
        "nom" :"Sao Tomé et Principe",
        "indicatif" :"+239",
        "isoCode" :"ST",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/st.svg "
    },
    {
        "nom" :"Arabie Saoudite",
        "indicatif" :"+966",
        "isoCode" :"SA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sa.svg "
    },
    {
        "nom" :"Sénégal",
        "indicatif" :"+221",
        "isoCode" :"SN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sn.svg "
    },
    {
        "nom" :"Serbie",
        "indicatif" :"+381",
        "isoCode" :"RS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/rs.svg "
    },
    {
        "nom" :"Seychelles",
        "indicatif" :"+248",
        "isoCode" :"SC",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sc.svg "
    },
    {
        "nom" :"Sierra Leone",
        "indicatif" :"+232",
        "isoCode" :"SL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sl.svg "
    },
    {
        "nom" :"Singapour",
        "indicatif" :"+65",
        "isoCode" :"SG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sg.svg "
    },
    {
        "nom" :"Sint Maarten",
        "indicatif" :"+1721",
        "isoCode" :"SX",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sx.svg "
    },
    {
        "nom" :"Slovaquie",
        "indicatif" :"+421",
        "isoCode" :"SK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sk.svg "
    },
    {
        "nom" :"Slovénie",
        "indicatif" :"+386",
        "isoCode" :"SI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/si.svg "
    },
    {
        "nom" :"Îles Salomon",
        "indicatif" :"+677",
        "isoCode" :"SB",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sb.svg "
    },
    {
        "nom" :"Somalie",
        "indicatif" :"+252",
        "isoCode" :"SO",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/so.svg "
    },
    {
        "nom" :"Afrique du Sud",
        "indicatif" :"+27",
        "isoCode" :"ZA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/za.svg "
    },
    {
        "nom" :"Géorgie du Sud et les îles Sandwich du Sud",
        "indicatif" :"+500",
        "isoCode" :"GS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gs.svg "
    },
    {
        "nom" :"Soudan du Sud",
        "indicatif" :"+211",
        "isoCode" :"SS",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ss.svg "
    },
    {
        "nom" :"Espagne",
        "indicatif" :"+34",
        "isoCode" :"ES",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/es.svg "
    },
    {
        "nom" :"Sri Lanka",
        "indicatif" :"+94",
        "isoCode" :"LK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/lk.svg "
    },
    {
        "nom" :"Soudan",
        "indicatif" :"+249",
        "isoCode" :"SD",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sd.svg "
    },
    {
        "nom" :"Surinam",
        "indicatif" :"+597",
        "isoCode" :"SR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sr.svg "
    },
    {
        "nom" :"Svalbard et Jan Mayen",
        "indicatif" :"+47",
        "isoCode" :"SJ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sj.svg "
    },
    {
        "nom" :"Suède",
        "indicatif" :"+46",
        "isoCode" :"SE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/se.svg "
    },
    {
        "nom" :"Suisse",
        "indicatif" :"+41",
        "isoCode" :"CH",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ch.svg "
    },
    {
        "nom" :"République arabe syrienne",
        "indicatif" :"+963",
        "isoCode" :"SY",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/sy.svg "
    },
    {
        "nom" :"Taïwan",
        "indicatif" :"+886",
        "isoCode" :"TW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tw.svg "
    },
    {
        "nom" :"Tadjikistan",
        "indicatif" :"+992",
        "isoCode" :"TJ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tj.svg "
    },
    {
        "nom" :"Tanzanie, République-Unie de Tanzanie",
        "indicatif" :"+255",
        "isoCode" :"TZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tz.svg "
    },
    {
        "nom" :"Thaïlande",
        "indicatif" :"+66",
        "isoCode" :"TH",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/th.svg "
    },
    {
        "nom" :"Timor oriental",
        "indicatif" :"+670",
        "isoCode" :"TL",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tl.svg "
    },
    {
        "nom" :"Togo",
        "indicatif" :"+228",
        "isoCode" :"TG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tg.svg "
    },
    {
        "nom" :"Tokélaou",
        "indicatif" :"+690",
        "isoCode" :"TK",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tk.svg "
    },
    {
        "nom" :"Tonga",
        "indicatif" :"+676",
        "isoCode" :"À",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/to.svg "
    },
    {
        "nom" :"Trinité-et-Tobago",
        "indicatif" :"+1868",
        "isoCode" :"TT",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tt.svg "
    },
    {
        "nom" :"Tunisie",
        "indicatif" :"+216",
        "isoCode" :"TN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tn.svg "
    },
    {
        "nom" :"Turquie",
        "indicatif" :"+90",
        "isoCode" :"TR",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tr.svg "
    },
    {
        "nom" :"Turkménistan",
        "indicatif" :"+993",
        "isoCode" :"TM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tm.svg "
    },
    {
        "nom" :"Îles Turques et Caïques",
        "indicatif" :"+1649",
        "isoCode" :"TC",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tc.svg "
    },
    {
        "nom" :"Tuvalu",
        "indicatif" :"+688",
        "isoCode" :"TV",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/tv.svg "
    },
    {
        "nom" :"Ouganda",
        "indicatif" :"+256",
        "isoCode" :"UG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ug.svg "
    },
    {
        "nom" :"Ukraine",
        "indicatif" :"+380",
        "isoCode" :"UA",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ua.svg "
    },
    {
        "nom" :"Emirats Arabes Unis",
        "indicatif" :"+971",
        "isoCode" :"AE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ae.svg "
    },
    {
        "nom" :"Royaume-Uni",
        "indicatif" :"+44",
        "isoCode" :"Go",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/gb.svg "
    },
    {
        "nom" :"États-Unis",
        "indicatif" :"+1",
        "isoCode" :"US",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/us.svg "
    },
    {
        "nom" :"Îles mineures éloignées des États-Unis",
        "indicatif" :"+246",
        "isoCode" :"UMI",
        "drapeau" :"https://cdn.kcak11.com/CountryFlags/countries/umi.svg "
    },
    {
        "nom" :"Uruguay",
        "indicatif" :"+598",
        "isoCode" :"UY",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/uy.svg "
    },
    {
        "nom" :"Ouzbékistan",
        "indicatif" :"+998",
        "isoCode" :"UZ",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/uz.svg "
    },
    {
        "nom" :"Vanuatu",
        "indicatif" :"+678",
        "isoCode" :"VU",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/vu.svg "
    },
    {
        "nom" :"Venezuela, République bolivarienne du Venezuela",
        "indicatif" :"+58",
        "isoCode" :"VE",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ve.svg "
    },
    {
        "nom" :"Vietnam",
        "indicatif" :"+84",
        "isoCode" :"VN",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/vn.svg "
    },
    {
        "nom" :"Iles Vierges Britanniques",
        "indicatif" :"+1284",
        "isoCode" :"VG",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/vg.svg "
    },
    {
        "nom" :"Îles Vierges américaines",
        "indicatif" :"+1340",
        "isoCode" :"VI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/vi.svg "
    },
    {
        "nom" :"Wallis et Futuna",
        "indicatif" :"+681",
        "isoCode" :"WF",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/wf.svg "
    },
    {
        "nom" :"Yémen",
        "indicatif" :"+967",
        "isoCode" :"OUI",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/ye.svg "
    },
    {
        "nom" :"Zambie",
        "indicatif" :"+260",
        "isoCode" :"ZM",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/zm.svg "
    },
    {
        "nom" :"Zimbabwé",
        "indicatif" :"+263",
        "isoCode" :"ZW",
        "flag" :"https://cdn.kcak11.com/CountryFlags/countries/zw.svg "
    }
];