import axios from "axios";
import {
  getAuthorizationToken,
  URL_POST_BRAND,
  parameterRequest,
  URL_BRAND_CATEGORIES,
} from "./constantApi";
import { useRole } from "./constantApi";
import { IS_ADMIN_VALUE } from "@sharecode/common/redux/actions/actionTypes";

const postBrand = (form) => {
  var brand = new FormData();
  brand.append("name", form.values.name);
  brand.append("area", form.values.area);
  if (form.values.logo) {
    brand.append("logo", form.values.logo, form.values.logo.name);
  }
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .post(URL_POST_BRAND, brand, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const updateBrand = (form) => {
  var brand = new FormData();
  brand.append("name", form.form.values.name);
  if (form.form.values.logo) {
    brand.append("logo", form.form.values.logo, form.form.values.logo.name);
  }
  if (form.form.values.area) {
    brand.append("area", form.form.values.area);
  }
  if (useRole() == IS_ADMIN_VALUE) {
    brand.append("validated", form.form.values.validated);
  }

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .patch(URL_POST_BRAND + form.id + "/", brand, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const deleteBrand = (id) => {
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .delete(URL_POST_BRAND + id + "/", {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const getBrand = (page, size, search, cancelToken) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        index: index,
        element: element,
        searchVal: searchVal,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(URL_POST_BRAND + index + element + searchVal, {
          headers: results.headers,
          cancelToken: cancelToken?.current?.token,
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          if (e.response) {
            let errors = e.response.data;
            let error = [];
            Object.keys(errors).forEach((el) =>
              error.push(el + " : ", errors[el] + "\n" + "")
            );
            console.log(error);
          }
        })
    );
};

const getBrandCategories = (page, size, search, all, cancelToken) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;

  const apiUrl = all
    ? `${URL_BRAND_CATEGORIES}?all`
    : URL_BRAND_CATEGORIES + index + element + searchVal;

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        index: index,
        element: element,
        searchVal: searchVal,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(apiUrl, {
          headers: results.headers,
          cancelToken: cancelToken?.current?.token,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {})
    );
};

const postBrandCategory = (form) => {
  var category = new FormData();
  category.append("code", form.values.code);
  category.append("value", form.values.value);
  category.append("color", form.values.color);

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .post(URL_BRAND_CATEGORIES, category, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw new Error("Ce code ou cette valeur existent déjà");
        })
    );
};

const updateBrandCategory = ({ form, code }) => {
  var category = new FormData();
  category.append("code", form.values.code);
  category.append("value", form.values.value);
  category.append("color", form.values.color);

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .patch(URL_BRAND_CATEGORIES + code + "/", category, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const deleteBrandCategory = (code) => {
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .delete(URL_BRAND_CATEGORIES + code + "/", {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const getSingleBrand = (brandId) => {
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(URL_POST_BRAND + brandId + "/", {
          headers: results.headers,
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          if (e.response) {
            let errors = e.response.data;
            let error = [];
            Object.keys(errors).forEach((el) =>
              error.push(el + " : ", errors[el] + "\n" + "")
            );
            console.log(error);
          }
        })
    );
};

const getBrandTickets = (
  page,
  size,
  search,
  startDate,
  endDate,
  area,
  brandId
) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;
  var startDate = startDate
    ? `&${parameterRequest.startDate}=${startDate}`
    : `&${parameterRequest.startDate}=`;
  var endDate = endDate
    ? `&${parameterRequest.endDate}=${endDate}`
    : `&${parameterRequest.endDate}=`;
  var area = area
    ? `&${parameterRequest.area}=${area}`
    : `&${parameterRequest.area}=`;
  var brandId = brandId || "";
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(
          URL_POST_BRAND +
            `${brandId}/tickets/` +
            index +
            element +
            searchVal +
            startDate +
            endDate +
            area,
          {
            headers: results.headers,
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const getBrandShops = (id) => {
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(URL_POST_BRAND + id + "/shops/", {
          headers: results.headers,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

export const BrandService = {
  postBrand,
  getBrand,
  getBrandShops,
  deleteBrand,
  updateBrand,
  getBrandTickets,
  getSingleBrand,
  getBrandCategories,
  postBrandCategory,
  updateBrandCategory,
  deleteBrandCategory,
};
