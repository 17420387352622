// React Core and Router
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Redux
import { connect, useDispatch } from "react-redux";

// Material UI components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Layout components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Custom components
import TableRequest from "../clientXP/components/TableRequest";
import PageListing from "components/PagesListing";
import { ExportButton } from "components/ExportButton";
import { SizeSelectorFilter } from "components/Filters/SizeSelectorFilter";
import { SearchInputFilter } from "components/Filters/SearchInputFilter";

// Services and constants
import { exportTable } from "@sharecode/common/utils/helpers";
import { URL_EXPORT_LIST_COMMENTS } from "@sharecode/common/services/constantApi";
import { setSnackbar } from "@sharecode/common/redux/actions/snack";

function ClientReviews(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sizeSelected, setSizeSelected] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    searchParams.get("size")
      ? setSizeSelected({ value: searchParams.get("size"), label: searchParams.get("size") })
      : setSizeSelected({ value: "10", label: "10" });
    searchParams.get("search") && setSearchValue(searchParams.get("search"));
  }, [searchParams]);

  const handleChangeSize = (e) => {
    setSizeSelected({ value: e.value, label: e.label });
    setSearchParams({
      page: 1,
      size: e.value,
      search: searchParams.get("search") || "",
    });
  };

  const searchCtn = (e) => {
    setSearchValue(e.target.value);

    setSearchParams({
      size: searchParams.get("size") || "10",
      page: 1,
      search: e.target.value,
    });
  };

  const handleExportClientReviews = () => {
    setLoadingExport(true);

    const keys = ["search"];

    const paramsValues = {};

    keys.forEach((key) => {
      const value = searchParams.get(key);
      if (value) {
        paramsValues[key] = value;
      }
    });

    exportTable({ url: URL_EXPORT_LIST_COMMENTS, fileName: "comments.xlsx" }, paramsValues)
      .then((response) => {})
      .catch((error) => {
        dispatch(setSnackbar(true, "error", "error", "No entry to export"));
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={2}>
        <Card>
          <SuiBox
            p={2}
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            gap={2}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <SuiTypography variant="h6" fontWeight="medium">
              Avis des clients
            </SuiTypography>
            {/* <ExportButton
              disabled={props.reviews?.count === 0}
              handleExport={handleExportClientReviews}
              loading={loadingExport}
            /> */}
          </SuiBox>
          <SuiBox
            p={2}
            gap={2}
            display="flex"
            justifyContent="space-between"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <SizeSelectorFilter
              sizeValue={sizeSelected}
              handleSizeChange={handleChangeSize}
              totalCount={props.reviews?.count}
            />
            <SearchInputFilter
              placeholder="Recherche nom ou enseigne"
              handleSearchChange={searchCtn}
              searchInputValue={searchValue}
            />
          </SuiBox>

          <TableRequest />
        </Card>
        <PageListing data={props.reviews} />
      </SuiBox>
    </DashboardLayout>
  );
}
function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ClientReviews);
