import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getShop } from "@sharecode/common/redux/actions/shop";

export const useShopData = (page = 1, size = 10, search = "") => {
  const dispatch = useDispatch();
  useEffect(() => {
    new Promise(async (resolve, reject) => {
      dispatch(getShop(page, size, search))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, []);
};

export default useShopData;
