/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// services
import { ChartService } from "@sharecode/common/services";
import { useEffect, useState } from "react";

export const useScanAndEtickets = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    ChartService.getEticketScanChart().then((r) => {
      setData(r);
    });
  }, []);

  const simplePieChartData = {
    labels: Object.keys(data),
    datasets: {
      label: "Projects",
      backgroundColors: ["success", "warning"],
      data: Object.values(data),
    },
  };

  return simplePieChartData;
};

export default useScanAndEtickets;
