import axios from "axios";
import {
  getAuthorizationToken,
  URL_POST_SHOP,
  parameterRequest,
} from "./constantApi";

const getShop = (page, size, search) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        index: index,
        element: element,
        searchVal: searchVal,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(
          URL_POST_SHOP + results.index + results.element + results.searchVal,
          {
            headers: results.headers,
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const deleteShop = (id) => {
  var shopId = id;
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .delete(URL_POST_SHOP + shopId + "/", {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const postShop = (data) => {
  var shop = new FormData();
  shop.append("name", data.form.values.name);
  shop.append("is_active", "true");
  shop.append("street", data.form.values.street);
  shop.append("city", data.form.values.city);
  shop.append("zipcode", data.form.values.zipcode);
  shop.append("country", data.country.toUpperCase());
  shop.append("phone_number", data.phone_number);
  shop.append("brand", data.selectedBrand);
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        shop: shop,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .post(URL_POST_SHOP, results.shop, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    );
};

const updateShop = (data) => {
  var id = data.id;
  var shop = new FormData();
  shop.append("name", data.form.values.name);
  shop.append("is_active", "true");
  shop.append("street", data.form.values.street);
  shop.append("city", data.form.values.city);
  shop.append("zipcode", data.form.values.zipcode);
  shop.append("country", data.country.toUpperCase());
  shop.append("phone_number", data.phone_number);
  shop.append("brand", data.selectedBrand);

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        id: id,
        shop: shop,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .patch(URL_POST_SHOP + results.id + "/", results.shop, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

export const ShopService = {
  getShop,
  deleteShop,
  postShop,
  updateShop,
};
