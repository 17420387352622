import { CHARTS } from "./mocks";

import {
  URL_GET_USER_PER_WEEK,
  URL_GET_ETICKETS_PER_WEEK,
  URL_GET_ACTIVITY_PER_AREA,
  URL_GET_CLIENT_FIDELITY,
  URL_GET_FEMALE_MALE_CHART,
  URL_GET_PRO_PERSO_CHART,
  URL_GET_ETICKETS_SCAN_CHART,
  URL_GET_NB_NEW_USERS,
  URL_GET_TICKETS_DISTRIBUTION,
  URL_GET_NB_NDF,
  URL_GET_NB_LOYALTY_CARDS,
  URL_GET_NB_COMMENTS,
  URL_GET_AREAS_DISTRIBUTION,
  URL_GET_COUNT_USERS,
  URL_GET_COUNT_PRO_USERS,
} from "./constantApi";
import { HTTP } from "../utils/http-common";
const getCharts = () => {
  return new Promise((resolve, reject) => {
    resolve(CHARTS);
  });
};

const getActivityPerArea = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_ACTIVITY_PER_AREA)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getNbNewUsers = (startDate, endDate, periodicity) => {
  const params = {
    start_date: startDate ? `?start_date=${startDate}` : "?start_date=",
    end_date: endDate ? `&end_date=${endDate}` : "&end_date=",
    periodicity: periodicity ? `&periodicity=${periodicity}` : "&periodicity=",
  };

  return new Promise((resolve, reject) => {
    HTTP.get(
      URL_GET_NB_NEW_USERS +
        params.start_date +
        params.end_date +
        params.periodicity
    )

      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getTicketsDistribution = (startDate, endDate, periodicity) => {
  const params = {
    start_date: startDate ? `?start_date=${startDate}` : "?start_date=",
    end_date: endDate ? `&end_date=${endDate}` : "&end_date=",
    periodicity: periodicity ? `&periodicity=${periodicity}` : "&periodicity=",
  };

  return new Promise((resolve, reject) => {
    HTTP.get(
      URL_GET_TICKETS_DISTRIBUTION +
        params.start_date +
        params.end_date +
        params.periodicity
    )

      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getNbNDF = (startDate, endDate, periodicity) => {
  const params = {
    start_date: startDate ? `?start_date=${startDate}` : "?start_date=",
    end_date: endDate ? `&end_date=${endDate}` : "&end_date=",
    periodicity: periodicity ? `&periodicity=${periodicity}` : "&periodicity=",
  };

  return new Promise((resolve, reject) => {
    HTTP.get(
      URL_GET_NB_NDF + params.start_date + params.end_date + params.periodicity
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getNbLoyaltyCards = (startDate, endDate, periodicity) => {
  const params = {
    start_date: startDate ? `?start_date=${startDate}` : "?start_date=",
    end_date: endDate ? `&end_date=${endDate}` : "&end_date=",
    periodicity: periodicity ? `&periodicity=${periodicity}` : "&periodicity=",
  };

  return new Promise((resolve, reject) => {
    HTTP.get(
      URL_GET_NB_LOYALTY_CARDS +
        params.start_date +
        params.end_date +
        params.periodicity
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getNbComments = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_NB_COMMENTS)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getTicketsPerArea = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_AREAS_DISTRIBUTION)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getCountUsers = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_COUNT_USERS)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getCountProUsers = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_COUNT_PRO_USERS)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getNbEticketsPerWeeks = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_USER_PER_WEEK)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getNbUserPerWeeks = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_ETICKETS_PER_WEEK)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getClientFidelity = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_CLIENT_FIDELITY)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getFemaleMaleChart = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_FEMALE_MALE_CHART)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getProPersoChart = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_PRO_PERSO_CHART)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getEticketScanChart = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(URL_GET_ETICKETS_SCAN_CHART)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const ChartService = {
  getCharts,
  getActivityPerArea,
  getNbEticketsPerWeeks,
  getNbUserPerWeeks,
  getClientFidelity,
  getFemaleMaleChart,
  getProPersoChart,
  getEticketScanChart,
  getNbNewUsers,
  getTicketsDistribution,
  getNbNDF,
  getNbLoyaltyCards,
  getNbComments,
  getTicketsPerArea,
  getCountUsers,
  getCountProUsers,
};
