import { useState, useEffect } from "react";
import { connect } from "react-redux";

import Table from "examples/Tables/Table";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
import { Oval } from "@agney/react-loading";
import BlankPicture from "assets/images/no_picture.png";
import moment from "moment";

const TableRequest = ({ loading, data }) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();

  const imageStyle = { width: "50px", height: "50px", borderRadius: "6px" };

  useEffect(() => {
    if (data && data.length > 0) {
      let objColumns = [
        { name: "Logo de la enseigne", align: "center" },
        { name: "Enseigne", align: "center" },
        { name: "Total", align: "center" },
        { name: "Note de frais", align: "center" },
        { name: "Ticket photo", align: "center" },
        { name: "Date de création", align: "center" },
        { name: "Action", align: "center" },
      ];
      setColumns(objColumns);
      let obj;

      if (data && data.length > 0) {
        setRows(
          data.map((item) => {
            obj = {
              "Logo de la enseigne": (
                <img src={item.brand.logo || BlankPicture} style={imageStyle} />
              ),
              Enseigne: (
                <DefaultCell>{item.brand && item.brand.name ? item.brand.name : "-"}</DefaultCell>
              ),
              Total: <DefaultCell>{item.total ? item.total : " "}</DefaultCell>,
              "Note de frais": <DefaultCell>{item.is_expense_report ? "✔️" : "❌"}</DefaultCell>,
              "Ticket photo": <DefaultCell>{item.is_paper_ticket ? "✔️" : "❌"}</DefaultCell>,
              "Date de création": (
                <DefaultCell>
                  {item.created_at ? moment(item.created_at).format("DD-MM-YYYY") : " "}
                </DefaultCell>
              ),
              Action: <ActionCell ticket={item} id={item.id} fileUrl={item.file} />,
            };
            return obj;
          })
        );
      }
    } else {
      setColumns();
      setRows();
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun ticket trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
