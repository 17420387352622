import IconButton from "@mui/material/IconButton";
import * as types from "@sharecode/common/redux/actions/actionTypes";
import SuiBox from "components/SuiBox";
import { useDispatch, connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { useSoftUIController, setOpenSectorDrawer } from "context";
import { deleteBrandCategory } from "@sharecode/common/redux/actions/brand";
import { DefaultServiceHandler } from "utils/http-common";
import { DeleteModal } from "components/DeleteModal";
import { useState } from "react";

function ActionCell({ item }) {
  const disp = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { openSectorDrawer } = controller;
  const handleSectorDrawerOpen = () => setOpenSectorDrawer(dispatch, !openSectorDrawer);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const editSector = (category) => {
    disp({
      type: types.UPDATE_BRAND_CATEGORY,
      payload: category,
    });
    handleSectorDrawerOpen();
  };

  const deleteSector = (category) => {
    setLoadingDelete(true);
    disp(deleteBrandCategory(category.code))
      .then(() => {
        disp(DefaultServiceHandler("success", "success", "Enseigne supprimée avec succès !"));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        disp(
          DefaultServiceHandler("error", "error", "Erreur lors de la suppression de l'enseigne.")
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .finally(() => {
        setLoadingDelete(false);
        setDialogOpen(false);
      });
  };

  return (
    <>
      <DeleteModal
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleDelete={deleteSector}
        objectId={item}
        loading={loadingDelete}
      />
      <SuiBox display="flex" alignItems="center">
        <SuiBox>
          <IconButton color="info" onClick={() => editSector(item)}>
            <EditIcon />
          </IconButton>
        </SuiBox>
        <SuiBox>
          <IconButton color="error" onClick={() => setDialogOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </SuiBox>
      </SuiBox>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
