/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
// @mui material components
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import DownloadIcon from "@mui/icons-material/Download";

// Soft UI Dashboard PRO React example components
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenSectorDrawer } from "context";

// data
import TableRequest from "./components/TableRequest";
import { IS_ADMIN } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_BRAND } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { useRole } from "routes/ProtectedRoutes";
import PageListing from "components/PagesListing";
import { getBrandCategories } from "@sharecode/common/redux/actions/brand";
import SectorCreateUpdate from "examples/Drawers/sectorCreateUpdate";
import { SizeSelectorFilter } from "components/Filters/SizeSelectorFilter";
import { SearchInputFilter } from "components/Filters/SearchInputFilter";
import axios from "axios";
// Soft UI Dashboard PRO React base styles

function SectorList(props) {
  const [controller, dispatch] = useSoftUIController();
  const { openSectorDrawer } = controller;
  const handleSectorDrawerOpen = () => setOpenSectorDrawer(dispatch, !openSectorDrawer);
  const [searchParams, setSearchParams] = useSearchParams();
  const role = useRole();
  const [loading, setLoading] = useState(false);
  const [brandCategories, setBrandCategories] = useState(null);
  const disp = useDispatch();
  const [sizeSelected, setSizeSelected] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const cancelTokenSource = useRef();

  const handleChangeSize = (e) => {
    setSizeSelected({ value: e.value, label: e.label });
    setSearchParams({
      page: 1,
      size: e.value,
      search: searchParams.get("search") || "",
    });
  };

  const searchCtn = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", searchParams.get("size") ? searchParams.get("size") : 10);
    searchParams.set("search", e.target.value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const size = searchParams.get("size") || 10;
    const search = searchParams.get("search") || "";
    const page = searchParams.get("page") || 1;
    setSizeSelected({ value: size, label: size });
    setSearchValue(search);

    setSearchParams({
      page: page,
      size: size,
      search: search,
    });
  }, [window.location.search]);

  useEffect(() => {
    setLoading(true);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    cancelTokenSource.current = axios.CancelToken.source();
    new Promise(async (resolve, reject) => {
      const page = searchParams.get("page") || 1;
      const size = searchParams.get("size") || 10;
      const search = searchParams.get("search") || "";
      disp(getBrandCategories(page, size, search, "", cancelTokenSource))
        .then((response) => {
          setBrandCategories(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [window.location.search]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={2}>
          <Card>
            <SuiBox
              p={2}
              display="flex"
              gap={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
              alignItems="baseline"
              justifyContent="space-between"
            >
              <SuiTypography variant="h6">Gestion des secteurs</SuiTypography>
              <SuiBox display="flex">
                {role && [IS_ADMIN, IS_MANAGER_BRAND, IS_MANAGER_SHOP].indexOf(role) > -1 && (
                  <SuiButton variant="gradient" color="info" onClick={handleSectorDrawerOpen}>
                    <Icon>add</Icon> &nbsp; Ajouter un secteur
                  </SuiButton>
                )}
              </SuiBox>
            </SuiBox>
            <SuiBox
              p={2}
              display="flex"
              justifyContent="space-between"
              gap={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <SizeSelectorFilter
                sizeValue={sizeSelected}
                handleSizeChange={handleChangeSize}
                totalCount={brandCategories?.count}
              />

              <SearchInputFilter
                placeholder="Recherche..."
                handleSearchChange={searchCtn}
                searchInputValue={searchValue}
              />
            </SuiBox>
            <TableRequest brandCategories={brandCategories?.results || []} loading={loading} />
          </Card>
          {brandCategories && <PageListing data={brandCategories} />}
        </SuiBox>
      </DashboardLayout>
      <SectorCreateUpdate />
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(SectorList);
