// @mui material components
import Divider from "@mui/material/Divider";
import { useRef, useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import { useFormik } from "formik";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

import {
  getBrand,
  getBrandCategories,
  postBrandCategory,
  updateBrandCategory,
} from "@sharecode/common/redux/actions/brand";

import { connect, useDispatch } from "react-redux";
// Soft UI Dashboard PRO React context
import { setOpenSectorDrawer, useSoftUIController } from "context";

// Custom styles for the Configurator
import { REMOVE_EDITED_BRAND_CATEGORY } from "@sharecode/common/redux/actions/actionTypes";
import SectorCreateUpdateRoot from "./sectorCreateUpdateRoot";
// sectorCreateUpdate components
import * as Yup from "yup";
import FormField from "./FormField";

import { useSearchParams } from "react-router-dom";
import { DefaultServiceHandler } from "utils/http-common";

function sectorCreateUpdate(props) {
  const disp = useDispatch();

  const [controller, dispatch] = useSoftUIController();
  const [searchParams, setSearchParams] = useSearchParams();
  const { openSectorDrawer } = controller;
  const [isEdit, setIsEdit] = useState(false);
  const fileInputRef = useRef(null);

  const handleCloseSectorDrawer = () => {
    disp({
      type: REMOVE_EDITED_BRAND_CATEGORY,
      payload: { deleted: {} },
    });
    setOpenSectorDrawer(dispatch, false);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Réinitialise la valeur de l'élément input
    }
    formik.resetForm();
  };

  const SectorAddSchema = Yup.object({
    code: Yup.string().min(1, "").max(50, "Nom enseigne trop long!").required("Required"),
    value: Yup.string().min(1, "").required("Required"),
    color: Yup.string().min(1, "").required("Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: props.sectorToEdit.code || "",
      value: props.sectorToEdit.value || "",
      color: props.sectorToEdit.color || "#000000",
      validated: props.sectorToEdit.validated ? props.sectorToEdit.validated : true,
    },
    validationSchema: SectorAddSchema,
    onSubmit: () => {
      if (props.sectorToEdit.code) {
        modifySector(formik);
      } else {
        addSector(formik);
      }
    },
  });

  const addSector = (form) => {
    disp(postBrandCategory(form))
      .then((response) => {
        disp(
          getBrandCategories(
            searchParams.get("page"),
            searchParams.get("size"),
            searchParams.get("search")
          )
        ).then((response) => {
          handleCloseSectorDrawer();
          disp(DefaultServiceHandler("success", "success", "Enseigne Ajoutée avec succès!"));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      })
      .catch((error) => {
        disp(DefaultServiceHandler("error", "error", error));
      });
  };

  const modifySector = (form) => {
    const data = { form: form, code: props.sectorToEdit.code };
    disp(updateBrandCategory(data))
      .then((response) => {
        disp(
          getBrand(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        )
          .then((response) => {
            handleCloseSectorDrawer();
            disp(DefaultServiceHandler("success", "success", "Enseigne modifiée avec succès!"));
            setSearchParams({ search: searchParams.get('search') || "", page: 1, size: searchParams.get('size') || 10 })
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        disp(DefaultServiceHandler("error", "error", "Erreur lors de la modification."));
      });
  };

  return (
    <SectorCreateUpdateRoot variant="permanent" ownerState={{ openSectorDrawer }}>
      <form onSubmit={formik.handleSubmit}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0}
          px={3}
          pl={0}
        >
          <SuiBox>
            <SuiTypography variant="h4" color="primary">
              {props.sectorToEdit.code
                ? `Modifier ${props.sectorToEdit.value} `
                : "Ajouter un secteur"}
            </SuiTypography>
          </SuiBox>

          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseSectorDrawer}
          >
            close
          </Icon>
        </SuiBox>
        <Divider />
        <SuiBox mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="code"
                name="code"
                label="Code"
                error={formik.touched.code && formik.errors.code != null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder=""
                disabled={!!props.sectorToEdit.code}
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.code && formik.errors.code ? formik.errors.code : <></>}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <>
                <FormField
                  type="text"
                  id="value"
                  name="value"
                  label="Valeur"
                  error={formik.touched.value && formik.errors.value != null}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.value}
                  placeholder=""
                />
                <SuiBox>
                  <SuiTypography variant="caption" color="error" fontWeight="regular">
                    {formik.touched.value && formik.errors.value ? formik.errors.value : <></>}
                  </SuiTypography>
                </SuiBox>
              </>
            </Grid>

            <Grid item xs={12}>
              <FormField
                type="color"
                id="color"
                name="color"
                value={formik.values.color}
                error={formik.touched.color && formik.errors.color != null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label="Coleur"
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.color && formik.errors.color ? formik.errors.color : <></>}
                </SuiTypography>
              </SuiBox>
            </Grid>
            {isEdit && (
              <Grid item xs={12} mt={1}>
                <FormControlLabel
                  control={
                    <Switch
                      id="validated"
                      name="validated"
                      checked={formik.values.validated}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Enseigne validée"
                  value="top"
                  labelPlacement="top"
                />
              </Grid>
            )}
            <Grid container spacing={2} display="flex" justifyContent="space-between" mt={2} ml={0}>
              <Grid item xs={6}>
                <SuiButton variant="gradient" color="error" onClick={handleCloseSectorDrawer}>
                  Annuler
                </SuiButton>
              </Grid>
              <Grid item xs={6}>
                <SuiButton disabled={!formik.isValid} variant="gradient" color="info" type="submit">
                  Valider
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </form>
    </SectorCreateUpdateRoot>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(sectorCreateUpdate);
