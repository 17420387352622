import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect, useDispatch, useSelector } from "react-redux";
import { useSearchParams,useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Autocomplete, TextField } from "@mui/material";

// components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import FormField from "./FormField";
import BusinessCreateUpdateRoot from "./businessCreateUpdateRoot";
// context
import { setOpenBusinessDrawer, useSoftUIController } from "context";
// redux actions and selectors
import {
  createPosItem,
  updatePosItemById,
  clearSelectedBusinessToEdit,
  setSelectedBusinessToEdit,
  getPosItem,
} from "@sharecode/common/redux/actions/posActions";
import { getSingleBrand } from "@sharecode/common/redux/actions/brand";

// utils
import { DefaultServiceHandler } from "utils/http-common";

// hook
import { useBrandData } from "layouts/pages/brandList/data/brandData";
import { BrandService } from "@sharecode/common/services";

function BusinessCreateUpdate(props) {
  const dispatch = useDispatch();
  const [controller, softDispatch] = useSoftUIController();
  const { openBusinessDrawer } = controller;
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPartnerId = useSelector((state) => state.posReducer.selectedPartnerId);

  const navigate = useNavigate();

  const BusinessSchema = Yup.object({
    brandName: Yup.string().required("Le nom de la marque est requis"),
    brandId: Yup.string().required("L'identifiant de la marque est requis"),
    businessId: Yup.string(),
    registerId: Yup.string(),
    posLink: Yup.string(),
  }).test(
    'at-least-one-id',
    'Au moins un identifiant (ID point de vente ou ID caisse) est requis',
    (values) => !!values.businessId || !!values.registerId
  );

  const formik = useFormik({
    initialValues: {
      brandName: "",
      brandId: "",
      businessId: "",
      registerId: "",
      posLink: "",
    },
    validationSchema: BusinessSchema,
    onSubmit: (values) => {
      const businessData = {
        brands_brand_id: values.brandId,
        business_id: values.businessId || null, // Set to null if empty
        register_id: values.registerId || null, // Set to null if empty
        pos_link: values.posLink || null, // Set to null if empty
      };

      if (props.businessToEdit) {
        // Update existing business
        dispatch(updatePosItemById(selectedPartnerId, props.businessToEdit.id, businessData))
          .then(() => {
            handleCloseBusinessDrawer();
            dispatch(DefaultServiceHandler("success", "success", "PDV modifié avec succès!"));
            dispatch(getPosItem(selectedPartnerId));
            navigate("/manage-pos-partners/" + selectedPartnerId + "/business");
            setSearchParams({ ...Object.fromEntries(searchParams), refresh: Date.now() });

          })
          .catch((error) => {
            dispatch(DefaultServiceHandler("error", "error", error));
          });
      } else {
        // Create new business
        dispatch(createPosItem(selectedPartnerId, businessData))
          .then(() => {
            handleCloseBusinessDrawer();
            dispatch(DefaultServiceHandler("success", "success", "PDV ajouté avec succès!"));
            dispatch(getPosItem(selectedPartnerId));
            navigate("/manage-pos-partners/" + selectedPartnerId + "/business");
            setSearchParams({ ...Object.fromEntries(searchParams), refresh: Date.now() });;
          })
          .catch((error) => {
            dispatch(DefaultServiceHandler("error", "error", error));
          });
      }
    },
  });

  useEffect(() => {
    // Populate form fields if editing an existing business (pos item)
    if (props.businessToEdit) {
      formik.setValues({
        brandId: props.businessToEdit.brands_brand_id,
        businessId: props.businessToEdit.business_id || "",
        registerId: props.businessToEdit.register_id || "",
        posLink: props.businessToEdit.pos_link || "",
      });

      // Fetch brand details when editing
      dispatch(getSingleBrand(props.businessToEdit.brands_brand_id))
        .then((response) => {
          setSelectedBrand({
            value: response.id,
            label: response.name,
          });
        })
        .catch((error) => {
          console.error("Error fetching brand details:", error);
        });
    } else {
      formik.resetForm();
    }
  }, [props.businessToEdit]);

  const handleCloseBusinessDrawer = () => {
    setOpenBusinessDrawer(softDispatch, false);
    formik.resetForm();
    dispatch(clearSelectedBusinessToEdit());
  };

  // Fetch brand data (trigger the fetch initially)
  const [searchBrand, setSearchBrand] = useState("");
  const brands = useBrandData(1, 500, searchBrand);

  // Prepare brands for Autocomplete (using useEffect to update when brands data changes)
  const [availableBrands, setAvailableBrands] = useState([]);
  useEffect(() => {
    if (brands?.results) {
      const tempBrands = brands?.results.map((brand) => ({
        value: brand.id,
        label: brand.name,
      }));
      setAvailableBrands(tempBrands);
    }
  }, [brands?.results]);

  // Add selectedBrand state
  const [selectedBrand, setSelectedBrand] = useState(null);

  // Handle brand selection
  const handleSelectBrand = (event, value) => {
    setSelectedBrand(value);
    formik.setFieldValue('brandId', value ? value.value : "");
    formik.setFieldValue('brandName', value ? value.label : "");
  };

  // Handle brand search input change
  const handleBrandSearchChange = async (event, value) => {
    setSearchBrand(value);
    if (value) {
      const fetched = await BrandService.getBrand(1, 50, value);
      const filtered = fetched.results.map((item) => ({ value: item.id, label: item.name }));
      setAvailableBrands(filtered);
    } else {
      setAvailableBrands([]);
    }
  };

  return (
    <BusinessCreateUpdateRoot variant="permanent" ownerState={{ openBusinessDrawer }}>
      <form onSubmit={formik.handleSubmit}>
        <SuiBox display="flex" justifyContent="space-between" alignItems="baseline" pt={3} pb={0} px={3}>
          <SuiTypography variant="h4" color="primary">
            {props.businessToEdit ? "Modifier un PDV" : "Ajouter un PDV"}
          </SuiTypography>
          <Icon
            sx={{
              fontSize: "1.5rem",
              cursor: "pointer",
              mt: 2,
            }}
            onClick={handleCloseBusinessDrawer}
          >
            close
          </Icon>
        </SuiBox>
        <Divider />
        <SuiBox mt={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                  Brand
                </SuiTypography>
              </SuiBox>
              <Autocomplete
                options={availableBrands}
                getOptionLabel={(option) => option.label}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onInputChange={handleBrandSearchChange}
                onChange={handleSelectBrand}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" placeholder="Search Brand" />
                )}
                value={selectedBrand}
              />
            </Grid>

            <Grid item xs={12}>
              <FormField
                type="text"
                label="ID Point de vente"
                id="businessId"
                name="businessId"
                value={formik.values.businessId}
                onChange={formik.handleChange}
                error={formik.touched.businessId && Boolean(formik.errors.businessId)}
                helperText={formik.touched.businessId && formik.errors.businessId}
              />
            </Grid>

            <Grid item xs={12}>
              <FormField
                type="text"
                label="ID Caisse"
                id="registerId"
                name="registerId"
                value={formik.values.registerId}
                onChange={formik.handleChange}
                error={formik.touched.registerId && Boolean(formik.errors.registerId)}
                helperText={formik.touched.registerId && formik.errors.registerId}
              />
            </Grid>

                    <Grid item xs={12}>
          <FormField
            type="text"
            label="Lien google"
            id="posLink"
            name="posLink"
            value={formik.values.posLink}
            onChange={formik.handleChange}
            error={formik.touched.posLink && Boolean(formik.errors.posLink)}
            helperText={formik.touched.posLink && formik.errors.posLink}
              />
            </Grid>

            <Grid container spacing={2} justifyContent="space-between" mt={2} ml={0}>
              <Grid item xs={6}>
                <SuiButton variant="gradient" color="error" onClick={handleCloseBusinessDrawer}>
                  Annuler
                </SuiButton>
              </Grid>
              <Grid item xs={6}>
                <SuiButton disabled={!formik.isValid} variant="gradient" color="info" type="submit">
                  Valider
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </form>
    </BusinessCreateUpdateRoot>
  );
}

export default connect(null, {
  createPosItem,
  updatePosItemById,
  clearSelectedBusinessToEdit,
  setSelectedBusinessToEdit,
})(BusinessCreateUpdate);
