import { useState, useEffect, useRef } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import {
  fetchCampaigns,
  fetchCampaignStats,
} from "@sharecode/common/redux/actions/campaignsActions";
// Soft UI Dashboard PRO React components

import Table from "examples/Tables/Table";
// Soft UI Dashboard PRO React context
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { useRole } from "routes/ProtectedRoutes";
import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
import {
  IS_ADMIN_VALUE,
  IS_MANAGER_BRAND_VALUE,
  IS_MANAGER_SHOP_VALUE,
} from "@sharecode/common/redux/actions/actionTypes";
import { Oval } from "@agney/react-loading";
import moment from "moment";
import { Select, MenuItem } from "@mui/material";


const TableRequest = () => {
  const [statusFilter, setStatusFilter] = useState("Actives");
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const role = useRole();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const campaigns = useSelector((state) => state.campaignReducer.campaigns);
  const stats = useSelector((state) => state.campaignReducer.campaignStats);

  // fetching campaigns
  useEffect(() => {
    setLoading(true);
    dispatch(fetchCampaigns())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (campaigns?.data && campaigns?.data?.length > 0) {
      setLoading(true);
      Promise.all(campaigns?.data.map((campaign) => dispatch(fetchCampaignStats(campaign.id))))
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching campaign stats:", error);
          setLoading(false);
        });
    }
  }, [campaigns?.data, dispatch]);

// filter campaigns by status
  useEffect(() => {
    if (campaigns?.data && campaigns?.data.length > 0) {
      let filteredData = campaigns.data.filter((campaign) => {
        switch (statusFilter) {
          case "Actives":
            return campaign.is_active && !campaign.has_ended;
          case "Terminées":
            return campaign.has_ended || (campaign.is_active === null && campaign.has_ended);
          case "En attente":
            return campaign.is_active === null && !campaign.has_ended;
          case "Rejetées":
            return campaign.is_active === false;
          default:
            return true;
        }
      });

      // Sort the filtered data by creation date, from most recent to oldest
    filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      let objColumns = [
        { name: "Nom campagne", align: "center" },
        { name: "Statut", align: "center" },
        { name: "Date de création", align: "center" },
        { name: "Nb stories", align: "center" },
        { name: "CPC", align: "center" },
        { name: "Favoris", align: "center" },
        { name: "Vues", align: "center" },
        { name: "Clics", align: "center" },
        { name: "Budget", align: "center" },
        { name: "Consommé", align: "center" },
      ];

      if (
        role &&
        [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1
      ) {
        objColumns.push({ name: "Action", align: "center" });
      }
      setColumns(objColumns);

      let obj;

      setRows(
        filteredData.map((item) => {
          let status;
          if (item.has_ended || (item.is_active === null && item.has_ended)) {
            status = "Terminée";
          } else if (item.is_active === null && !item.has_ended) {
            status = "En attente";
          } else if (item.is_active === false) {
            status = "Rejetée";
          } else {
            status = "Active";
          }
          let clickBidEuro = item.clickBid / 100;

          obj = {
            "Nom campagne": <DefaultCell>{item?.campaignName}</DefaultCell>,
            Statut: <DefaultCell>{status}</DefaultCell>,
            "Date de création": (
              <DefaultCell>{moment(item?.created_at).format("DD/MM/YYYY")}</DefaultCell>
            ),
            "Nb stories": <DefaultCell>{item.ads_count - item.ads_deleted_count}</DefaultCell>,
            CPC: <DefaultCell>{clickBidEuro.toFixed(2)}€</DefaultCell>,
            Favoris: <DefaultCell>{stats[item.id]?.data?.totalWishlist}</DefaultCell>,
            Vues: <DefaultCell>{stats[item.id]?.data?.totalViewed}</DefaultCell>,
            Clics: <DefaultCell>{stats[item.id]?.data?.totalClicked}</DefaultCell>,
            Budget: <DefaultCell>{(item?.budget / 100).toFixed(2)}</DefaultCell>,
            Consommé: (
              <DefaultCell>{(stats[item.id]?.data?.totalClicked * clickBidEuro).toFixed(2)}</DefaultCell>
            ),
          };

          if (
            role &&
            [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1
          ) {
            Object.assign(obj, { Action: <ActionCell id={item.id} status={status} /> });
          }

          return obj;
        })
      );
    } else {
      setRows([]);
    }
  }, [campaigns?.data, stats, statusFilter, role]);

  return (
    <>
      <SuiBox textAlign="center" marginBottom={3} width={200} marginLeft={5}>
        <SuiTypography htmlFor="statusFilter" style={{ marginRight: "10px" }}>
          Filtrer par statut:
        </SuiTypography>
        <Select
          id="statusFilter"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          style={{ padding: "10px", borderRadius: "5px", outline: "none" }}
        >
          <MenuItem value="Toutes">Toutes</MenuItem>
          <MenuItem value="Actives">Actives</MenuItem>
          <MenuItem value="Terminées">Terminées</MenuItem>
          <MenuItem value="En attente">En attente</MenuItem>
          <MenuItem value="Rejetées">Rejetées</MenuItem>
        </Select>
      </SuiBox>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucune campagne trouvée
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
