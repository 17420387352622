import { useState, useEffect } from "react";
import { connect } from "react-redux";
// Soft UI Dashboard PRO React components

import Table from "examples/Tables/Table";
// Soft UI Dashboard PRO React context
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { useRole } from "routes/ProtectedRoutes";
import { useSearchParams } from "react-router-dom";
import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
import {
  IS_ADMIN_VALUE,
  IS_MANAGER_BRAND_VALUE,
  IS_MANAGER_SHOP_VALUE,
} from "@sharecode/common/redux/actions/actionTypes";
import { Oval } from "@agney/react-loading";
import SuiBadgeDot from "components/SuiBadgeDot";

const TableRequest = ({ brandCategories, loading }) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const role = useRole();

  useEffect(() => {
    if (brandCategories) {
      let objColumns = [
        { name: "Code", align: "center" },
        { name: "Valeur", align: "center" },
        { name: "Coleur", align: "center" },
      ];
      if (
        role &&
        [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1
      ) {
        objColumns.push({ name: "Action", align: "center" });
      }
      setColumns(objColumns);
      let obj;
      setRows(
        brandCategories.map((item) => {
          obj = {
            Code: <DefaultCell>{item.code || "-"}</DefaultCell>,
            Valeur: <DefaultCell>{item.value || "-"}</DefaultCell>,
            Coleur: (
              <SuiBadgeDot
                badgeContent={item.color || "-"}
                color={item.color || "#000"}
                size="lg"
              />
            ),
          };

          if (
            role &&
            [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1
          ) {
            Object.assign(obj, { Action: <ActionCell item={item} /> });
          }

          return obj;
        })
      );
    }
  }, [brandCategories]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun secteur trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
