import { CircularProgress } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";

export function SectorSelectFilter({ brandOptions, handleChangeBrand, brandValue, loading }) {
  return (
    <SuiBox width={250}>
      {loading ? (
        <SuiBox display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={20} color="secondary" />
        </SuiBox>
      ) : (
        <SuiSelect
          placeholder="Sélectionner le secteur"
          options={brandOptions}
          onChange={(e) => handleChangeBrand(e)}
          value={brandValue}
          isClearable
          maxMenuHeight={150}
        />
      )}
    </SuiBox>
  );
}
