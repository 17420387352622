import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useField, ErrorMessage } from "formik";
import Slider from "@mui/material/Slider";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InputAdornment from "@mui/material/InputAdornment";

// internal imports
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";

// Custom slider for cost per click
const CustomSlider = styled(Slider)(({ theme, disabled }) => ({
  "& .MuiSlider-rail": {
    opacity: disabled ? 0.5 : 1,
    backgroundColor: disabled ? "#bfbfbf" : "",
  },
  "& .MuiSlider-track": {
    backgroundColor: disabled ? "#bfbfbf" : "transparent",
    backgroundImage: disabled
      ? "linear-gradient(90deg, #bfbfbf 0%, #bfbfbf 100%)"
      : "linear-gradient(310deg, #2152ff, #21d4fd)",
    height: "20px",
    borderRadius: "10px",
  },
  // "& .MuiSlider-rail::before": {
  //   content: '""',
  //   position: "absolute",
  //   height: "500%",
  //   width: "20%",
  //   backgroundColor: "#89FF8E",
  //   left: "25%",
  //   top: "50%",
  //   transform: "translateY(-50%)",
  // },
  "& .MuiSlider-thumb": {
    zIndex: 2,
    backgroundColor: disabled ? "#bfbfbf" : "",
  },
}));

// Custom slider for budget split
const BudgetSplitSliderStyled = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: theme.palette.primary.main,
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
  "& .MuiSlider-track": {
    height: 8,
    borderRadius: 4,
  },
  "& .MuiSlider-rail": {
    color: "#bfbfbf",
    opacity: 1,
    height: 8,
    borderRadius: 4,
  },
}));

function FormField({
  label,
  name,
  type,
  maxBudget,
  editMode = false,
  costPerClick,
  overallAverageBid,
  ...rest
}) {
  const [field, , helpers] = useField(name);

  const [cpcSliderValue, setCpcSliderValue] = useState(
    typeof field.value === "number" ? field.value : 0
  ); // cost per click slider value
  const [budgetSplitValue, setBudgetSplitValue] = useState(field.value || 0); // budget split slider value
  const [fileName, setFileName] = useState("");

  // Update slider values when field value changes
  useEffect(() => {
    if (editMode && typeof field.value === "number") {
      setCpcSliderValue(field.value);
      setBudgetSplitValue(field.value);
    }
  }, [field.value, editMode]);

  // Generic handler for text input changes
  const handleInputChange = (event) => {
    const { value } = event.target;
    helpers.setValue(value);
  };

  const handleCpcSliderChange = (event, newValue) => {
    setCpcSliderValue(newValue);
    helpers.setValue(newValue);
  };

  const handleBudgetSplitChange = (event, newValue) => {
    setBudgetSplitValue(newValue);
    helpers.setValue(newValue);
  };
  // Handler for file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); // Set the file name for display
      helpers.setValue(file); // Store the file in Formik's state
    }
  };

  // handle for countries select
  const handleSelectChange = (selectedOptions) => {
    helpers.setValue(
      selectedOptions.map((option) => ({ label: option.label, value: option.value }))
    );
  };

  const renderInput = () => {
    switch (type) {
      case "slider":
        return (
          <Box width="100%">
            <CustomSlider
              value={typeof cpcSliderValue === "number" ? cpcSliderValue : 0}
              onChange={handleCpcSliderChange}
              min={0.1}
              max={10.0}
              step={0.05}
              valueLabelDisplay="auto"
              disabled={rest.disabled}
              {...rest}
            />
            <SuiTypography variant="caption">
              Le coût par clic moyen dans le(s) pays sélectionné(s):{" "}
              <span style={{ backgroundColor: "#89FF8E" }}>
                {overallAverageBid ? overallAverageBid : 0} €
              </span>
            </SuiTypography>

            <SuiTypography variant="caption" display="block" gutterBottom>
              Votre coût par clic: {cpcSliderValue} €
            </SuiTypography>
          </Box>
        );
        break;
      case "budgetSplitSlider":
        return (
          <Box width="100%">
            <BudgetSplitSliderStyled
              value={typeof budgetSplitValue === "number" ? budgetSplitValue : 0}
              onChange={handleBudgetSplitChange}
              min={0}
              max={maxBudget}
              step={costPerClick}
              valueLabelDisplay="auto"
              {...rest}
            />
            <Box display="flex" justifyContent="space-between">
              <SuiTypography variant="caption">0€</SuiTypography>
              <SuiTypography variant="caption">{maxBudget}€</SuiTypography>
            </Box>
          </Box>
        );
      case "file":
        return (
          <Box sx={{ mt: 2, width: "100%" }}>
            <input
              accept="image/*,video/*"
              style={{ display: "none" }}
              id={`file-upload-${name}`}
              multiple
              type="file"
              onChange={handleFileChange}
              {...rest}
            />
            <TextField
              fullWidth
              disabled
              variant="outlined"
              value={fileName || "Aucun fichier choisi"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <label htmlFor={`file-upload-${name}`}>
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <CloudUploadIcon />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        );
      case "select":
        return (
          <SuiSelect
            {...field}
            {...rest}
            style={{ marginTop: "8px" }}
            onChange={handleSelectChange}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            isMulti
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
          />
        );
      default:
        return (
          <div style={{ marginTop: "8px" }}>
            <SuiInput onChange={handleInputChange} {...field} {...rest} />
          </div>
        );
    }
  };

  return (
    <SuiBox mb={3}>
      <div style={{ display: "flex", flexDirection: "column", marginBottom: "inherit" }}>
        <SuiBox mb={1} ml={0.5} lineHeight={0}>
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </SuiTypography>
        </SuiBox>
        {rest.description && (
          <SuiTypography
            variant="caption"
            style={{
              fontStyle: "italic",
              color: "#3447678C",
            }}
          >
            {rest.description}
          </SuiTypography>
        )}
      </div>
      {renderInput()}
      <SuiBox mt={1.5}>
        <SuiTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
};

export default FormField;
