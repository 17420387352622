/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-chartjs-2 components
import { Pie } from "react-chartjs-2";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadgeDot from "components/SuiBadgeDot";

// simplePieChart configurations
import configs from "examples/Charts/PieChart/simplePieChart/configs";

function SimplePieChart({ title, description, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {}, chart.cutout);

  const renderBadgeDots = chart.labels
    ? Array.from(chart.labels)
        .reverse()
        .map((label, index) => {
          const badgeDotKey = `badge-dot-${index}`;

          const reversedBadgeColors =
            chart.datasets && Array.isArray(chart.datasets.backgroundColors)
              ? Array.from(chart.datasets.backgroundColors).reverse()
              : [];

          return (
            <SuiBadgeDot
              key={badgeDotKey}
              color={chart.datasets.backgroundColors ? reversedBadgeColors[index] : "info"}
              size="lg"
              badgeContent={label}
              font={{ color: "text", weight: "medium" }}
            />
          );
        })
    : null;

  return (
    <Card>
      <SuiBox p={2}>
        {title || description ? (
          <SuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
            {title && (
              <SuiBox mb={1}>
                <SuiTypography variant="h6">{title}</SuiTypography>
              </SuiBox>
            )}
            <SuiBox mb={2}>
              <SuiTypography component="div" variant="button" fontWeight="regular" color="text">
                {description}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null}

        <SuiBox sx={{ height: "12rem" }} mt={4}>
          <Pie data={data} options={options} />
        </SuiBox>
        <Divider sx={{ marginTop: "45px" }} />
        <SuiBox display="flex" alignItems="center" justifyContent="center">
          {renderBadgeDots}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of SimplePieChart
SimplePieChart.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the SimplePieChart
SimplePieChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default SimplePieChart;
