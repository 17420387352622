export const servicesType = {
  kpis: "KPIs",
  nbNewUsers: "nbNewUsers",
};

export const periodicityOptions = [
  { value: "day", label: "Jour" },
  { value: "week", label: "Semaine" },
  { value: "month", label: "Mois" },
];
