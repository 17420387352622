/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { connect } from "react-redux";
import Divider from "@mui/material/Divider";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenShopDrawer } from "context";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { postShop, updateShop, getShop } from "@sharecode/common/redux/actions/shop";
import FormField from "../brandCreateUpdate/FormField";
import SuiButton from "components/SuiButton";
import { useBrandData } from "layouts/pages/brandList/data/brandData";
import { SHOW_ALERT } from "@sharecode/common/redux/actions/actionTypes";

import {
  countries,
  getCountries,
  getCountryFromIso,
  getCountryFromIndicatif,
} from "@sharecode/common/utils/useCountriesList";
// Custom styles for the Configurator
import ShopCreateUpdateRoot from "./shopCreateUpdateRoot";
import { REMOVE_EDITED_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { useRole } from "routes/ProtectedRoutes";
import { IS_ADMIN } from "@sharecode/common/redux/actions/actionTypes";
import { IS_EMPLOYEE_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { DefaultServiceHandler } from "utils/http-common";

function shopCreateUpdate(props) {
  const [controller, dispatch] = useSoftUIController();
  const [availableBrands, setAvailableBrands] = useState();
  const [brandSelected, setBrandSelected] = useState();
  const [countrySelected, setCountrySelected] = useState({ value: "", label: "" });
  const [indicatif, setIndicatif] = useState();
  const [lCountries, setLCountries] = useState();
  const { openShopDrawer } = controller;
  const [searchParams, setSearchParams] = useSearchParams();
  const disp = useDispatch();
  const role = useRole();
  if (role === IS_ADMIN) {
    const brands = useBrandData(1, 500, "");
    useEffect(() => {
      const tempBrand = [];
      if (props.brand.results && props.brand.results.length > 0) {
        props.brand.results.map((item) => {
          // if (item.is_active)
          tempBrand.push({ value: item.id, label: item.name });
        });
        setAvailableBrands(tempBrand);
      }
    }, [props.brand.results]);
  }

  useEffect(() => {
    getCountries().then((response) => {
      setLCountries(response);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(props.shopToEdit).length !== 0 && !props.shopToEdit.deleted) {
      setBrandSelected({
        value: props.shopToEdit.brand.id ? props.shopToEdit?.brand?.id : "",
        label: props.shopToEdit.brand.name ? props.shopToEdit.brand.name : "",
      });
      getCountryFromIso(props.shopToEdit.country).then((response) => {
        setCountrySelected({ value: response.isoCode.trim(), label: response.nom.trim() });
        setIndicatif(response.indicatif.trim());
      });
    }
  }, [props.shopToEdit]);

  const ShopAddSchema = Yup.object().shape({
    name: Yup.string().min(1, "").max(50, "Nom magasin trop long!").required("Required"),
    city: Yup.string().min(1, "").max(50, "Nom ville trop long!").required("Required"),
    country: Yup.string().min(1, "").max(50, "Nom pays trop long!").required("Required"),
    street: Yup.string().min(1, "").max(50, "texte trop long!").required("Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: props.shopToEdit.name ? props.shopToEdit.name : "",
      city: props.shopToEdit.city ? props.shopToEdit.city : "",
      country: props.shopToEdit.country ? props.shopToEdit.country : "",
      street: props.shopToEdit.street ? props.shopToEdit.street : "",
      brand: props.shopToEdit.id
        ? { value: props.shopToEdit.brand.id, label: props.shopToEdit.brand.name }
        : { value: "", label: "" },
      zipcode: props.shopToEdit.zipcode ? props.shopToEdit.zipcode : "",
      number: props.shopToEdit.number ? props.shopToEdit.number : "",
    },
    ShopAddSchema,
  });

  const handleCloseShopDrawer = () => {
    disp({
      type: REMOVE_EDITED_SHOP,
      payload: { deleted: {} },
    });
    setOpenShopDrawer(dispatch, false);
    formik.resetForm();
  };
  const addShop = (form) => {
    const data = {
      form: form,
      selectedBrand:
        role !== IS_ADMIN
          ? props.shop.results[0].brand.id
          : brandSelected.value
          ? brandSelected.value
          : "",
      country: countrySelected.value ? countrySelected.value : "",
      phone_number: indicatif + form.values.number,
    };
    disp(postShop(data))
      .then((response) => {
        disp(
          getShop(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        ).then((response) => {
          handleCloseShopDrawer();
          disp(DefaultServiceHandler("success", "success", "Point de vente ajouté avec succès!"));
        });
      })
      .catch((error) => {
        let message = "";
        if (error.response.data.phone_number !== undefined) {
          message = error.response.data.phone_number;
        } else if (error.response.data.name !== undefined) {
          message = error.response.data.name;
        }
        disp(DefaultServiceHandler("error", "error", message));
      });
  };

  const modifyShop = (form) => {
    const data = {
      form: form,
      id: props.shopToEdit.id,
      selectedBrand: brandSelected.value ? brandSelected.value : "",
      country: countrySelected.value ? countrySelected.value : "",
      phone_number: indicatif + form.values.number,
    };
    disp(updateShop(data))
      .then((response) => {
        disp(
          getShop(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        ).then((response) => {
          handleCloseShopDrawer();
          disp(DefaultServiceHandler("success", "success", "Point de vente modifié avec succès!"));
        });
      })
      .catch((error) => {
        console.log(error);
        disp(
          DefaultServiceHandler(
            "error",
            "error",
            "Erreur lors de la modification du point de vente."
          )
        );
      });
  };

  const handleSelectChange = (event) => {
    if (event === null) {
      setBrandSelected({ value: "", label: "" });
    } else {
      setBrandSelected({ value: event.value, label: event.label });
    }
  };

  const handleSelectChangeCountry = (event) => {
    setCountrySelected({ value: event.value, label: event.label });
    getCountryFromIso(event.value.toUpperCase()).then((response) => {
      setIndicatif(response.indicatif);
    });
  };

  return (
    <ShopCreateUpdateRoot variant="permanent" ownerState={{ openShopDrawer }}>
      <form onSubmit={formik.handleSubmit}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0}
          px={3}
          pl={0}
        >
          <SuiBox>
            <SuiTypography variant="h4" color="primary">
              {!props.shopToEdit || Object.keys(props.shopToEdit).length === 0
                ? "Ajouter un magasin"
                : "Modifier un magasin"}
            </SuiTypography>
          </SuiBox>

          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseShopDrawer}
          >
            close
          </Icon>
        </SuiBox>
        <Divider />
        <SuiBox mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="name"
                name="name"
                label="Nom du magasin"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder=""
              />
            </Grid>
            <Grid item xs={12}>
              {role === IS_ADMIN ? (
                <>
                  <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Enseigne
                    </SuiTypography>
                  </SuiBox>

                  <SuiSelect
                    isClearable={true}
                    options={availableBrands}
                    name=""
                    onChange={(e) => {
                      handleSelectChange(e);
                    }}
                    defaultValue={brandSelected}
                    value={brandSelected}
                  />
                </>
              ) : (
                <FormField
                  label="Enseigne"
                  placeholder=""
                  icon={{ component: "lock", direction: "right" }}
                  value={brandSelected}
                  disabled
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="street"
                name="street"
                label="Adresse"
                onChange={formik.handleChange}
                value={formik.values.street}
                placeholder=""
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="city"
                name="city"
                label="Ville"
                onChange={formik.handleChange}
                value={formik.values.city}
                placeholder=""
              />
            </Grid>
            <Grid item xs={12}>
              <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Pays
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                options={lCountries}
                onChange={(e) => handleSelectChangeCountry(e)}
                value={countrySelected}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="zipcode"
                name="zipcode"
                label="Code Postal"
                onChange={formik.handleChange}
                value={formik.values.zipcode}
                placeholder=""
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="phone_number"
                name="number"
                label="Téléphone"
                icon={{
                  direction: "left",
                  type: "text",
                  adornement: <span>{indicatif}</span>,
                }}
                onChange={formik.handleChange}
                value={formik.values.number}
                placeholder=""
              />
            </Grid>
            <Grid container justifyContent="space-between" mt={4}>
              <Grid item xs={6} textAlign={"center"}>
                <SuiButton variant="gradient" color="error" onClick={handleCloseShopDrawer}>
                  Annuler
                </SuiButton>
              </Grid>
              <Grid item xs={6} textAlign={"center"}>
                <SuiButton
                  disabled={!formik.dirty || !formik.isValid}
                  variant="gradient"
                  color="info"
                  onClick={() => (props.shopToEdit.id ? modifyShop(formik) : addShop(formik))}
                >
                  Valider
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </form>
    </ShopCreateUpdateRoot>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(shopCreateUpdate);
