import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getShop } from "@sharecode/common/redux/actions/shop";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import Table from "examples/Tables/Table";
import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React context
import {
  IS_EMPLOYEE_SHOP,
  IS_ADMIN,
  IS_MANAGER_BRAND,
  IS_MANAGER_SHOP,
} from "@sharecode/common/redux/actions/actionTypes";
import { useRole } from "routes/ProtectedRoutes";
import { useSearchParams } from "react-router-dom";
import ActionCell from "../ActionCell";
import { Oval } from "@agney/react-loading";

const TableRequest = (props) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const role = useRole();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    new Promise(async (resolve, reject) => {
      const page = searchParams.get("page");
      const size = searchParams.get("size");
      const search = searchParams.get("search");
      dispatch(getShop(page, size, search))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [window.location.search]);

  useEffect(() => {
    if (props.shop.results && props.shop.results.length > 0) {
      let objColumns = [];
      if (role && [IS_ADMIN].indexOf(role) > -1) {
        objColumns.push({ name: "Enseigne", align: "center" });
      }

      objColumns.push(
        { name: "Nom", align: "center" },
        { name: "Ville", align: "center" },
        { name: "Téléphone", align: "center" },
        { name: "Etat", align: "center" }
      );

      if (role && [IS_ADMIN, IS_MANAGER_BRAND, IS_MANAGER_SHOP].indexOf(role) > -1) {
        objColumns.push({ name: "Action", align: "center" });
      }
      setColumns(objColumns);
      let obj;

      setRows(
        props.shop.results.map((item) => {
          let obj = {};
          if (role && [IS_ADMIN].indexOf(role) > -1) {
            Object.assign(obj, { Enseigne: item.brand ? item.brand.name : "" });
          }

          Object.assign(
            obj,
            { Nom: item.name },
            { Ville: item.city ? item.city : "" },
            { Téléphone: item.phone_number ? item.phone_number : "" },
            {
              Etat: (
                <SuiBox ml={-1.325}>
                  <SuiBadge
                    variant="contained"
                    color={item.is_active ? "success" : "error"}
                    size="xs"
                    badgeContent={item.is_active ? "Actif" : "inactif"}
                    container
                  />
                </SuiBox>
              ),
            }
          );
          if (role && [IS_ADMIN, IS_MANAGER_BRAND, IS_MANAGER_SHOP].indexOf(role) > -1) {
            Object.assign(obj, { Action: <ActionCell id={item.id} /> });
          }

          return obj;
        })
      );
    }
  }, [props.shop.results]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun point de vente trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
