/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// ComplexReportsDoughnutChart configurations
import configs from "./configs";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { FixedSizeList as List } from "react-window";

function SimpleComplexReportsDoughnutChart({ title, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const Row = chart.labels
    ? ({ index, style }) => (
        <Grid container justifyContent="space-between" alignItems="center" key={index}>
          <Grid item xs={10}>
            <SuiBox display="flex" py={1.5} px={2} style={style}>
              <SuiBox mr={1}>
                <SuiBox
                  bgColor={
                    chart.datasets.backgroundColors && chart.labels[index] != "Non défini"
                      ? chart.datasets.backgroundColors[index]
                      : "light"
                  }
                  width="1rem"
                  height="1rem"
                  borderRadius="lg"
                  shadow="md"
                ></SuiBox>
              </SuiBox>

              <SuiBox display="flex" flexDirection="column" justifyContent="center">
                <SuiTypography
                  component="div"
                  variant="button"
                  textTransform="capitalize"
                  fontWeight="medium"
                >
                  {chart.labels[index]}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={2}>
            <SuiBox py={0.8} px={5} textAlign="end" style={style}>
              <SuiTypography variant="caption" color="text" fontWeight="medium">
                {chart.datasets && chart.datasets.data ? chart.datasets.data[index] : 0}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      )
    : null;

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SuiTypography variant="h6">{title}</SuiTypography>
      </SuiBox>
      <SuiBox position="relative" p={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={5} sx={{ textAlign: "center" }}>
            <SuiBox height="100%" display="flex" flexDirection="column">
              <SuiBox height="100%" mt={5} mx={1}>
                {useMemo(
                  () => (
                    <Doughnut data={data} options={options} />
                  ),
                  [chart]
                )}
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={7}>
            <List height={250} itemCount={chart.datasets.data.length} itemSize={35}>
              {Row}
            </List>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of ComplexReportsDoughnutChart
SimpleComplexReportsDoughnutChart.defaultProps = {};

// Typechecking props for the ComplexReportsDoughnutChart
SimpleComplexReportsDoughnutChart.propTypes = {
  title: PropTypes.string.isRequired,
  chart: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])
    ).isRequired,
  }).isRequired,
};

export default SimpleComplexReportsDoughnutChart;
