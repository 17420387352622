// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConf = {
  apiKey: "AIzaSyDbTNx8t71itzSu0gfMKXZfXaBUidL4Tpg",
  authDomain: "check-345512.firebaseapp.com",
  projectId: "check-345512",
  storageBucket: "check-345512.appspot.com",
  messagingSenderId: "930653427216",
  appId: "1:930653427216:web:0fec4741443dcfffbcdc95",
};

// Initialize Firebase
const app = initializeApp(firebaseConf);

export const firebaseConfig = {app, firebaseConf};