import React from "react";

// assets
import phonePreviewImage from "../../../../../assets/images/black_iphone_empty_wallpaper.png";

const EmptyPhonePreview = ({ storyImage }) => {
  return (
    <div style={{ width: "100%", height: "auto", paddingLeft: "100px" }}>
      <img
        src={storyImage || phonePreviewImage}
        alt="Phone preview"
        style={{ maxWidth: "100%", height: "auto" }}
      />
    </div>
  );
};

export default EmptyPhonePreview;
