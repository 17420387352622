import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
// redux
import { useSelector } from "react-redux";

import arrowIcon from "../../../../../assets/images/arrow.png";

// styles
import "./PhonePreview.css";

const PhonePreview = ({ stories, showNavigation = true }) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [mediaSource, setMediaSource] = useState("");
  const [isLiked, setIsLiked] = useState(false);

  const brandLogo = useSelector((state) => state?.user?.results[0]?.brand?.logo);

  useEffect(() => {
    const currentStory = stories[currentStoryIndex];
    if (currentStory) {
      if (currentStory.visualChoice instanceof File) {
        // When visualChoice is a File object, create an object URL
        const mediaSrc = URL.createObjectURL(currentStory.visualChoice);
        setMediaSource(mediaSrc);

        return () => {
          URL.revokeObjectURL(mediaSrc); // Clean up object URL
        };
      } else if (typeof currentStory.visualChoice === "string") {
        // When visualChoice is a URL string, use it directly
        setMediaSource(currentStory.visualChoice);
      }
    }
  }, [currentStoryIndex, stories]);

  const handleLikeUnlike = () => {
    setIsLiked(!isLiked); // Toggle the "liked" state
  };

  const handleOfferClick = (event) => {
    event.preventDefault(); // Prevent any default action
    event.stopPropagation(); // Stop the event from propagating
    // Logic to handle offer click
    const currentStory = stories[currentStoryIndex];
    if (currentStory && currentStory.redirectURL) {
      window.open(currentStory.redirectURL, "_blank");
    }
  };

  //function to navigate to the next story
  const navigateToStory = (index) => {
    if (index < 0) {
      setCurrentStoryIndex(0);
    } else if (index >= stories.length) {
      setCurrentStoryIndex(stories.length - 1);
    } else {
      setCurrentStoryIndex(index);
    }
  };

  // Get the current story to display
  const currentStory = stories[currentStoryIndex];

  const videoKey = `video-${currentStoryIndex}-${currentStory?.storyName?.replace(/\s+/g, "-")}`;

  return (
    <div className="phone-background-container">
      <div className="phone-container">
        {/* Header with brand logo and like button */}
        <div className="phone-header">
          {/* Brand logo */}
          <div className="brand-logo-container">
            <div className="brand-logo">
              <img
                src={brandLogo}
                alt="Brand logo"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>

          {/* Like button */}
          <IconButton onClick={handleLikeUnlike}>
            {isLiked ? (
              <FontAwesomeIcon icon={faHeartSolid} style={{ color: "#2794F9" }} />
            ) : (
              <FontAwesomeIcon icon={faHeartRegular} />
            )}
          </IconButton>
        </div>

        {/* Story Media */}
        {currentStory &&
        (currentStory.visualChoice.type === "video/mp4" || mediaSource.endsWith(".mp4")) ? (
          <video key={videoKey} className="story-media" autoPlay>
            <source src={mediaSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img className="story-media" src={mediaSource} alt={currentStory.storyName} />
        )}

        {/* Next/previous buttons */}
        {showNavigation && (
          <div className="navigation-buttons">
            <IconButton onClick={() => navigateToStory(currentStoryIndex - 1)}>
              <ArrowBackIosNewIcon style={{ color: "#2794F9" }} />
            </IconButton>
            <IconButton onClick={() => navigateToStory(currentStoryIndex + 1)}>
              <ArrowForwardIosIcon style={{ color: "#2794F9" }} />
            </IconButton>
          </div>
        )}

        {/* Footer with offer button */}
        <div className="phone-footer">
          <button
            type="button"
            className="offer-button"
            onClick={(event) => handleOfferClick(event)}
            title={currentStory ? currentStory.redirectURL : "No offer available"}
          >
            Profitez de l'offre
          </button>
          <img className="arrow-icon" src={arrowIcon} alt="Arrow icon" />
        </div>
      </div>
    </div>
  );
};

export default PhonePreview;
