import checkout from "./form";

const {
  formField: {
    campaignName,
    maxBudget,
    costPerClick,
    storyName,
    visualChoice,
    redirectURL,
    budgetSplit,
  },
} = checkout;

// Function to generate initial values for editing mode
const generateEditInitialValues = (campaignData, adsData) => {
  const campaign = campaignData?.data[0];
  const ads = adsData?.data;

  // Initialize campaign values
  const initialValues = {
    [campaignName.name]: campaign?.campaignName || "",
    [maxBudget.name]: campaign?.budget / 100 || 0,
    [costPerClick.name]: campaign?.clickBid / 100,
    campaignId: campaign?.id,
    campaignRemainingClicks: campaign?.clickLimit - campaign?.clickCurrent,
    // Initialize an empty array for ads
    ads: [],
  };

  // Sort and add available ads
  if (ads) {
    const sortedAds = ads.sort((a, b) => a.ads_campaign_order_nb - b.ads_campaign_order_nb);

    sortedAds.forEach((ad) => {
      initialValues.ads.push({
        [storyName.name]: ad.ads_name || "",
        [visualChoice.name]: ad.media_link || "",
        [redirectURL.name]: ad.promo_link || "",
        [budgetSplit.name]: ad.budget / 100 || 0,
        adsId: ad.id,
        clickCurrent: ad.click_current,
        clickLimit: ad.click_limit,
        deleted: ad?.deleted_at,
        orderNumber: ad?.ads_campaign_order_nb,
      });
    });
  }

  // Fill in the remaining spots with default values if there are fewer than 5 ads
  for (let i = initialValues.ads.length; i < 5; i++) {
    initialValues.ads.push({
      [storyName.name]: "",
      [visualChoice.name]: "",
      [redirectURL.name]: "",
      [budgetSplit.name]: 0,
      adsId: "",
      clickCurrent: 0,
      clickLimit: undefined, // Added to match the structure, might need to adjust based on actual requirements
      deleted: undefined, // Added to match the structure, might need to adjust based on actual requirements
      orderNumber: undefined, // Added to ensure all fields are consistent
    });
  }

  return initialValues;
};

export default generateEditInitialValues;
