import { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import { getReviews } from "@sharecode/common/redux/actions/reviews";
// Soft UI Dashboard PRO React components
import Rating from "@mui/material/Rating";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
// Soft UI Dashboard PRO React context
import { useRole } from "routes/ProtectedRoutes";
import { Link, useSearchParams } from "react-router-dom";
import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
import UserCell from "../UserCell";
import {
  IS_EMPLOYEE_BRAND_VALUE,
  IS_ADMIN_VALUE,
} from "@sharecode/common/redux/actions/actionTypes";
import { Oval } from "@agney/react-loading";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";

const TableRequest = (props) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const role = useRole();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const cancelTokenSource = useRef()

  useEffect(() => {
    setLoading(true);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    cancelTokenSource.current = axios.CancelToken.source();
    new Promise(async (resolve, reject) => {
      const page = searchParams.get("page");
      const size = searchParams.get("size");
      const search = searchParams.get("search");
      dispatch(getReviews(page, size, search, cancelTokenSource))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [window.location.search]);

  useEffect(() => {
    if (props.reviews.results && props.reviews.results.length > 0) {
      let objColumns = [
        { name: "Profil", align: "left" },
        { name: "Date", align: "center" },
        { name: "Note", align: "center" },
        { name: "Commentaire", align: "center" },
        { name: "Note de frais", align: "center" },
        { name: "Ticket concerné", align: "center" },
        { name: "Action", align: "center" },
      ];

      if (role === IS_ADMIN_VALUE) {
        objColumns.splice(1, 0, { name: "Enseigne", align: "center" });
      }
      setColumns(objColumns);
      let obj;
      setRows(
        props.reviews.results.map((item) => {
          obj = {
            Profil: <UserCell image={item.user?.picture || ""} name={item.user.fullname} />,
            Date: (
              <DefaultCell>
                {item.created_at ? moment(item.created_at).locale("fr").format("LLL") : " "}
              </DefaultCell>
            ),
            Note: <Rating value={item.rating ? parseFloat(item.rating) : " "} readOnly />,
            Commentaire: <DefaultCell>{item.description ? item.description : " "}</DefaultCell>,
            "Note de frais": <DefaultCell>{item.is_expense_report ? "✔️" : "❌"}</DefaultCell>,
            "Ticket concerné": (
              <a
                href={item.ticket?.file || ""}
                target="_blank"
                style={{ color: "#67748e", fontWeight: "bold" }}
              >
                {item.ticket?.id || "-"}
              </a>
            ),
            Action: <ActionCell id={item.id ? item.id : " "} />,
          };

          if (role === IS_ADMIN_VALUE) {
            Object.assign(obj, {
              Enseigne: <UserCell image={item.brand?.logo || "-"} name={item.brand?.name || "-"} />,
            });
          }

          return obj;
        })
      );
    } else {
      return setRows([]);
    }
  }, [props.reviews.results]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun client trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
