import { useState } from "react";
import { useDispatch } from "react-redux";
import { useRole } from "routes/ProtectedRoutes";
import { useSearchParams } from "react-router-dom";
import SuiPagination from "components/SuiPagination";
import { Icon } from "@mui/material";
export default function PageListing (props) {
  const data = props.data;
  const [searchParams, setSearchParams] = useSearchParams();
  const nextPage = (page) => {
      searchParams.set('page', page);
      searchParams.set('size', searchParams.get('size') ? searchParams.get('size') : 10);
      searchParams.set('search', searchParams.get('search') ? searchParams.get('search') : '');
      setSearchParams(searchParams);
    };
  const styleActiveButton = { color: '#8392ab',borderColor: '#8392ab',borderWidth: '2px'};
  const styleInactiveButton = { opacity: .5 };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
      <SuiPagination size="small" color="secondary" variant="contained">
        <SuiPagination item disabled={!data?.previous} style={data?.previous ? styleActiveButton : styleInactiveButton} onClick={(e) => nextPage(searchParams.get('page') ? parseInt(searchParams.get('page')) -1  : '')}>
          <Icon>keyboard_arrow_left</Icon>
        </SuiPagination>
        {data?.previous &&
          <SuiPagination item onClick={(e) => nextPage(searchParams.get('page') ? parseInt(searchParams.get('page')) -1  : '')}>{searchParams.get('page') && searchParams.get('page') !== '1' ? parseInt(searchParams.get('page')) - 1 : ''}</SuiPagination>
        }
        <SuiPagination item active>{searchParams.get('page') ? searchParams.get('page') : '1'}</SuiPagination>
        {data?.next &&
          <SuiPagination item onClick={(e) => nextPage(searchParams.get('page') ? parseInt(searchParams.get('page')) + 1 : 2)}>{searchParams.get('page') ? parseInt(searchParams.get('page')) + 1 : 2}</SuiPagination>
        }
        <SuiPagination item disabled={!data?.next} style={data?.next ? styleActiveButton : styleInactiveButton} onClick={(e) => nextPage(searchParams.get('page') ? parseInt(searchParams.get('page')) + 1 : 2)}>
          <Icon>keyboard_arrow_right</Icon>
        </SuiPagination>
      </SuiPagination>
    </div>
  );
}



