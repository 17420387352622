/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleStatisticsCard from "examples/Cards/StatisticsCards/SimpleStatisticsCard";

import TableRequest from "./components/TableRequest";

// services
import { KpiService } from "@sharecode/common/services";

import FilterButton from "examples/FilterButton";

import PageListing from "components/PagesListing";

function ClientXP(props) {
  const [listKpi, setListKpi] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sizeSelected, setSizeSelected] = useState();

  useEffect(() => {
    KpiService.getKpiClientXp().then((r) => {
      setListKpi(r);
    });
  }, []);

  const handleChangeSize = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", e.value);
    searchParams.set("search", "");
    setSizeSelected({ value: e.value, label: e.label });
    setSearchParams(searchParams);
  };

  const searchCtn = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", searchParams.get("size") ? searchParams.get("size") : 10);
    searchParams.set("search", e.target.value);
    setSearchParams(searchParams);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={0}>
        <SuiBox mb={3}>
          <FilterButton />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Nb de notes", fontWeight: "medium" }}
                count={listKpi.nb_grade}
                icon={{ component: "grading" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "moyenne xp clients", fontWeight: "medium" }}
                count={listKpi.avg_xp}
                icon={{ component: "star_border" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "pire note", fontWeight: "medium" }}
                count={listKpi.worst_grade}
                icon={{ component: "star_border" }}
                direction="left"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "meilleure note", fontWeight: "medium" }}
                count={listKpi.best_grade}
                icon={{ component: "star_border" }}
                direction="left"
              />
            </Grid>
          </Grid>
        </SuiBox>
        <Card>
          <SuiBox pt={3} px={3}>
            <SuiTypography variant="h6" fontWeight="medium">
              Avis des clients
            </SuiTypography>
          </SuiBox>
          <SuiBox p={2} display="flex">
            <SuiBox display="flex">
              <SuiBox justifyContent="left">
                <SuiSelect
                  placeholder="Elements par page"
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" },
                    { value: "200", label: "200" },
                  ]}
                  onChange={(e) => handleChangeSize(e)}
                  value={{
                    value: searchParams.get("size") ? searchParams.get("size") : "10",
                    label: searchParams.get("size") ? searchParams.get("size") : "10",
                  }}
                />
              </SuiBox>
              <div
                style={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "16px",
                }}
              >
                Total: {props.reviews.count}
              </div>
            </SuiBox>
            <SuiBox style={{ flex: "50%" }}>
              <SuiBox justifyContent="right" style={{ float: "right" }}>
                <SuiInput
                  placeholder="Recherche..."
                  icon={{
                    component: "search",
                    direction: "right",
                  }}
                  onKeyUp={(e) => searchCtn(e)}
                />
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <TableRequest />
        </Card>
        <PageListing data={props.reviews} />
      </SuiBox>
    </DashboardLayout>
  );
}
function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ClientXP);
