const initialState = [{}];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "EDITED_TICKET":
      return payload;
    default:
      return state;
  }
}
