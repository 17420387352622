import { useState, useEffect, useCallback } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Oval } from "@agney/react-loading";
import { debounce } from "lodash";

// common
import { IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE } from "@sharecode/common/redux/actions/actionTypes";
// actions
import {getPosPartners} from "@sharecode/common/redux/actions/posActions";

// components
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
// examples
import Table from "examples/Tables/Table";
// routes
import { useRole } from "routes/ProtectedRoutes";


const TableRequest = (props) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const role = useRole();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const posPartners = useSelector((state) => state?.posReducer?.posPartners);

  // Fetch partners initially and whenever searchParams change
  useEffect(() => {
    const page = searchParams.get("page") || 1;
    const pagesize = searchParams.get("pagesize") || 100;
    setLoading(true);
    dispatch(getPosPartners(page, pagesize))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, searchParams]);

  // Update rows whenever posPartners (from the reducer) changes
  useEffect(() => {
    if (posPartners?.data && Array.isArray(posPartners?.data.results)) {
      setRows(
        posPartners.data.results.map((item) => {
          let obj = {
            "Nom logiciel": <DefaultCell>{item?.name || "-"}</DefaultCell>,
            Secteur: <DefaultCell>{item?.area || "-"}</DefaultCell>,
            "Nombre de PDV": <DefaultCell>{item?.num_pdv || "-"}</DefaultCell>,
            "Nombre de caisses": <DefaultCell>{item?.num_registers || "-"}</DefaultCell>,
            "Nombre de nouveaux users": <DefaultCell>{item?.new_users_count || "-"}</DefaultCell>,
          };

          if (role && [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1) {
            Object.assign(obj, { Action: <ActionCell id={item?.id || ""} /> });
          }

          return obj;
        })
      );
    } else {
      setRows([]);
    }
  }, [posPartners?.data, role]);

  useEffect(() => {
    let objColumns = [
      { name: "Nom logiciel", align: "center" },
      { name: "Secteur", align: "center" },
      { name: "Nombre de PDV", align: "center" },
      { name: "Nombre de caisses", align: "center" },
      { name: "Nombre de nouveaux users", align: "center" },
    ];

    if (role && [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1) {
      objColumns.push({ name: "Action", align: "center" });
    }

    setColumns(objColumns);
  }, [role]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun partenaire trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
