// @ts-nocheck
import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  ads: [],
  adDetails: null,
  error: null,
};

const adsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ADS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_ADS_SUCCESS:
      return { ...state, loading: false, ads: action.payload };
    case types.FETCH_ADS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.CREATE_AD_REQUEST:
      return { ...state, loading: true };
    case types.CREATE_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        ads: [...state.ads, action.payload],
      };
    case types.CREATE_AD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_AD_DETAILS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_AD_DETAILS_SUCCESS:
      return { ...state, loading: false, adDetails: action.payload };
    case types.FETCH_AD_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.UPDATE_AD_REQUEST:
      return { ...state, loading: true };
    case types.UPDATE_AD_SUCCESS:
    case types.UPDATE_AD_SUCCESS:
      const updatedAds = state.ads.map((ad) =>
        ad.id === action.payload.id
          ? { ...ad, ...action.payload.data }
          : ad
      );
      return {
        ...state,
        loading: false,
        ads: updatedAds,
        error: null,
      };
    case types.UPDATE_AD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.DELETE_AD_REQUEST:
      return { ...state, loading: true };
    case types.DELETE_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        ads: state.ads.filter((ad) => ad.id !== action.payload.id),
      };
    case types.DELETE_AD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default adsReducer;
