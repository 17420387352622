import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";

const FilterButton = ({
  onCampaignSelect,
  onStorySelect,
  campaignOptions,
  storyOptions,
  handleClearCampaignAndStoryFilter,
  selectedCampaignIds,
  selectedStoryIds,
  selectedStory,
  selectedCampaign,
  brandOptions,
  selectedBrandIds,
  selectedBrand,
  onBrandSelect,
}) => {
  return (
    <SuiBox>
      <Grid container spacing={2} my={2} justifyContent="flex-end">
        <Grid item>
          <SuiSelect
            // isMulti
            options={brandOptions}
            onChange={onBrandSelect}
            placeholder="Choisir une enseigne"
            value={selectedBrandIds?.length > 0 ? selectedBrand : null}
          />
        </Grid>
        <Grid item>
          <SuiSelect
            // isMulti
            options={campaignOptions}
            onChange={onCampaignSelect}
            placeholder="Choisir une campagne"
            value={selectedCampaignIds?.length > 0 ? selectedCampaign : null}
          />
        </Grid>
        <Grid item>
          <SuiSelect
            // isMulti
            options={storyOptions}
            onChange={onStorySelect}
            placeholder="Choisir une story"
            value={selectedStoryIds?.length > 0 ? selectedStory : null}
          />
        </Grid>

        <Grid item>
          <SuiButton onClick={handleClearCampaignAndStoryFilter} variant="gradient" color="error">
            Effacer les filtres
          </SuiButton>
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default FilterButton;
