import React, { useState } from "react";
import { Box, Typography, useTheme, IconButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

const SuccessOverlay = ({ message }) => {
  const theme = useTheme();
  const [showOverlay, setShowOverlay] = useState(true);

  const handleClose = () => {
    setShowOverlay(false);
  };

  return (
    <>
      {showOverlay && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: theme.zIndex.modal,
          }}
        >
          <Box
            sx={{
              width: "497px",
              height: "146px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "15px",
              border: "0.5px solid #FFF",
              background: "#B4FFB7",
              padding: theme.spacing(2),
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: theme.spacing(1),
                right: theme.spacing(1),
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <CheckCircleOutlineIcon sx={{ fontSize: 50, color: "#333" }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              {message}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SuccessOverlay;
