import { Oval } from "@agney/react-loading";
import { Dialog, DialogTitle } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

export function DeleteModal({ open, handleClose, handleDelete, objectId, loading }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle textAlign="center">Êtes-vous sûr de vouloir effacer ?</DialogTitle>
      <SuiBox p={2} display="flex" gap={2} justifyContent="center">
        <SuiButton color="secondary" onClick={handleClose} size="small">
          Annuler
        </SuiButton>
        {loading ? (
          <Oval width="30" color="secondary" />
        ) : (
          <SuiButton color="error" onClick={() => handleDelete(objectId)} size="small">
            Supprimer
          </SuiButton>
        )}
      </SuiBox>
    </Dialog>
  );
}
