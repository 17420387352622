/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
// redux
import { useDispatch } from "react-redux";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiDatePicker from "components/SuiDatePicker";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

import { AccountService } from "@sharecode/common/services/AccountService";

import { DefaultServiceHandler } from "utils/http-common";
import { getCountries } from "@sharecode/common/utils/useCountriesList";

// Loading spinner
import { Oval } from "@agney/react-loading";

import { listRole } from "@sharecode/common/redux/actions/actionTypes";

import moment from "moment";

function BasicInfo() {
  const [birthDate, setBirthDate] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [shops, setShops] = useState([]);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [country, setCountry] = useState(null);
  const [genderSelected, setGenderSelected] = useState(null);
  const [listCountries, setListCountries] = useState([]);
  const [userData, setUserData] = useState({
    birthdate: "",
    country: "",
    city: "",
    email: "",
    gender: "",
    phone_number: "",
    street: "",
    zipcode: "",
    brand: "",
    shops: [],
  });

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "Required";
    }

    if (!values.last_name) {
      errors.last_name = "Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
    },
    validate,
    onSubmit: () => {
      updateProfileInfo();
      setDisabled(true);
    },
  });

  useEffect(() => {
    getCountries().then((response) => {
      for (const [key, value] of Object.entries(response)) {
        response[key]["value"] = response[key]["value"].toUpperCase();
      }
      setListCountries(response);
    });
  }, []);

  useEffect(() => {
    AccountService.profile().then((r) => {
      setUserData(r);
      formik.values.first_name = r.first_name;
      formik.values.last_name = r.last_name;
      setGenderSelected(
        selectData.gender.filter((el) => {
          return el.value === r.gender;
        })
      );
      setCountry(
        listCountries.filter((el) => {
          return el.value === r.country;
        })
      );

      setShops(
        r.shops.map((item) => {
          return item.name;
        })
      );
    });
  }, [listCountries]);

  useEffect(() => {
    listRole.map((item) => {
      if (item.value === userData.role) {
        setRole(item.label);
      }
    });
  });

  const newData = {
    birthdate: birthDate,
    country: userData.country ? userData.country : null,
    city: userData.city ? userData.city : null,
    first_name: formik.values.first_name ? formik.values.first_name : null,
    gender: userData.gender ? userData.gender : null,
    last_name: formik.values.last_name ? formik.values.last_name : null,
    phone_number: userData.phone_number ? userData.phone_number : null,
    street: userData.street ? userData.street : null,
    zipcode: userData.zipcode ? userData.zipcode : null,
  };

  const updateProfileInfo = () => {
    setLoading(true);
    AccountService.updateProfile(newData)
      .then((r) => {
        setTimeout(() => {
          dispatch(DefaultServiceHandler("success", "success", "Profil mis à jour "));
        }, 500);
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch(DefaultServiceHandler("error", "error", error));
        }, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    let target = event.target.value;
    // For phone_number, we've got to make sure it only contains numbers and starts with a "+"
    if (prop == "phone_number" && target != "") {
      target = target.replace(/\D/g, "");
      target = "+" + target;
    }
    setUserData({ ...userData, [prop]: target });
    setDisabled(false);
  };

  const handleChangeGender = (event) => {
    setGenderSelected({ value: event.value, label: event.label });
    userData.gender = event.value;
    setDisabled(false);
  };

  const handleChangeCountry = (event) => {
    setCountry({ value: event.value, label: event.label });
    userData.country = event.value;
    setDisabled(false);
  };

  const handleSetBirthDate = (newDate) => {
    setBirthDate(moment(new Date(newDate).toUTCString()).format("YYYY-MM-DD"));
    setDisabled(false);
  };

  return (
    <Card id="personnal-info" sx={{ overflow: "visible" }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">Informations personnelles </SuiTypography>
      </SuiBox>
      <form onSubmit={formik.handleSubmit}>
        <SuiBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="prénom"
                id="first_name"
                name="first_name"
                type="text"
                placeholder=""
                error={formik.touched.first_name && formik.errors.first_name != null}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.first_name && formik.errors.first_name ? (
                    formik.errors.first_name
                  ) : (
                    <></>
                  )}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="nom"
                id="last_name"
                name="last_name"
                placeholder=""
                error={formik.touched.last_name && formik.errors.last_name != null}
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.last_name && formik.errors.last_name ? (
                    formik.errors.last_name
                  ) : (
                    <></>
                  )}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="email"
                placeholder=""
                value={userData.email}
                icon={{ component: "lock", direction: "right" }}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Date de naissance
                  </SuiTypography>
                </SuiBox>
                <SuiDatePicker value={userData.birthdate} onChange={(e) => handleSetBirthDate(e)} />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Sexe
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  options={selectData.gender}
                  onChange={(e) => handleChangeGender(e)}
                  value={genderSelected}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="téléphone"
                placeholder="+3366546464"
                value={userData.phone_number}
                onChange={handleChange("phone_number")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Pays
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  onChange={(e) => handleChangeCountry(e)}
                  options={listCountries}
                  value={country}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="ville"
                placeholder="Nice"
                value={userData.city}
                onChange={handleChange("city")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="rue"
                placeholder=""
                value={userData.street}
                onChange={handleChange("street")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Code postal"
                placeholder="06000"
                inputProps={{ type: "number" }}
                value={userData.zipcode}
                onChange={handleChange("zipcode")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="enseigne"
                placeholder=""
                value={userData.brand ? userData.brand.name : ""}
                icon={{ component: "lock", direction: "right" }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="points de vente"
                placeholder=""
                icon={{ component: "lock", direction: "right" }}
                value={shops}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="role"
                placeholder=""
                value={userData.brand ? role : ""}
                icon={{ component: "lock", direction: "right" }}
                disabled
              />
            </Grid>
          </Grid>

          <SuiBox display="flex" alignItems="flex-end" flexWrap="wrap" mt={3}>
            <SuiBox ml="auto">
              {loading ? (
                <SuiBox display="flex" alignItems="flex-start" mr={5}>
                  <Oval width="30" color="info" />
                </SuiBox>
              ) : (
                <SuiButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  disabled={(disabled && !formik.dirty) || !formik.isValid}
                >
                  Enregistrer
                </SuiButton>
              )}
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </form>
    </Card>
  );
}

export default BasicInfo;
