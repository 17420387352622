/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// services

import { getUserInfos } from "@sharecode/common/services/constantApi";
import { IS_ADMIN_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import DashboardAdmin from "./dashboardAdmin";
import DashboardBrand from "./dashboardBrand";

function Dashboard() {
  const role = getUserInfos();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {role.role === IS_ADMIN_VALUE ? <DashboardAdmin /> : <DashboardBrand />}
    </DashboardLayout>
  );
}

export default Dashboard;
