import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Paper, Typography, Grid, Divider, List, ListItem } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { styled } from "@mui/system";

import PropTypes from "prop-types";

// redux actions
import { createCampaign, editCampaign,sendEmailToAdmin } from "@sharecode/common/redux/actions/campaignsActions";
import { createAd, updateAd } from "@sharecode/common/redux/actions/adsActions";

// Styled components
const BudgetBox = styled("div")({
  backgroundColor: "#2794f9",
  borderRadius: "5px",
  height: "35px",
  width: "98px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  margin: "0 auto",
});

const NameBox = styled("div")(({ theme }) => ({
  backgroundColor: "#344767",
  borderRadius: theme.shape.borderRadius,
  height: "35px",
  width: "50%",
  maxWidth: "60%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  padding: theme.spacing(0, 2),
  margin: "0 auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  [theme.breakpoints.up("md")]: {
    maxWidth: "300px",
  },

  [theme.breakpoints.up("lg")]: {
    maxWidth: "400px",
  },

  [theme.breakpoints.up("xl")]: {
    maxWidth: "500px",
  },
}));

const CampaignSummary = ({
  campaignName,
  maxBudget,
  costPerClick,
  createdStories,
  onCampaignValidation,
  changeActiveStep,
  onToggleForm,
  mode,
  existingCampaignId,
  setIsLoading,
  countries,
  brandName,
  email,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Function to show the Snackbar with a custom message
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to handle closing the Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  // selector function to get the latest campaign
  const selectLatestCampaign = (state) => {
    const campaigns = state.campaignReducer.campaigns;
    return campaigns && campaigns.length > 0 ? campaigns[campaigns.length - 1] : null;
  };
  // Use the selector to get the latest campaign (used of campaign creation)
  const latestCampaign = useSelector(selectLatestCampaign);

  const maxBudgetInteger = parseInt(maxBudget);
  const clickBidIntegerCents = parseInt(costPerClick * 100);
  const maxBudgetIntegerCent = maxBudgetInteger * 100;
  const campaignClickLimit = Math.floor(maxBudgetIntegerCent / clickBidIntegerCents);

  // selected countries array
  const selectedCountriesValues = countries.map((country) => ({
    value: country.value,
  }));
  // array of selected countries
  const selectedCountries = selectedCountriesValues.map((country) => country.value);

  // Define handleValidateCampaign
const handleValidateCampaign = async () => {
  setIsLoading(true); // Indicate process start
  try {
    // Check if there are any created stories
    if (createdStories.length === 0) {
      showSnackbar("Veuillez créer au moins une story pour valider la campagne.");
      setIsLoading(false); // Reset loading state as we exit early
      return; // Exit early if no stories are created
    }

    // First, create the campaign and wait for the response
    const campaignResponse = await dispatch(
      createCampaign({
        campaignName: campaignName,
        clickBid: parseInt(costPerClick * 100),
        clickLimit: campaignClickLimit,
        countries: selectedCountries,
      })
    );

    if (!campaignResponse || !campaignResponse.data.AdsCampaignID) {
      showSnackbar("Échec de la création de la campagne. Veuillez réessayer.");
      setIsLoading(false); // Reset loading state as we exit early
      return;
    }

    const campaignId = campaignResponse.data.AdsCampaignID;

    // Process stories
    const createAdPromises = createdStories.map((story, index) =>
      createStoryAd(story, index, campaignId)
    );

    await Promise.all(createAdPromises);

    // Send email to admin
    await dispatch(sendEmailToAdmin(
      brandName,
      campaignName,
      createdStories.length,
      maxBudget,
      costPerClick,
      selectedCountries,
      email
    ));

    onCampaignValidation();
    setTimeout(() => {
      navigate("/manage-campaigns");
    }, 3000);
  } catch (error) {
    console.error("Error validating campaign:", error);
    showSnackbar("Erreur lors de la validation de la campagne. Veuillez réessayer.");
  } finally {
    setIsLoading(false);
  }
};

  async function createStoryAd(story, index, campaignId) {
    const clickBidIntegerCents = parseInt(costPerClick * 100);
    const storyBudgetIntegerCents = parseInt(story.budgetSplit * 100);
    const clickLimitInteger = Math.ceil(storyBudgetIntegerCents / clickBidIntegerCents);

    const visualChoiceFile = story.visualChoice;
    const fileExtension = visualChoiceFile.name.split(".").pop();
    const fileType = visualChoiceFile.type.startsWith("image") ? "image" : "video";

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = reader.result.split(",")[1]; // Remove the data URL prefix

        const storyData = {
          adsName: story.storyName,
          campaignId: campaignId,
          promoLink: story.redirectURL,
          clickLimit: clickLimitInteger,
          media: base64Data,
          mediaExt: fileExtension,
          mediaType: fileType,
          campaignOrderNb: createdStories.length === 1 ? 1 : index + 1,
        };

        try {
          await dispatch(createAd(storyData, campaignId));
          resolve();
        } catch (error) {
          console.error(`Error creating ad for story: ${story.storyName}`, error);
          reject(error); // Propagate the error
        }
      };
      reader.readAsDataURL(visualChoiceFile);
    });
  }

  // Define handleEditCampaign and handleEditAd
  const handleEditCampaign = async () => {
    setIsLoading(true); // Set loading state at the beginning of the process
    try {
      // Dispatch the editCampaign action
      await dispatch(
        editCampaign(existingCampaignId, {
          campaignName: campaignName,
        })
      );

      const updateAdPromises = createdStories.map((story, index) => {
        if (typeof story.storyId === "number") {
          // Existing story
          return updateExistingStory(story, index);
        } else {
          // New story
          return createNewStory(story, index);
        }
      });

      await Promise.all(updateAdPromises);

      onCampaignValidation();
      navigate("/manage-campaigns");
    } catch (error) {
      console.error("Error in handleEditCampaign function:", error);
      showSnackbar("Échec de la mise à jour de la campagne. Veuillez réessayer."); // Inform the user about the failure
    } finally {
      setIsLoading(false); // Reset loading state at the end of the process
    }
  };

  async function updateExistingStory(story, index) {
    const storyBudgetIntegerCents = parseInt(story.budgetSplit * 100);
    const clickLimitInteger = Math.floor(storyBudgetIntegerCents / clickBidIntegerCents);

    const storyData = {
      clickLimit: clickLimitInteger,
      campaignOrderNb: createdStories.length === 1 ? 1 : index + 1,
    };

    try {
      await dispatch(updateAd(story.storyId, storyData));
    } catch (error) {
      console.error(`Error updating ad for story ID: ${story.storyId}`, error);
      throw new Error(`Failed to update story ID: ${story.storyId}`); // Propagate error
    }
  }

  async function createNewStory(story, index) {
    return new Promise((resolve, reject) => {
      const clickBidIntegerCents = parseInt(costPerClick * 100);
      const storyBudgetIntegerCents = parseInt(story.budgetSplit * 100);
      const clickLimitInteger = Math.ceil(storyBudgetIntegerCents / clickBidIntegerCents);

      const visualChoiceFile = story.visualChoice;
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = reader.result.split(",")[1]; // Remove the data URL prefix
        const fileExtension = visualChoiceFile.name.split(".").pop();
        const fileType = visualChoiceFile.type.startsWith("image") ? "image" : "video";

        const storyData = {
          adsName: story.storyName,
          campaignId: existingCampaignId,
          promoLink: story.redirectURL,
          clickLimit: clickLimitInteger,
          media: base64Data,
          mediaExt: fileExtension,
          mediaType: fileType,
          campaignOrderNb: createdStories.length === 1 ? 1 : index + 1,
          budget: storyBudgetIntegerCents,
        };

        try {
          await dispatch(createAd(storyData, existingCampaignId));
          resolve();
        } catch (error) {
          console.error(`Error creating ad for story: ${story.storyName}`, error);
          reject(error); // Propagate error
        }
      };
      reader.readAsDataURL(visualChoiceFile);
    });
  }

  const onEditStories = () => {
    onToggleForm(false); // Hide the story creation form
    changeActiveStep(1); // Go back to the story creation step
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px", margin: "auto" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => changeActiveStep(0)}>
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{ color: "white", backgroundColor: "#2794F9", padding: "10px" }}
            />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ color: "#344767", textAlign: "left", fontFamily: "Inter", fontWeight: 700 }}
          >
            Étape 1: Création campagne
          </Typography>
        </Box>
        <Divider light />
        <List>
          <ListItem disableGutters sx={{ justifyContent: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#344767", fontFamily: "Inter", fontWeight: 300 }}
            >
              Nom campagne:{" "}
              <Typography component="span" sx={{ color: "#2794F9" }}>
                {campaignName}
              </Typography>
            </Typography>
          </ListItem>
          <ListItem disableGutters sx={{ justifyContent: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#344767", fontFamily: "Inter", fontWeight: 300 }}
            >
              Budget maximum:{" "}
              <Typography component="span" sx={{ color: "#2794F9" }}>
                {maxBudget}€
              </Typography>
            </Typography>
          </ListItem>
          <ListItem disableGutters sx={{ justifyContent: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#344767", fontFamily: "Inter", fontWeight: 300 }}
            >
              Coût par clic:{" "}
              <Typography component="span" sx={{ color: "#2794F9" }}>
                {costPerClick}€
              </Typography>
            </Typography>
          </ListItem>
        </List>
      </Paper>

      <Paper elevation={3} sx={{ padding: "20px", margin: "auto", marginTop: "20px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => onEditStories()}>
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{ color: "white", backgroundColor: "#2794F9", padding: "10px" }}
            />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ color: "#344767", textAlign: "left", fontFamily: "Inter", fontWeight: 700 }}
          >
            Étape 2: Création stories
          </Typography>
        </Box>

        <Divider light />
        <List>
          {createdStories.map((story, index) => (
            <ListItem
              key={index}
              disableGutters
              sx={{
                justifyContent: "center",
                display: "flex",
                gap: "16px", // Increase gap
                alignItems: "center",
                marginBottom: "10px", // Add space between list items
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "#344767", fontFamily: "Inter", fontWeight: 800 }}
              >
                {index + 1}.
              </Typography>
              <NameBox>{story.storyName}</NameBox>
              <BudgetBox>{story.budgetSplit}€</BudgetBox>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
        <Grid item>
          <Paper
            component="button"
            elevation={3}
            onClick={mode === "edit" ? handleEditCampaign : handleValidateCampaign}
            sx={{
              width: "fit-content",
              padding: "8px 16px",
              backgroundColor: "#3BE342",
              borderRadius: "8px",
              color: "white",
              fontFamily: "Inter",
              fontSize: "13px",
              fontWeight: 300,
              cursor: "pointer",
              border: "none",
              "&:hover": {
                backgroundColor: "#32cc3a",
              },
            }}
          >
            {mode === "edit" ? "Modifier ma campagne" : "Valider ma campagne"}
          </Paper>
        </Grid>
      </Grid>
      {/* Snackbar for displaying messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

CampaignSummary.propTypes = {
  campaignName: PropTypes.string.isRequired,
  maxBudget: PropTypes.number.isRequired,
  costPerClick: PropTypes.number.isRequired,
  createdStories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      budgetSplit: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default CampaignSummary;
