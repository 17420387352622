export default function checkEmail(e) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  return re.test(e);
}

// Password : At least 8 characters, must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number and can contain special characters
export function checkPassword(e) {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
  return re.test(e);
}
