import axios from "axios";
import {
  getAuthorizationToken,
  URL_GET_TICKETS_ADMIN,
  URL_POST_SCAN_IMAGE,
  URL_POST_SCAN_IMAGE_PRO,
  parameterRequest,
  URL_POST_USER,
  URL_CREATE_GENERIC_ERECIPT_CARREPOS,
  URL_CREATE_GENERIC_ERECIPT_CSI,
} from "./constantApi";
import AsyncStorage from "@react-native-async-storage/async-storage";

const createTicket = (image, isProMode) => {
  return new Promise((resolve, reject) => {
    AsyncStorage.getItem("token")
      .then((response) => {
        const headers = {
          Authorization: `Token ${response}`,
          "Content-Type": "multipart/form-data",
        };

        let formData = new FormData();
        formData.append("file", {
          uri: image.uri,
          name: "ticket.jpg",
          filename: "ticket.jpg",
          type: "image/jpeg",
        });

        let url;
        if (isProMode) {
          url = URL_POST_SCAN_IMAGE_PRO;
        } else {
          url = URL_POST_SCAN_IMAGE;
        }

        axios
          .post(url, formData, {
            headers: headers,
            transformRequest: (formData) => formData,
          })
          .then((r) => {
            resolve(r.data);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((error) => {
        console.log("Ticket error: ", error);
      });
  });
};

const updateTicket = (id, data) => {
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .patch(URL_POST_SCAN_IMAGE + id + "/", data, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const createTicketPro = (image) => {
  return new Promise((resolve, reject) => {
    AsyncStorage.getItem("token")
      .then((response) => {
        const headers = {
          Authorization: `Token ${response}`,
          "Content-Type": "multipart/form-data",
        };

        let formData = new FormData();
        formData.append("file", {
          uri: image.uri,
          name: "ticket.jpg",
          filename: "ticket.jpg",
          type: "image/jpeg",
        });

        axios
          .post(URL_POST_SCAN_IMAGE, formData, {
            headers: headers,
            transformRequest: (formData) => formData,
          })
          .then((r) => {
            resolve(r.data);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((error) => {
        console.log("Ticket error: ", error);
      });
  });
};

const getTicketsAdmin = (
  page,
  size,
  search,
  startDate,
  endDate,
  area,
  unassigned,
  paperTickets,
  cancelToken
) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;
  var startDate = startDate
    ? `&${parameterRequest.startDate}=${startDate}`
    : `&${parameterRequest.startDate}=`;
  var endDate = endDate
    ? `&${parameterRequest.endDate}=${endDate}`
    : `&${parameterRequest.endDate}=`;
  var area = area
    ? `&${parameterRequest.area}=${area}`
    : `&${parameterRequest.area}=`;
  var unassigned = unassigned ? `&${parameterRequest.unassigned}` : "";
  var paperTickets = paperTickets
    ? `&${parameterRequest.paperTickets}=${paperTickets}`
    : "";

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(
          URL_GET_TICKETS_ADMIN +
            index +
            element +
            searchVal +
            startDate +
            endDate +
            area +
            unassigned +
            paperTickets,
          {
            headers: results.headers,
            cancelToken: cancelToken?.current?.token,
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const getSingleUserTickets = (
  page,
  size,
  search,
  startDate,
  endDate,
  area,
  userId,
  unassigned,
  paperTickets
) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;
  var startDate = startDate
    ? `&${parameterRequest.startDate}=${startDate}`
    : `&${parameterRequest.startDate}=`;
  var endDate = endDate
    ? `&${parameterRequest.endDate}=${endDate}`
    : `&${parameterRequest.endDate}=`;
  var area = area
    ? `&${parameterRequest.area}=${area}`
    : `&${parameterRequest.area}=`;
  var userId = userId || "";
  var unassigned = unassigned ? `&${parameterRequest.unassigned}` : "";
  var paperTickets = paperTickets
    ? `&${parameterRequest.paperTickets}=${paperTickets}`
    : "";

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(
          URL_POST_USER +
            `${userId}/tickets/` +
            index +
            element +
            searchVal +
            startDate +
            endDate +
            area,
          {
            headers: results.headers,
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const createGenericEreceiptCarrepos = () => {
  return getAuthorizationToken()
    .then((token) => {
      return axios.get(URL_CREATE_GENERIC_ERECIPT_CARREPOS, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
    })
    .catch((error) => {
      console.log("Error getting authorization token:", error);
      throw error;
    });
};

const createGenericEreceiptCsi = () => {
  return getAuthorizationToken()
    .then((token) => {
      return axios.get(URL_CREATE_GENERIC_ERECIPT_CSI, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
    })
    .catch((error) => {
      console.log("Error getting authorization token:", error);
      throw error;
    });
};

const deleteGenericEreceiptCarrepos = () => {
  return getAuthorizationToken()
    .then((token) => {
      return axios.delete(URL_CREATE_GENERIC_ERECIPT_CARREPOS, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
    })
    .catch((error) => {
      console.log("Error getting authorization token:", error);
      throw error;
    });
};

const deleteGenericEreceiptCsi = () => {
  return getAuthorizationToken()
    .then((token) => {
      return axios.delete(URL_CREATE_GENERIC_ERECIPT_CSI, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
    });
};

export const TicketService = {
  createTicket,
  getSingleUserTickets,
  updateTicket,
  getTicketsAdmin,
  createGenericEreceiptCarrepos,
  createGenericEreceiptCsi,
  deleteGenericEreceiptCarrepos,
  deleteGenericEreceiptCsi,
};
