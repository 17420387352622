//react and libs
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { setSnackbar } from "@sharecode/common/redux/actions/snack";
import { getBrandCategories } from "@sharecode/common/redux/actions/brand";

//SUI
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TicketUpdate from "examples/Drawers/ticketUpdate";
import CustomizedSnackbars from "examples/Snackbar";

//Material UI
import Card from "@mui/material/Card";

// Components
import TableRequest from "./components/TableRequest";
import PageListing from "components/PagesListing";
import { ExportButton } from "components/ExportButton";
import SuiButton from "components/SuiButton";

//Filter Components
import { SizeSelectorFilter } from "components/Filters/SizeSelectorFilter";
import { SearchInputFilter } from "components/Filters/SearchInputFilter";
import { SectorSelectFilter } from "components/Filters/SectorSelectFilter";
import { TicketsTypeFilter } from "components/Filters/TicketsTypeFilter";
import { TicketsNoBrandFilter } from "components/Filters/TicketsNoBrandFilter";
import { DatePeriodFilter } from "components/Filters/DatePeriodFilter";
// common
import { exportTable } from "@sharecode/common/utils/helpers";
// services
import { URL_EXPORT_TICKETS } from "@sharecode/common/services/constantApi";
// redux
import { createGenericEreceiptCarrepos, createGenericEreceiptCsi, deleteGenericEreceiptCarrepos, deleteGenericEreceiptCsi } from "@sharecode/common/redux/actions/tickets";

function ManageTickets({ tickets }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sizeSelected, setSizeSelected] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [brandSelected, setBrandSelected] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);
  const [ticketType, setTicketType] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [loadingBrandOptions, setLoadingBrandOptions] = useState(false);

  const filterParams = (obj, itemToRemove) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== "" && obj[key] !== null && key !== itemToRemove) {
        result[key] = obj[key];
      }
    });
    return result;
  };

  const generalSearchParamsObject = {
    page: 1,
    size: searchParams.get("size") || 10,
    search: searchParams.get("search") || "",
    unassigned: searchParams.get("unassigned") || "",
    area: searchParams.get("area") || "",
    start_date: searchParams.get("start_date") || "",
    paper_tickets: searchParams.get("paper_tickets") || "",
    end_date: searchParams.get("end_date") || "",
  };

  const filteredSearchParams = filterParams(generalSearchParamsObject);

  useEffect(() => {
    setLoadingBrandOptions(true);

    dispatch(getBrandCategories(null, null, null, true))
      .then((response) => {
        let newArray = response.map((item) => {
          return {
            value: item.code,
            label: item.value,
          };
        });
        setBrandOptions(newArray);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingBrandOptions(false);
      });
  }, []);

  useEffect(() => {
    const brand = searchParams.get("area") || "";
    const size = searchParams.get("size") || "10";
    const initialDate = searchParams.get("start_date") || "";
    const finalDate = searchParams.get("end_date") || "";
    const paperTicket = searchParams.get("paper_tickets") || "";
    const search = searchParams.get("search") || "";

    if (paperTicket === "true") {
      setTicketType({ value: true, label: "Tickets photo" });
    } else if (paperTicket === "false") {
      setTicketType({ value: false, label: "E-tickets" });
    }
    setSearchInputValue(search);
    setStartDate(initialDate);
    setEndDate(finalDate);
    setSizeSelected({ value: size, label: size });

    if (brand.length > 1) {
      const matchingOption = brandOptions.find((option) => option.value === brand);
      if (matchingOption) {
        setBrandSelected({ value: brand, label: matchingOption.label });
      } else {
        setBrandSelected("");
      }
    } else {
      setBrandSelected("");
    }
  }, [searchParams, brandOptions]);

  const handleSizeChange = (e) => {
    setSearchParams({
      ...filteredSearchParams,
      page: 1,
      size: e.value,
    });
    setSizeSelected({ value: e.value, label: e.label });
  };

  const handleChangeBrand = (e) => {
    if (!e) {
      searchParams.delete("area");
      setSearchParams(searchParams);
      setBrandSelected("");
      return;
    }

    setSearchParams({
      ...filteredSearchParams,
      page: 1,
      area: e.value,
      search: searchParams.get("search") || "",
    });
    setBrandSelected({ value: e.value, label: e.label });
  };

  const handleChangeTicketType = (e) => {
    if (!e || e === null) {
      searchParams.delete("paper_tickets");
      setSearchParams(searchParams);
      setTicketType("");
      return;
    }

    if (e.value === null) {
      searchParams.delete("paper_tickets");
      setSearchParams(searchParams);
      setTicketType({ value: null, label: "Tous" });
      return;
    }

    setSearchParams({
      ...filteredSearchParams,
      page: 1,
      paper_tickets: e.value,
    });
    setTicketType({ value: e.value, label: e.label });
  };

  const searchCtn = (e) => {
    setSearchInputValue(e.target.value);

    setSearchParams({
      ...filteredSearchParams,
      search: e.target.value,
    });
  };

  const handleTicketsSignsFilter = (e) => {
    if (e.target.checked) {
      setSearchParams({
        ...searchParams,
        page: 1,
        unassigned: true,
        search: "",
        start_date: searchParams.get("start_date") || "",
        end_date: searchParams.get("end_date") || "",
        area: searchParams.get("area") || "",
      });
    } else {
      searchParams.delete("unassigned");
      setSearchParams(searchParams);
    }
  };

  const handlePeriodFilter = () => {
    if (startDate && endDate) {
      let formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      let formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      if (moment(formattedStartDate).isBefore(formattedEndDate)) {
        searchParams.set("start_date", formattedStartDate);
        searchParams.set("end_date", formattedEndDate);
        return setSearchParams(searchParams);
      }
    }

    dispatch(
      setSnackbar(
        true,
        "error",
        "error",
        "La date de début doit être inférieure ou égale à la date de fin."
      )
    );
    searchParams.delete("start_date");
    searchParams.delete("end_date");
    setSearchParams(searchParams);

    setStartDate("");
    setEndDate("");
  };

  const handleClearPeriodFilter = () => {
    setSearchParams({ ...filteredSearchParams, start_date: "", end_date: "" });
  };

  const handleExportTickets = () => {
    setLoadingExport(true);

    const keys = ["area", "start_date", "end_date", "paper_tickets", "search", "unassigned"];

    const paramsValues = {};

    keys.forEach((key) => {
      const value = searchParams.get(key);
      if (value) {
        paramsValues[key] = value;
      }
    });

    exportTable({ url: URL_EXPORT_TICKETS, fileName: "tickets.xlsx" }, paramsValues)
      .then((response) => {})
      .catch((error) => {
        dispatch(setSnackbar(true, "error", "error", "No entry to export"));
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  const handleCreateGenericEreceiptCarrepos = () => {
    dispatch(createGenericEreceiptCarrepos());
  };

  const handleCreateGenericEreceiptCsi = () => {
    dispatch(createGenericEreceiptCsi());
  };

  const handleDeleteGenericEreceiptCarrepos = () => {
    dispatch(deleteGenericEreceiptCarrepos());
  };

  const handleDeleteGenericEreceiptCsi = () => {
    dispatch(deleteGenericEreceiptCsi());
  };

  return (
    <>
      <DashboardLayout>
        <CustomizedSnackbars />
        <DashboardNavbar />
        <SuiBox py={2}>
          <Card>
            <SuiBox mb={4}>
              <SuiBox p={2} display="flex" alignItems="baseline" justifyContent="space-between">
                <SuiTypography variant="h6">Gestion des tickets</SuiTypography>
                <SuiBox display="flex" gap={2}>
                  {/* <ExportButton
                    disabled={tickets?.count === 0}
                    handleExport={handleExportTickets}
                    loading={loadingExport}
                  /> */}
                  <SuiButton
                    variant="contained"
                    color="primary"
                    onClick={handleCreateGenericEreceiptCarrepos}
                  >
                    Créer Ticket Carrepos
                  </SuiButton>
                  <SuiButton
                    variant="contained"
                    color="primary"
                    onClick={handleCreateGenericEreceiptCsi}
                  >
                    Créer Ticket CSI
                  </SuiButton>
                  <SuiButton
                    variant="contained"
                    color="error"
                    onClick={handleDeleteGenericEreceiptCarrepos}
                  >
                    Supprimer Ticket Carrepos
                  </SuiButton>
                  <SuiButton
                    variant="contained"
                    color="error"
                    onClick={handleDeleteGenericEreceiptCsi}
                  >
                    Supprimer Ticket CSI
                  </SuiButton>

                </SuiBox>
              </SuiBox>

              <SuiBox
                display="flex"
                gap={2}
                justifyContent="space-between"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                p={2}
              >
                <SizeSelectorFilter
                  sizeValue={sizeSelected}
                  handleSizeChange={handleSizeChange}
                  totalCount={tickets?.count}
                />

                <SearchInputFilter
                  placeholder="Recherche par enseigne..."
                  handleSearchChange={searchCtn}
                  searchInputValue={searchInputValue}
                />
              </SuiBox>

              <SuiBox
                display="flex"
                gap={2}
                p={2}
                sx={{ flexDirection: { xs: "column", md: "row" }, flexShrink: 0 }}
              >
                <SectorSelectFilter
                  brandOptions={brandOptions}
                  loading={loadingBrandOptions}
                  handleChangeBrand={handleChangeBrand}
                  brandValue={brandSelected}
                />
                <TicketsTypeFilter
                  ticketValue={ticketType}
                  handleChangeTicketType={handleChangeTicketType}
                />
              </SuiBox>

              <SuiBox p={2}>
                <TicketsNoBrandFilter
                  handleTicketsSignsFilter={handleTicketsSignsFilter}
                  searchParams={searchParams}
                />
              </SuiBox>

              <SuiBox px={2} display="flex">
                <DatePeriodFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  handlePeriodFilter={handlePeriodFilter}
                  handleClearPeriodFilter={handleClearPeriodFilter}
                />
              </SuiBox>
            </SuiBox>

            <TableRequest />
          </Card>
          <PageListing data={tickets} />
        </SuiBox>
      </DashboardLayout>
      <TicketUpdate />
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ManageTickets);
