import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Edit from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// internal imports
import SuiBox from "components/SuiBox";
import PhonePreview from "../PhonePreview";

const BudgetBox = styled("div")({
  backgroundColor: "#2794f9",
  borderRadius: "5px",
  height: "35px",
  width: "98px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  margin: "0 auto",
});

const NameBox = styled("div")(({ theme }) => ({
  backgroundColor: "#344767",
  borderRadius: theme.shape.borderRadius,
  height: "35px",
  width: "50%",
  maxWidth: "60%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  padding: theme.spacing(0, 2),
  margin: "0 auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  [theme.breakpoints.up("md")]: {
    maxWidth: "300px",
  },

  [theme.breakpoints.up("lg")]: {
    maxWidth: "400px",
  },

  [theme.breakpoints.up("xl")]: {
    maxWidth: "500px",
  },
}));

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "isPlaceholder",
})(({ theme, isPlaceholder }) => ({
  color: isPlaceholder ? theme.palette.action.disabled : "inherit",
  "&.Mui-disabled": {
    color: theme.palette.action.disabled,
  },
}));
const StyledNumber = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isPlaceholder",
})(({ theme, isPlaceholder }) => ({
  color: isPlaceholder ? theme.palette.action.disabled : "inherit",
}));

const StoryList = ({ stories, remainingBudget, setCreatedStories, mode }) => {
  // Initialize an array of 5 placeholders for the story slots
  const totalStories = Array.from({ length: 5 }, (_, index) => ({
    storyName: "",
    budgetSplit: 0,
    isPlaceholder: true, // Mark as placeholder
    // Initialize with a null storyId to ensure the property exists
    storyId: null,
  }));

  // Insert created stories into their respective slots
  stories.forEach((story, index) => {
    totalStories[index] = { ...story, isPlaceholder: false };
  });

  // Sort stories by orderNumber in edit mode if orderNumber exists
  const sortedStories =
    mode === "edit"
      ? totalStories.sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0))
      : totalStories;

  // Calculer l'indice du premier placeholder
  const firstPlaceholderIndex = stories.length;

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;

    const newStories = [...stories];
    const [reorderedStory] = newStories.splice(source.index, 1);
    newStories.splice(destination.index, 0, reorderedStory);

    setCreatedStories(newStories);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Budget de votre campagne à répartir:{" "}
        <span style={{ color: "#2794F9" }}>{remainingBudget}€</span>
      </Typography>
      <SuiBox style={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid
            item
            xs={12}
            sm={8}
            style={{ display: "flex", flexDirection: "column", gap: "35px" }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="storiesDroppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {totalStories.map((story, index) => {
                      // Logic to determine button activation
                      const isButtonActive =
                        index === firstPlaceholderIndex || !story.isPlaceholder;
                      const isDragDisabled = story.isPlaceholder;

                      return (
                        <Draggable
                          key={index}
                          draggableId={`draggable-${index}`}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        >
                          {(provided, snapshot) => (
                            <Grid
                              item
                              xs={12}
                              container
                              alignItems="center"
                              spacing={1}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                boxShadow: snapshot.isDragging ? "0px 0px 10px #888888" : "none",
                                margin: "0 0 8px 0",
                              }}
                            >
                              <Grid item>
                                <StyledNumber
                                  variant="h6"
                                  component="span"
                                  isPlaceholder={story.isPlaceholder}
                                >
                                  {index + 1}
                                </StyledNumber>
                              </Grid>
                              <Grid item {...provided.dragHandleProps}>
                                <StyledIconButton size="small" disabled={!isButtonActive}>
                                  <MenuIcon />
                                </StyledIconButton>
                              </Grid>
                              <Grid item>
                                <StyledIconButton
                                  size="small"
                                  isPlaceholder={!isButtonActive}
                                  disabled={
                                    (!isButtonActive && index >= stories.length) ||
                                    (Math.round(remainingBudget * 100) / 100 === 0 &&
                                      index >= stories.length)
                                  }
                                >
                                  {index < stories.length ? <Edit /> : <AddBoxIcon />}
                                </StyledIconButton>
                              </Grid>
                              <Grid item xs>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  {!story.isPlaceholder && <NameBox>{story.storyName}</NameBox>}
                                  {!story.isPlaceholder && (
                                    <BudgetBox>{`${story.budgetSplit}€`}</BudgetBox>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Grid item xs={12}>
              <Typography variant="caption" textAlign="center">
                Nombre de story disponibles: {5 - stories.length}
              </Typography>
              <span style={{ display: "block", height: "10px" }} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <PhonePreview stories={stories} mode={mode} />
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

StoryList.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      budget: PropTypes.number,
      isPlaceholder: PropTypes.bool,
    })
  ).isRequired,
  remainingStories: PropTypes.number.isRequired,
};

export default StoryList;
