import * as types from "./actionTypes";
import { CheckService } from "../../services";

export const getClients =
  (page = 1, size = 10, search = "") =>
  (dispatch) => {
    return CheckService.getListClients(page, size, search).then(
      (response) => {
        dispatch({
          type: types.GET_LIST_CLIENTS,
          payload: response,
        });
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

export const getSingleClient = (userId) => (dispatch) => {
  return CheckService.getSingleClient(userId).then(
    (response) => {
      dispatch({
        type: types.GET_CLIENT,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getUsersByBrand = (page = 1, pageSize = 10) => (dispatch) => {
  return CheckService.getUsersByBrand(page, pageSize).then(
    (response) => {
      dispatch({
        type: types.GET_USERS_BY_BRAND,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
