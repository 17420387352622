/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// OfferCreate page components
import OfferInfo from "./components/OfferInfo";
import OfferStoryCreation from "./components/OfferStoryCreation";
import CampaignSummary from "./components/CampaignSummary";
import LoadingOverlay from "./components/LoadingOverlay";
import SuccessOverlay from "./components/SuccessOverlay";

// OfferCreate layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import generateEditInitialValues from "./schemas/editInitialValues";
// redux actions
import { getUser } from "@sharecode/common/redux/actions/user";

function OfferCreate() {
  const dispatch = useDispatch();
  const cancelTokenSource = useRef();

  const campaignIdToEdit = useSelector((state) => state.campaignReducer.selectedCampaignId);
  const existingCampaign = useSelector((state) => state.campaignReducer.campaigns);
  const existingCampaignData = useSelector((state) => state.campaignReducer.campaignDetails);
  const existingCampaignAds = useSelector((state) => state.campaignReducer.campaignAds);
  const user = useSelector((state) => state?.user);

  // extract campaign ads from existingCampaignAds

  const [activeStep, setActiveStep] = useState(0);
  const [createdStories, setCreatedStories] = useState([]); // State to store created stories
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [mode, setMode] = useState(campaignIdToEdit ? "edit" : "create");
  const [isStoryFormVisible, setIsStoryFormVisible] = useState(mode === "edit" ? false : true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [remainingBudget, setRemainingBudget] = useState(0);
  const [deletedStoriesConsumedBudget, setDeletedStoriesConsumedBudget] = useState(0);

  // dispatch getUser action to get user data for the logo
  useEffect(() => {
    dispatch(
      getUser(
        searchParams.get("page"),
        searchParams.get("size"),
        searchParams.get("search"),
        cancelTokenSource
      )
    ).catch((error) => {
      console.log(error);
    });
  }, [dispatch]);

  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const editInitialValues = generateEditInitialValues(existingCampaignData, existingCampaignAds);
  const existingCampaignId = existingCampaignData?.data[0].id;
  const costPerClick = existingCampaignData?.data[0].clickBid;
  const campaignAdsData = mode === "edit" ? existingCampaignAds?.data : undefined;
  const isCampaignActive =
    existingCampaign?.data?.some((campaign) => {
      // Campaign is considered active if:
      // - It has not ended (`has_ended` is `false`)
      // - It is explicitly marked as active (`is_active` is `true`).
      // - it is waiting validation campaign.is_active === null && !campaign.has_ended
      return (
        campaign.has_ended === false && (campaign.is_active === true || campaign.is_active === null)
      );
    }) || false;
  const brandName = user?.results?.[0]?.brand?.name;
  const userEmail = user?.results?.[0]?.email;



  // calculate the consumed budget of deleted stories from campaign ads and set it to deletedStoriesConsumedBudget  based on 'deleted_at'
  useEffect(() => {
    if (existingCampaignAds && mode === "edit") {
      const deletedStories = campaignAdsData?.filter((ad) => ad.deleted_at);
      const deletedConsumedBudget = deletedStories?.reduce(
        (acc, story) => acc + (story.click_limit * costPerClick) / 100,
        0
      );
      setDeletedStoriesConsumedBudget(deletedConsumedBudget);
    }
  }, [existingCampaignAds, mode]);

  function getSteps() {
    return [
      "Etape 1. Création campagne",
      "Etape 2. Création stories",
      "Etape 3. Validation campagne",
    ];
  }

  function handleCampaignValidated() {
    setIsLoading(false);
    setIsSuccess(true);
  }
  const changeActiveStep = (step) => {
    setActiveStep(step);
  };
  const handleUpdateRemainingBudget = (newRemainingBudget) => {
    setRemainingBudget(newRemainingBudget);
  };

  function getStepContent(stepIndex, formData, formikProps) {
    switch (stepIndex) {
      case 0:
        return (
          <OfferInfo
            formData={formData}
            formikProps={formikProps}
            mode={mode}
            isCampaignActive={isCampaignActive}
          />
        );
      case 1:
        return (
          <OfferStoryCreation
            formData={formData}
            createdStories={createdStories}
            setCreatedStories={setCreatedStories}
            changeActiveStep={changeActiveStep}
            formikProps={formikProps}
            onToggleForm={(isVisible) => setIsStoryFormVisible(isVisible)}
            isStoryFormVisible={isStoryFormVisible}
            mode={mode}
            campaignAds={existingCampaignAds}
            onUpdateRemainingBudget={handleUpdateRemainingBudget}
            campaignIdToEdit={campaignIdToEdit}
            deletedStoriesConsumedBudget={deletedStoriesConsumedBudget}
          />
        );
      case 2:
        return (
          <CampaignSummary
            campaignName={formData.values.name}
            maxBudget={formData.values.budget}
            costPerClick={formData.values.costPerClick}
            createdStories={createdStories}
            onCampaignValidation={handleCampaignValidated}
            changeActiveStep={changeActiveStep}
            onToggleForm={(isVisible) => setIsStoryFormVisible(isVisible)}
            mode={mode}
            existingCampaignId={existingCampaignId}
            setIsLoading={setIsLoading}
            setCreatedStories={setCreatedStories}
            deletedStoriesConsumedBudget={deletedStoriesConsumedBudget}
            countries={formData.values.countries}
            formData={formData}
            brandName={brandName}
            email={userEmail}
          />
        );
      default:
        return null;
    }
  }

  const handleBack = () => setActiveStep(activeStep - 1);

  // Function to open Snackbar with a message
  const handleOpenSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (values, actions) => {
    if (activeStep === 1) {
      if (remainingBudget > 0) {
        handleOpenSnackbar(
          `Il reste ${remainingBudget}€ de budget à allouer. Veuillez l'utiliser avant de continuer.`
        );
        return; // Stop the function if there's remaining budget
      }
    }

    if (isLastStep) {
      if (createdStories.length === 0) {
        // Check if no stories are created
        // Handle the case when no stories are created
        return;
      }

      if (values.budget > 0) {
        // Check if budget is greater than 0
        return;
      }

      setIsLoading(true);
      try {
        // Perform API call or other operations
      } catch (error) {
        console.error("API call failed:", error);
        setIsLoading(false);
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={10}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={mode === "edit" ? editInitialValues : initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleSubmit,
                isValid,
                validateForm,
                setErrors,
                setTouched,
                setValues,
                resetForm,
                setFieldError,
                mode,
                setFieldValue,
              }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SuiBox p={2}>
                      <SuiBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            mode,
                          },
                          {
                            handleSubmit,
                            isValid,
                            validateForm,
                            setErrors,
                            setTouched,
                            setValues,
                            resetForm,
                            setFieldError,
                            mode,
                            setFieldValue,
                          }
                        )}
                        <SuiBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {/* Render the "Previous" button based on the specified conditions */}
                          {activeStep === 0 || isLastStep ? (
                            <SuiBox />
                          ) : activeStep !== 1 || (activeStep === 1 && !isStoryFormVisible) ? (
                            <SuiButton variant="gradient" color="light" onClick={handleBack}>
                              Précédent
                            </SuiButton>
                          ) : null}
                          {/* Render the "Next" button based on the specified conditions */}
                          {isLastStep || (activeStep === 1 && isStoryFormVisible) ? null : (
                            <SuiButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Suivant
                            </SuiButton>
                          )}
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Form>
              )}
            </Formik>
            {isLoading && (
              <LoadingOverlay message="Veuillez patienter... ajout de la campagne en cours" />
            )}
            {isLoading && mode === "edit" && (
              <LoadingOverlay
                message={
                  mode === "edit"
                    ? "Veuillez patienter... mise à jour de la campagne en cours"
                    : "Veuillez patienter... ajout de la campagne en cours"
                }
              />
            )}
            {isSuccess && (
              <SuccessOverlay message="Merci! Nous avons bien reçu votre campagne, elle est en cours d’examen et sera traitée dans un délai de 24h." />
            )}
          </Grid>
        </Grid>
      </SuiBox>
      {/* Snackbar for error messages */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default OfferCreate;
