/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    campaignName,
    maxBudget,
    costPerClick,
    storyName,
    visualChoice,
    redirectURL,
    budgetSplit,
    countries,
  },
} = checkout;

const urlRegex = /http[s]?:\/\/[a-zA-Z0-9.-]+.*/;

const validations = [
  Yup.object().shape({
    [campaignName.name]: Yup.string()
      .required(campaignName.errorMsg)
      .max(50, "Le nom de la campagne ne doit pas dépasser 50 caractères."),
    [maxBudget.name]: Yup.number()
      .typeError("Le budget maximum doit être un nombre.")
      .min(0, "Le budget ne peut pas être négatif.")
      .required(maxBudget.errorMsg),
    [costPerClick.name]: Yup.number()
      .min(0.1, `Un coût par clic de 0.1€ est le minimum.`)
      .max(10, `Un coût par clic de 10€ est le maximum.`)
      .required(costPerClick.errorMsg),
    [countries.name]: Yup.array()
      .of(Yup.object())
      .min(1, "Veuillez sélectionner au moins un pays."),
  }),
  Yup.object().shape({
    [storyName.name]: Yup.string()
      .required(storyName.errorMsg)
      .max(20, "Le nom de la story ne doit pas dépasser 20 caractères."),
    [visualChoice.name]: Yup.mixed()
      .required(visualChoice.errorMsg)
      .test("isFileTypeOrString", "Invalid input type", function (value) {
        if (typeof value === "object" && value !== null && value.type) {
          return true; // Continue to file-specific validations
        } else if (typeof value === "string") {
          // Always return true for string input
          return true;
        } else {
          // If value doesn't meet file or string criteria
          return this.createError({
            message: "Input must be a file or a string.",
          });
        }
      })
      .test(
        "fileSize",
        "Le fichier est trop volumineux. La taille maximale autorisée est de 20MB",
        function (file) {
          if (file && typeof file.size === "number") {
            // Ensure file.size is a number
            const sizeInMB = file.size / 1024 / 1024;
            if (sizeInMB > 20) {
              return this.createError({
                message: "Le fichier est trop volumineux. La taille maximale autorisée est de 20MB",
              });
            }
          }
          return true; // Return true if there's no file or if size validation passes
        }
      ),

    [redirectURL.name]: Yup.string()
      .required(redirectURL.errorMsg)
      .matches(urlRegex, "L'URL de redirection n'est pas un format d'URL valide."),
    [budgetSplit.name]: Yup.number()
      .required(budgetSplit.errorMsg)
      .notOneOf([0], "Le budget ne peut pas être égal à 0."),
  }),
];

export default validations;
