import IconButton from "@mui/material/IconButton";
import * as types from "@sharecode/common/redux/actions/actionTypes";
import { Link, useSearchParams } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { getUser } from "@sharecode/common/redux/actions/user";
import { useDispatch, connect } from "react-redux";
// icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import { useSoftUIController, setOpenUserDrawer } from "context";
import { DefaultServiceHandler } from "utils/http-common";
import { UserService } from "@sharecode/common/services";
import { useState } from "react";
import { DeleteModal } from "components/DeleteModal";

function ActionCell(props) {
  const disp = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { openUserDrawer } = controller;
  const [searchParams, setSearchParams] = useSearchParams();
  const handleUserDrawerOpen = () => setOpenUserDrawer(dispatch, !openUserDrawer);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const editUser = (id) => {
    const editedUser = props.user.results.filter((el) => {
      return el.id === parseInt(id);
    });
    disp({
      type: types.EDITED_USER,
      payload: editedUser[0],
    });
    handleUserDrawerOpen();
  };

  const deleteUser = (id) => {
    setLoadingDelete(true);
    const selectedUser = props.user.results.filter((el) => {
      return el.id === parseInt(id);
    });
    UserService.deleteUser(selectedUser[0])
      .then((response) => {
        disp(
          getUser(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        ).then((response) => {
          disp(DefaultServiceHandler("success", "success", "Utilisateur effacé avec succès!"));
        });
      })
      .catch((error) => {
        disp(
          DefaultServiceHandler("error", "error", "Erreur lors de la suppression de l'utilisateur.")
        );
      })
      .finally(() => {
        setLoadingDelete(false);
        setDialogOpen(false);
      });
  };

  return (
    <>
      <DeleteModal
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleDelete={deleteUser}
        objectId={props.id}
        loading={loadingDelete}
      />
      <SuiBox display="flex" alignItems="center">
        <SuiBox>
          <IconButton color="info" onClick={() => editUser(props.id)}>
            <EditIcon />
          </IconButton>
        </SuiBox>
        <SuiBox>
          <IconButton color="error" onClick={() => setDialogOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </SuiBox>
        <SuiBox>
          <Link to={`/user/${props.id}/tickets/`}>
            <IconButton color="success">
              <ReceiptLongIcon />
            </IconButton>
          </Link>
        </SuiBox>
      </SuiBox>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
