import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import FormField from "../FormField";

// store
import { fetchAverageClickBidByCountry } from "@sharecode/common/redux/actions/campaignsActions";

function OfferInfo({ formData, mode }) {
  const dispatch = useDispatch();

  const averageClickBidsByCountry = useSelector(
    (state) => state.campaignReducer.averageClickBidsByCountry
  );

  const { formField, values, errors, touched } = formData;

  const { campaignName, maxBudget, costPerClick, countries } = formField;
  const {
    name: campaignNameV,
    budget: maxBudgetV,
    costPerClick: costPerClickV,
    countries: countriesV,
  } = values ?? {};

  const [selectedCountries, setSelectedCountries] = useState([]);

  // fetching all countries
  useEffect(() => {
    if (mode !== "edit") {
      fetch("https://restcountries.com/v3.1/all")
        .then((response) => response.json())
        .then((data) => {
          const countriesData = data.map((country) => ({
            label: country.translations.fra.common, // country name
            value: country.cca2, // country code
          }));
          const sortedCountriesData = countriesData.sort((a, b) => a.label.localeCompare(b.label));
          setSelectedCountries(sortedCountriesData);
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
        });
    }
  }, [mode]);

  // fetch average click bid by country
  useEffect(() => {
    if (countriesV?.length > 0) {
      countriesV.forEach((country) => {
        dispatch(fetchAverageClickBidByCountry(country.value));
      });
    }
  }, [countriesV, dispatch]);

  const computeOverallAverage = (selectedCountries, allBids) => {
    // Filter to only include bids greater than zero
    const bids = selectedCountries
      ?.map((country) => allBids[country.value]?.data / 100)
      .filter((bid) => bid > 0); // Exclude bids that are zero

    const total = bids?.reduce((acc, bid) => acc + bid, 0);
    const average = bids?.length > 0 ? total / bids?.length : 0;
    return average;
  };

  const overallAverageBid = computeOverallAverage(countriesV, averageClickBidsByCountry);

  return (
    <SuiBox>
      <SuiBox mt={1.625}>
        <Grid container>
          <Grid item xs={12}>
            {/* Input field for campaign name */}
            <FormField
              type={campaignName.type}
              label={campaignName.label}
              name={campaignName.name}
              value={campaignNameV}
              placeholder={campaignName.placeholder}
              error={errors.campaignName && touched.campaignName}
              success={campaignNameV?.length > 0 && !errors.campaignName ? true : undefined}
              description={campaignName.description}
              disabled={mode === "edit"}
            />
          </Grid>

          {/* Input field for max budget */}
          <Grid item xs={12}>
            <FormField
              type={maxBudget.type}
              label={maxBudget.label}
              name={maxBudget.name}
              value={maxBudgetV}
              placeholder={maxBudget.placeholder}
              error={errors[maxBudget.name] && touched[maxBudget.name]}
              success={maxBudgetV > 0 && !errors[maxBudget.name] ? true : undefined}
              description={maxBudget.description}
              disabled={mode === "edit"}
            />
          </Grid>
        </Grid>
        <Grid container>
          {/* Select countries */}
          <Grid item xs={12}>
            <FormField
              type="select"
              label={countries.label}
              name={countries.name}
              value={countriesV}
              placeholder={countries.placeholder}
              error={errors.countries && touched.countries}
              success={countriesV?.length > 0 && !errors.countries ? true : undefined}
              description={countries.description}
              options={selectedCountries}
              isMulti
              disabled={mode === "edit"}
            />
          </Grid>
          {/* Slider for cost per click */}
          <Grid item xs={12}>
            <FormField
              type="slider"
              label={costPerClick.label}
              name={costPerClick.name}
              value={costPerClickV}
              error={errors.costPerClick && touched.costPerClick}
              success={costPerClickV > 0.1 && !errors.costPerClick ? true : undefined}
              description={costPerClick.description}
              disabled={mode === "edit"}
              overallAverageBid={overallAverageBid}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

OfferInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  mode: PropTypes.string.isRequired,
};

export default OfferInfo;
