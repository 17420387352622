import { combineReducers } from 'redux';
import brand from './brand';
import auth from './auth';
import snack from './snack';
import shop from './shop';
import brandToEdit from './brandToEdit';
import shopToEdit from './shopToEdit';
import userToEdit from './userToEdit';
import sectorToEdit from './sectorToEdit';
import alert from './alert';
import user from './user';
import reviews from './reviews';
import userReview from './userReview';
import listClients from './listClients';
import listConversation from './listConversation';
import tickets from './tickets';
import ticketToEdit from './ticketToEdit';
import adsReducer from './adsReducer';
import campaignReducer from './campaignsReducer';
import posReducer from './posReducer';

const rootReducer = combineReducers({
  brand,
  auth,
  snack,
  brandToEdit,
  sectorToEdit,
  shop,
  shopToEdit,
  alert,
  user,
  userToEdit,
  reviews,
  userReview,
  listClients,
  listConversation,
  tickets,
  ticketToEdit,
  adsReducer,
  campaignReducer,
  posReducer,
});

export default rootReducer;
