/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import IconButton from "@mui/material/IconButton";
import * as types from "@sharecode/common/redux/actions/actionTypes";
import { useSearchParams } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { deleteShop, getShop } from "@sharecode/common/redux/actions/shop";
import { useDispatch, connect } from "react-redux";
// icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSoftUIController, setOpenShopDrawer } from "context";
import { SHOW_ALERT } from "@sharecode/common/redux/actions/actionTypes";
import { DefaultServiceHandler } from "utils/http-common";

function ActionCell(props) {
  const disp = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { openShopDrawer } = controller;
  const [searchParams, setSearchParams] = useSearchParams();
  const handleShopDrawerOpen = () => setOpenShopDrawer(dispatch, !openShopDrawer);

  const removeShop = (id) => {
    disp(deleteShop(id))
      .then((response) => {
        disp(
          getShop(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        ).then((response) => {
          disp(DefaultServiceHandler("success", "success", "Point de vente effacé avec succès!"));
        });
      })
      .catch((error) => {
        disp(
          DefaultServiceHandler(
            "error",
            "error",
            "Erreur lors de la suppression du point de vente."
          )
        );
      });
  };

  const editShop = (id) => {
    const editedShop = props.shop.results.filter((el) => {
      return el.id === parseInt(id);
    });
    disp({
      type: types.EDITED_SHOP,
      payload: editedShop[0],
    });
    handleShopDrawerOpen();
  };

  return (
    <SuiBox display="flex" alignItems="center">
      <SuiBox onClick={() => removeShop(props.id)}>
        <IconButton color="error">
          <DeleteIcon />
        </IconButton>
      </SuiBox>
      <SuiBox>
        <IconButton color="info" onClick={() => editShop(props.id)}>
          <EditIcon />
        </IconButton>
      </SuiBox>
    </SuiBox>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ActionCell);
