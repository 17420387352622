//React and libs
import { connect } from "react-redux";

// Material UI
import Card from "@mui/material/Card";

// SUI
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiTypography from "components/SuiTypography";

//Components
import TableRequest from "./components/TableRequest";

function FacturationAdmin(props) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={2}>
        <Card>
          <SuiBox
            p={2}
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            gap={2}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <SuiTypography variant="h6"> Facturation </SuiTypography>
            <SuiBox
              display="flex"
              gap={2}
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
              }}
            ></SuiBox>
          </SuiBox>
          <SuiBox
            p={2}
            display="flex"
            justifyContent="space-between"
            gap={4}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          ></SuiBox>
          <TableRequest />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(FacturationAdmin);
