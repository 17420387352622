import AsyncStorage from '@react-native-async-storage/async-storage';
import { isMobile } from "./isMobile";

const StorageUtil = {
  getItemTest: async(key) => {
    if (isMobile) {
        const item = await AsyncStorage.getItem(key);
        return item;
    } else {
      return localStorage.getItem(key);
    }
  },
 getItem: (key) => {
  if (isMobile) {
      const item = AsyncStorage.getItem(key) ?? AsyncStorage.getItem(key);
      return item;
  } else {
    return localStorage.getItem(key);
  }
 },
 setItem: (key, value) => { 
  if (isMobile) return AsyncStorage.setItem(key,value);
  return localStorage.setItem(key, value);
 },
 removeItem: (key) => {
  if (isMobile) {
      const item = AsyncStorage.removeItem(key) ?? AsyncStorage.removeItem(key);
      return;
  } else {
    localStorage.removeItem(key);
    return;
  }
 },
}

export default StorageUtil;