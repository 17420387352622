/* eslint-disable  */

import axios from "axios";
import StorageUtil from "@sharecode/common/utils/storageUtil";
import { isMobile } from "./isMobile";
import { SIGN_IN } from "@sharecode/common/routes/constants";
// import { setSnackbar } from "redux/actions/snack";
import { useDispatch } from "react-redux";
import {
  HMD_BACKEND_API_URL,
  AUTH_TOKEN_KEY,
  clearSession,
} from "@sharecode/common/services/constantApi";

// const dispatch = useDispatch();

export const HTTP = axios.create({
  baseURL: HMD_BACKEND_API_URL,
  timeout: 180000,
});

HTTP.interceptors.request.use(
  (config) => {
    const token =
      StorageUtil.getItem(AUTH_TOKEN_KEY) ??
      StorageUtil.getItem(AUTH_TOKEN_KEY);
    if (token != null && !isMobile) {
      config.headers.common.Authorization = "Token " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
HTTP.interceptors.response.use(
  (config) => config,
  (error) => {
    const res = error.response;
    if (res)
      if (res.status === 401) {
        clearSession();

        if (!isMobile) {
          window.location.href = SIGN_IN;
        }
      }
    return Promise.reject(error);
  }
);

// export const DefaultServiceHandler = (icon, color, message) => (dispatch) => {
//   return dispatch(setSnackbar(true, icon, color, message));
// };

export default { HTTP };
