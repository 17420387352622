/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */

// @mui material components
import Divider from "@mui/material/Divider";
import { useEffect, useRef, useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import { useFormik } from "formik";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";

import {
  getBrand,
  getBrandCategories,
  postBrand,
  updateBrand,
} from "@sharecode/common/redux/actions/brand";

import { connect, useDispatch } from "react-redux";
// Soft UI Dashboard PRO React context
import { setOpenBrandDrawer, useSoftUIController } from "context";

// Custom styles for the Configurator
import { REMOVE_EDITED_BRAND } from "@sharecode/common/redux/actions/actionTypes";
import BrandCreateUpdateRoot from "./brandCreateUpdateRoot";
// brandCreateUpdate components
import * as Yup from "yup";
import FormField from "./FormField";

import { useSearchParams } from "react-router-dom";
import { DefaultServiceHandler } from "utils/http-common";
function brandCreateUpdate(props) {
  const disp = useDispatch();

  const [controller, dispatch] = useSoftUIController();
  const [searchParams, setSearchParams] = useSearchParams();
  const { openBrandDrawer } = controller;
  const [selectedArea, setSelectedArea] = useState(null);
  const [availableSectors, setAvailableSectors] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const fileInputRef = useRef(null);

  const handleCloseBrandDrawer = () => {
    disp({
      type: REMOVE_EDITED_BRAND,
      payload: { deleted: {} },
    });
    setOpenBrandDrawer(dispatch, false);
    setSelectedArea(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Réinitialise la valeur de l'élément input
    }
    formik.resetForm();
  };

  const BrandAddSchema = Yup.object({
    name: Yup.string().min(1, "").max(50, "Nom enseigne trop long!").required("Required"),
    area: Yup.string().required("Required"),
    logo: Yup.mixed().test("requiredIfNotEdit", "Required", function (value) {
      if (!isEdit) {
        return value !== undefined && value !== null;
      }
      return true;
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: props.brandToEdit.name ? props.brandToEdit.name : "",
      logo: "",
      area: props.brandToEdit.area?.code ? props.brandToEdit.area?.code : "",
      validated: props.brandToEdit.validated ? props.brandToEdit.validated : true,
    },
    validationSchema: BrandAddSchema,
    onSubmit: () => {
      if (props.brandToEdit.id) {
        modifyBrand(formik);
      } else {
        addBrand(formik);
      }
    },
  });

  const mapSectorLabel = (value) => {
    let label = null;
    if (availableSectors) {
      availableSectors.forEach((elem) => {
        if (elem.value === value) {
          label = elem.label;
        }
      });
    }

    return label;
  };

  const addBrand = (form) => {
    disp(postBrand(form))
      .then((response) => {
        disp(
          getBrand(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        ).then((response) => {
          const params = {
            search: searchParams.get("search") || "",
            size: searchParams.get("size") || "",
          };
          setSearchParams({ ...params, page: 1 });
          handleCloseBrandDrawer();
          disp(DefaultServiceHandler("success", "success", "Enseigne Ajoutée avec succès!"));
        });
      })
      .catch((error) => {
        disp(DefaultServiceHandler("error", "error", error));
      });
  };

  const modifyBrand = (form) => {
    const data = { form: form, id: props.brandToEdit.id };
    disp(updateBrand(data))
      .then((response) => {
        disp(
          getBrand(
            searchParams.get("page") || "",
            searchParams.get("size") || "",
            searchParams.get("search") || ""
          )
        )
          .then((response) => {
            handleCloseBrandDrawer();
            disp(DefaultServiceHandler("success", "success", "Enseigne modifiée avec succès!"));
            setSearchParams({ search: searchParams.get('search') || "", page: 1, size: searchParams.get('size') || 10 })
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        disp(DefaultServiceHandler("error", "error", "Erreur lors de la modification."));
      });
  };

  const handSelectSector = (event) => {
    if (event) {
      setSelectedArea({ value: event.value, label: event.label });
      formik.setFieldValue("area", event.value);
    } else {
      setSelectedArea({ value: "", label: "" });
      formik.setFieldValue("area", "");
    }
  };

  useEffect(() => {
    disp(getBrandCategories(null, null, null, true)).then((response) => {
      let newArray = response.map((item) => {
        return {
          value: item.code,
          label: item.value,
        };
      });
      setAvailableSectors(newArray);
    });
  }, []);

  useEffect(() => {
    setIsEdit(!!props.brandToEdit.id);

    if (props.brandToEdit.area) {
      let areaLabel = mapSectorLabel(props.brandToEdit.area.code);
      setSelectedArea({ value: props.brandToEdit.area.value, label: areaLabel });
    }
  }, [props.brandToEdit]);

  return (
    <BrandCreateUpdateRoot variant="permanent" ownerState={{ openBrandDrawer }}>
      <form onSubmit={formik.handleSubmit}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0}
          px={3}
          pl={0}
        >
          <SuiBox>
            <SuiTypography variant="h4" color="primary">
              {props.brandToEdit.id ? "Modifier " + props.brandToEdit.name : "Ajouter une enseigne"}
            </SuiTypography>
          </SuiBox>

          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseBrandDrawer}
          >
            close
          </Icon>
        </SuiBox>
        <Divider />
        <SuiBox mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="name"
                name="name"
                label="Nom de l'enseigne"
                error={formik.touched.name && formik.errors.name != null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder=""
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.name && formik.errors.name ? formik.errors.name : <></>}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <>
                <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Secteur
                  </SuiTypography>
                </SuiBox>

                <SuiSelect
                  isClearable={true}
                  options={availableSectors}
                  name="sector"
                  onChange={(e) => handSelectSector(e)}
                  value={selectedArea}
                  error={formik.touched.area && formik.errors.area != null}
                />
                <SuiBox>
                  <SuiTypography variant="caption" color="error" fontWeight="regular">
                    {formik.touched.area && formik.errors.area ? formik.errors.area : <></>}
                  </SuiTypography>
                </SuiBox>
              </>
            </Grid>

            <Grid item xs={12}>
              <FormField
                type="file"
                id="logo"
                name="logo"
                error={formik.touched.logo && formik.errors.logo != null}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.setFieldValue("logo", e.currentTarget.files[0])}
                label="Logo"
                inputRef={fileInputRef}
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.logo && formik.errors.logo ? formik.errors.logo : <></>}
                </SuiTypography>
              </SuiBox>
            </Grid>
            {isEdit && (
              <Grid item xs={12} mt={1}>
                <FormControlLabel
                  control={
                    <Switch
                      id="validated"
                      name="validated"
                      checked={formik.values.validated}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Enseigne validée"
                  value="top"
                  labelPlacement="top"
                />
              </Grid>
            )}
            <Grid container spacing={2} display="flex" justifyContent="space-between" mt={2} ml={0}>
              <Grid item xs={6}>
                <SuiButton variant="gradient" color="error" onClick={handleCloseBrandDrawer}>
                  Annuler
                </SuiButton>
              </Grid>
              <Grid item xs={6}>
                <SuiButton disabled={!formik.isValid} variant="gradient" color="info" type="submit">
                  Valider
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </form>
    </BrandCreateUpdateRoot>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(brandCreateUpdate);
