import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  campaigns: [],
  campaignDetails: null,
  error: null,
  campaignStats: {},
  campaignAds: [],
  campaignAdsById: {},
  token: null,
  dailyStats: [],
  monthlyStats: [],
  weeklyStats: [],
  brands: [],
  dailyAdminStats: [],
  monthlyAdminStats: [],
  weeklyAdminStats: [],
  averageClickBidsByCountry: {},
};

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    // fetch all campaigns
    case types.FETCH_CAMPAIGNS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_CAMPAIGNS_SUCCESS:
      return { ...state, loading: false, campaigns: action.payload };
    case types.FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.originalError,
        token: action.payload.token,
      };

    case types.CREATE_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case types.CREATE_CAMPAIGN_SUCCESS:
      return action.payload;
    case types.CREATE_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_CAMPAIGN_DETAILS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignDetails: action.payload,
      };
    case types.FETCH_CAMPAIGN_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.DELETE_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case types.DELETE_CAMPAIGN_SUCCESS:
      return { ...state, loading: false };
    case types.DELETE_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.EDIT_CAMPAIGN_REQUEST:
      return { ...state, loading: true };

    case types.EDIT_CAMPAIGN_SUCCESS:
      const { campaignId: updatedCampaignId, ...updatedData } =
        action.payload;
      return {
        ...state,
        loading: false,
        updatedCampaignData: {
          updatedData,
        },
      };

    case types.EDIT_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.SET_SELECTED_CAMPAIGN_ID:
      return { ...state, selectedCampaignId: action.payload };
    case types.FETCH_CAMPAIGN_STATS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_CAMPAIGN_STATS_SUCCESS:
      const { campaignId, stats } = action.payload;
      return {
        ...state,
        loading: false,
        campaignStats: {
          ...state.campaignStats,
          [campaignId]: stats, // Store stats keyed by campaign ID
        },
      };

    case types.FETCH_CAMPAIGN_STATS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.FETCH_CAMPAIGN_ADS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_CAMPAIGN_ADS_SUCCESS:
      const { campaignId: adCampaignId, ads } = action.payload;
      return {
        ...state,
        loading: false,
        campaignAds: ads, // Update existing state
        campaignAdsById: {
          ...state.campaignAdsById,
          [adCampaignId]: ads, // New state to store ads by campaign ID
        },
      };
    case types.FETCH_CAMPAIGN_ADS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.UPDATE_CAMPAIGN_CLICKBID_REQUEST:
      return { ...state, loading: true };
    case types.UPDATE_CAMPAIGN_CLICKBID_SUCCESS:
      const { campaignId: clickBidupdatedCampaignId, clickBid } =
        action.payload;
      return {
        ...state,
        loading: false,
        updatedClickBids: {
          ...state.updatedClickBids,
          [clickBidupdatedCampaignId]: clickBid,
        },
      };

    case types.UPDATE_CAMPAIGN_CLICKBID_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // fetch all admin campaigns
    case types.FETCH_ADMIN_CAMPAIGNS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_ADMIN_CAMPAIGNS_SUCCESS:
      return { ...state, loading: false, campaigns: action.payload };
    case types.FETCH_ADMIN_CAMPAIGNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.originalError,
        token: action.payload.token,
      };

    // fetch single admin campaign
    case types.FETCH_ADMIN_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_ADMIN_CAMPAIGN_SUCCESS:
      return { ...state, loading: false, campaigns: action.payload };
    case types.FETCH_ADMIN_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_ADMIN_CAMPAIGN_DETAILS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_ADMIN_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignDetails: action.payload,
      };
    case types.FETCH_ADMIN_CAMPAIGN_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_ADMIN_CAMPAIGNS_ADS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_ADMIN_CAMPAIGNS_ADS_SUCCESS:
      const { campaignId: adminCampaignId2, ads: adminAds } =
        action.payload;
      return {
        ...state,
        loading: false,
        campaignAds: adminAds,
        campaignAdsById: {
          ...state.campaignAdsById,
          [adminCampaignId2]: adminAds,
        },
      };
    case types.FETCH_ADMIN_CAMPAIGNS_ADS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.FETCH_ADMIN_CAMPAIGNS_STATS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_ADMIN_CAMPAIGNS_STATS_SUCCESS:
      const { campaignId: adminCampaignId, stats: adminStats } =
        action.payload;
      return {
        ...state,
        loading: false,
        campaignStats: {
          ...state.campaignStats,
          [adminCampaignId]: adminStats, // Store stats keyed by campaign ID
        },
      };
    case types.FETCH_ADMIN_CAMPAIGNS_STATS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.DELETE_ADMIN_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case types.DELETE_ADMIN_CAMPAIGN_SUCCESS:
      return { ...state, loading: false };
    case types.DELETE_ADMIN_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_DAILY_STATS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_DAILY_STATS_SUCCESS:
      return { ...state, loading: false, dailyStats: action.payload };
    case types.FETCH_DAILY_STATS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_MONTHLY_STATS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_MONTHLY_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlyStats: action.payload,
      };
    case types.FETCH_MONTHLY_STATS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_WEEKLY_STATS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_WEEKLY_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        weeklyStats: action.payload,
      };
    case types.FETCH_WEEKLY_STATS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_BRANDS_WITH_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_BRANDS_WITH_CAMPAIGN_SUCCESS:
      return { ...state, loading: false, brands: action.payload };
    case types.FETCH_BRANDS_WITH_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_DAILY_ADMIN_STATS_BY_BRAND_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_DAILY_ADMIN_STATS_BY_BRAND_SUCCESS:
      const { brandId: dailyBrandId, stats: dailyStats } =
        action.payload;
      return {
        ...state,
        loading: false,
        dailyAdminStats: {
          ...state.dailyAdminStats,
          [dailyBrandId]: dailyStats,
        },
      };
    case types.FETCH_DAILY_ADMIN_STATS_BY_BRAND_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_SUCCESS:
      const { brandId: monthlyBrandId, stats: monthlyStats } =
        action.payload;
      return {
        ...state,
        loading: false,
        monthlyAdminStats: {
          ...state.monthlyAdminStats,
          [monthlyBrandId]: monthlyStats,
        },
      };
    case types.FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_SUCCESS:
      const { brandId: weeklyBrandId, stats: weeklyStats } =
        action.payload;
      return {
        ...state,
        loading: false,
        weeklyAdminStats: {
          ...state.weeklyAdminStats,
          [weeklyBrandId]: weeklyStats,
        },
      };
    case types.FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_SUCCESS:
      const { country, averageClickBid } = action.payload;
      return {
        ...state,
        loading: false,
        averageClickBidsByCountry: {
          ...state.averageClickBidsByCountry,
          [country]: averageClickBid,
        },
      };
    case types.FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.VALIDATE_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case types.VALIDATE_CAMPAIGN_SUCCESS:
      return { ...state, loading: false };
    case types.VALIDATE_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.REJECT_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case types.REJECT_CAMPAIGN_SUCCESS:
      return { ...state, loading: false };
    case types.REJECT_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case types.SEND_EMAIL_TO_ADMIN_REQUEST:
      return { ...state, loading: true };
    case types.SEND_EMAIL_TO_ADMIN_SUCCESS:
      return { ...state, loading: false };
    case types.SEND_EMAIL_TO_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default campaignsReducer;
