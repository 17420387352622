import { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
// Soft UI Dashboard PRO React components
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React context
import DefaultCell from "../DefaultCell";
import UserCell from "../UserCell";
import { Oval } from "@agney/react-loading";
import "moment/locale/fr";
import SuiBadgeDot from "components/SuiBadgeDot";
import ActionCell from "../ActionCell";

const TableRequest = ({ loading, brands, setBrands }) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();

  useEffect(() => {
    if (brands && brands.length > 0) {
      let objColumns = [
        { name: "Nom enseigne", align: "left" },
        { name: "Secteur", align: "left" },
        { name: "Nb de visiteurs", align: "left" },
        { name: "Nb tickets photo", align: "left" },
        { name: "Nb e-tickets", align: "left" },
        { name: "Validée", align: "left" },
        { name: "Action", align: "center" },
      ];

      setColumns(objColumns);
      let obj;

      setRows(
        brands.map((item) => {
          obj = {
            "Nom enseigne": <UserCell image={item.logo} name={item.name} />,
            Secteur: (
              <SuiBadgeDot badgeContent={item?.area?.value} color={item?.area?.color} size="lg" />
            ),
            "Nb de visiteurs": item.count_users,
            "Nb tickets photo": <DefaultCell>{`${item.count_paper_tickets}`}</DefaultCell>,
            "Nb e-tickets": <DefaultCell>{`${item.count_email_tickets}`}</DefaultCell>,
            Validée: item.validated ? "✔️" : "❌",
            Action: <ActionCell id={item.id} setBrands={setBrands} />,
          };

          return obj;
        })
      );
    } else {
      return setRows([]);
    }
  }, [brands]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucune enseigne trouvée
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
