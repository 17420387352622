import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { setSnackbar } from "@sharecode/common/redux/actions/snack";

const CustomizedSnackbars = () => {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snack.snackbarOpen);
  const snackbarIcon = useSelector((state) => state.snack.snackbarIcon);
  const snackbarType = useSelector((state) => state.snack.snackbarType);
  const snackbarMessage = useSelector((state) => state.snack.snackbarMessage);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackbar(false, snackbarIcon, snackbarType, snackbarMessage));
  };

  return (
    <div>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          severity={snackbarIcon}
          elevation={6}
          variant="filled"
          onClose={handleClose}
          color={snackbarType}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbars;
