import axios from "axios";
import { getAuthorizationToken } from "../services/constantApi";

export function exportTable({ url, fileName }, searchParams = {}) {
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return axios.get(url, { headers, params: searchParams });
    })
    .then((response) => {
      //Retrieve a xlsx file encoded in base64
      const base64Data =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        response.data;
      const link = document.createElement("a");
      link.href = base64Data;
      link.download = fileName;
      link.click();
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
