import axios from "axios";
import {
  getAuthorizationToken,
  URL_POST_USER,
  URL_ACCOUNT_DELETE,
  parameterRequest,
  URL_GET_USERS
} from "./constantApi";

const getUser = (page, size, search, cancelToken) => {
  var index = page
    ? `?${parameterRequest.page}=${page}`
    : `?${parameterRequest.page}=1`;
  var element = size
    ? `&${parameterRequest.size}=${size}`
    : `&${parameterRequest.size}=10`;
  var searchVal = search
    ? `&${parameterRequest.search}=${encodeURIComponent(search)}`
    : `&${parameterRequest.search}=`;
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .get(URL_POST_USER + index + element + searchVal, {
          headers: results.headers,
          cancelToken: cancelToken?.current?.token,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

// Get Users using CHECK API URL
const getUsers = (page = 1, pagesize = 100, search = '', role = '') => {
  const index = `?${parameterRequest.page}=${page}`;
  const element = `&${parameterRequest.pagesize}=${pagesize}`;
  const searchVal = `&${parameterRequest.search}=${encodeURIComponent(search)}`;
  const roleVal = `&${parameterRequest.role}=${encodeURIComponent(role)}`;

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return { headers };
    })
    .then((results) =>
      axios
        .get(URL_GET_USERS + index + element + searchVal + roleVal, {
          headers: results.headers,
        })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
          throw error;
        })
    );
};


const postUser = (data) => {
  //var authUser = getUserInfos();
  var user = JSON.stringify({
    email: data.form.values.email,
    username: data.form.values.email,
    first_name: data.form.values.first_name,
    last_name: data.form.values.last_name,
    role: data.selectedRole,
    shops: data.selectedShops,
    brand: data.selectedBrand,
    is_active: "true",
  });

  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        user: user,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .post(URL_POST_USER, results.user, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    );
};

const updateUser = (data) => {
  //const authUser = getUserInfos();
  var id = data.id;
  var user = JSON.stringify({
    role: data.selectedRole,
    shops: data.selectedShops,
    brand: data.selectedBrand,
  });
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        id: id,
        user: user,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .patch(URL_POST_USER + results.id + "/", results.user, {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

const deleteUser = (data) => {
  //const authUser = getUserInfos();
  var id = data.id;
  return getAuthorizationToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      return {
        id: id,
        headers: headers,
      };
    })
    .then((results) =>
      axios
        .delete(URL_ACCOUNT_DELETE + id + "/", {
          headers: results.headers,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
};

export const UserService = {
  getUser,
  postUser,
  updateUser,
  deleteUser,
  getUsers,
};
