import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AccountService } from "@sharecode/common/services";
import { DefaultServiceHandler } from "utils/http-common";
import { DASHBOARD } from "@sharecode/common/routes/constants";
import { FCMService } from "@sharecode/common/services/FCMService";

function WaitPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("code")) {
      const payload = {
        code: { code: searchParams.get("code") },
        urlRedirect: window.location.href.indexOf("linkedin") > -1 ? "linkedin" : "google",
      };
      AccountService.postCodeSocial(payload)
        .then((r) => {
          FCMService.registerDevice();
          dispatch(
            DefaultServiceHandler("success", "success", "Patientez, redirection en cours...")
          );
          setTimeout(() => {
            navigate(DASHBOARD);
          }, 2000);
        })
        .catch((error) => {
          dispatch(DefaultServiceHandler("error", "error", error.toString()));
        });
    }
  }, []);

  return <h1 style={{ textAlign: "center" }}>Redirection en cours...</h1>;
}

export default WaitPage;
