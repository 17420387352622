/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts

// Authentication pages
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SignupActivationEmail from "layouts/authentication/sign-up-email-activation";
import ResetPassword from "layouts/authentication/reset-password";
import ChangePassword from "layouts/authentication/change-password";
import {
  IS_ADMIN,
  IS_MANAGER_BRAND,
  IS_MANAGER_SHOP,
} from "@sharecode/common/redux/actions/actionTypes";
// pages
import ManageUsers from "layouts/pages/settings";
import ManageTickets from "layouts/pages/tickets";
import Dashboard from "layouts/pages/dashboard";
import Settings from "layouts/pages/account/settings";
import ClientList from "layouts/pages/clientList";
import ClientXP from "layouts/pages/clientXP";
import ShopList from "layouts/pages/shopList";
import BrandList from "layouts/pages/brandList";
import Chat from "layouts/pages/chat";
import OfferCreate from "layouts/pages/offers";
import FacturationPage from "layouts/pages/facturation";
import ManageOffers from "layouts/pages/manageOffers";
import ManageOffersAdmin from "layouts/pages/manageOffersAdmin";
import DashboardRetailerAdmin from "layouts/pages/dashboard/dashboardRetailerAdmin";
import FacturationAdmin from "layouts/pages/facturationAdmin";
import WaitPage from "layouts/pages/home/waitpage";
import Home from "layouts/pages/home";
import ClientReviews from "layouts/pages/clientReviews";
import UserTickets from "layouts/pages/userTickets";
import BrandTickets from "layouts/pages/brandTickets";
import SectorList from "layouts/pages/sectorList";
import OfferCreateAdmin from "layouts/pages/offers admin";
import ManagePosPartners from "layouts/pages/managePosPartners";
import ManagePosBusiness from "layouts/pages/managePosBusiness";

// error
import NotFound from "layouts/error/notFound";
// MUI icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import StarIcon from "@mui/icons-material/Star";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import PeopleIcon from "@mui/icons-material/People";
import StoreIcon from "@mui/icons-material/Store";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ForumIcon from "@mui/icons-material/Forum";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Euro } from "@mui/icons-material";
import CampaignIcon from "@mui/icons-material/Campaign";
import TuneIcon from "@mui/icons-material/Tune";
import BarChartIcon from "@mui/icons-material/BarChart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HandshakeIcon from '@mui/icons-material/Handshake';

import {
  BRAND_LIST,
  CLIENTS_EXPERIENCES,
  CLIENTS_LIST,
  CREATE_OFFER,
  DASHBOARD,
  CHAT,
  MANAGE_USERS,
  NEW_PASSWORD,
  PROFILE,
  RESET_PASSWORD,
  SHOP_LIST,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_ACTIVATION,
  NOT_FOUND,
  WAIT_PAGE_SOCIAL,
  WAIT_PAGE_SOCIAL_LINKEDIN,
  WAIT_PAGE_SOCIAL_GOOGLE,
  TICKET_LIST,
  REVIEWS_LIST,
  USER_TICKET_LIST,
  BRAND_TICKET_LIST,
  SECTOR_LIST,
  MANAGE_CAMPAIGNS,
  FACTURATION,
  CREATE_STORY,
  MANAGE_CAMPAIGNS_ADMIN,
  DASHBOARD_RETAILER_ADMIN,
  CREATE_OFFER_ADMIN,
  MANAGE_POS_PARTNERS,
  MANAGE_POS_BUSINESS,
} from "@sharecode/common/routes/constants";
import { useRole } from "./ProtectedRoutes";
import { IS_ADMIN_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_BRAND_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { IS_EMPLOYEE_SHOP_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_SHOP_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { IS_EMPLOYEE_BRAND_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { IS_EMPLOYEE_BRAND } from "@sharecode/common/redux/actions/actionTypes";


export const authRoutes = [
  {
    name: "profile",
    key: "account",
    route: PROFILE,
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon size="12px" />,
    route: DASHBOARD,
    component: <Dashboard />,
    noCollapse: true,
  },
];

export const managerRoutes = [
  {
    name: "profile",
    key: "account",
    route: PROFILE,
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <BarChartIcon size="12px" />,
    route: DASHBOARD,
    component: <Dashboard />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Experiences clients",
  //   key: "experiences-clients",
  //   route: CLIENTS_EXPERIENCES,
  //   component: <ClientXP />,
  //   icon: <StarIcon size="12px" />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Liste clients",
    key: "listes-clients",
    route: CLIENTS_LIST,
    component: <ClientList />,
    icon: <PeopleIcon size="12px" />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Liste points de vente",
  //   key: "shop-list",
  //   route: SHOP_LIST,
  //   component: <ShopList />,
  //   icon: <StoreIcon size="12px" />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Chat",
  //   key: "chat",
  //   icon: <ForumIcon size="12px" />,
  //   route: CHAT,
  //   component: <Chat />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Créer une offre",
    key: "create-offer",
    icon: <CampaignIcon size="12px" />,
    route: CREATE_OFFER,
    component: <OfferCreate />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Gérer mes campagnes",
    key: "manage-campaigns",
    icon: <TuneIcon size="12px" />,
    route: MANAGE_CAMPAIGNS,
    component: <ManageOffers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Facturation",
    key: "facturation",
    icon: <CreditCardIcon size="12px" />,
    route: FACTURATION,
    href: "https://billing.stripe.com/p/login/3cs4jI2tw3Y2gYU7ss",
    // component: <FacturationPage />,
    noCollapse: true,
  },

  // {
  //   type: "collapse",
  //   name: "Gestion utilisateurs",
  //   key: "manage-users",
  //   icon: <ManageAccountsIcon size="12px" />,
  //   route: MANAGE_USERS,
  //   component: <ManageUsers />,
  //   noCollapse: true,
  // },
];

export const employeeShopRoutes = [
  {
    name: "profile",
    key: "account",
    route: PROFILE,
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon size="12px" />,
    route: DASHBOARD,
    component: <Dashboard />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Experiences clients",
  //   key: "experiences-clients",
  //   route: CLIENTS_EXPERIENCES,
  //   component: <ClientXP />,
  //   icon: <StarIcon size="12px" />,
  //   noCollapse: true,
  // },
  // {
  {
    type: "collapse",
    name: "Liste clients",
    key: "listes-clients",
    route: CLIENTS_LIST,
    component: <ClientList />,
    icon: <PeopleIcon size="12px" />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Chat",
  //   key: "chat",
  //   icon: <ForumIcon size="12px" />,
  //   route: CHAT,
  //   component: <Chat />,
  //   noCollapse: true,
  // },
];

export const adminRoutes = [
  {
    name: "profile",
    key: "account",
    route: PROFILE,
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "Dashboard Admin",
    key: "dashboard",
    icon: <DashboardIcon size="12px" />,
    route: DASHBOARD,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Dashboard RA",
    key: "dashboard-retailer-admin",
    icon: <DashboardIcon size="12px" />,
    route: DASHBOARD_RETAILER_ADMIN,
    component: <DashboardRetailerAdmin />,
    noCollapse: true,
  },
  {
    name: "Créer une offre",
    key: "create-offer-admin",
    icon: <CampaignIcon size="12px" />,
    route: CREATE_OFFER_ADMIN,
    component: <OfferCreateAdmin />,
  },
  {
    type: "collapse",
    name: "Gérer mes campagnes",
    key: "manage-campaigns-admin",
    icon: <CampaignIcon size="12px" />,
    route: MANAGE_CAMPAIGNS_ADMIN,
    component: <ManageOffersAdmin />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Liste partenaires",
    key: "manage-pos-partners",
    icon: <HandshakeIcon size="12px" />,
    route: MANAGE_POS_PARTNERS,
    component: <ManagePosPartners />,
    noCollapse: true,
  },
  {
    name: "Liste business",
    key: "manage-pos-business",
    route: MANAGE_POS_BUSINESS,
    component: <ManagePosBusiness />,
  },
  {
    type: "collapse",
    name: "Liste utilisateurs",
    key: "manage-users",
    icon: <ManageAccountsIcon size="12px" />,
    route: MANAGE_USERS,
    component: <ManageUsers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Liste enseignes",
    key: "brand-list",
    route: BRAND_LIST,
    component: <BrandList />,
    icon: <ApartmentIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Liste tickets",
    key: "ticket-list",
    route: TICKET_LIST,
    component: <ManageTickets />,
    icon: <ReceiptLongIcon size="12px" />,
    noCollapse: true,
  },
  {
    name: "Liste des tickets utilisateurs",
    key: "user-tickets-list",
    route: USER_TICKET_LIST,
    component: <UserTickets />,
  },
  {
    name: "Liste des tickets enseigne",
    key: "brand-tickets-list",
    route: BRAND_TICKET_LIST,
    component: <BrandTickets />,
  },
  // {
  //   type: "collapse",
  //   name: "Liste avis clients",
  //   key: "reviews-list",
  //   route: REVIEWS_LIST,
  //   component: <ClientReviews />,
  //   icon: <StarIcon size="12px" />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Liste des secteurs",
    key: "sector-list",
    route: SECTOR_LIST,
    component: <SectorList />,
    icon: <AllInboxIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Facturation",
    key: "facturation",
    icon: <Euro size="12px" />,
    route: FACTURATION,
    component: <FacturationAdmin />,
    noCollapse: true,
  },
];

export const publicRoutes = [
  {
    name: "Sign In",
    key: "sign-in",
    route: SIGN_IN,
    component: <SignIn />,
  },
  {
    name: "Connexion",
    key: "/accounts/login/linkedin/callback/",
    route: WAIT_PAGE_SOCIAL_LINKEDIN,
    component: <WaitPage />,
  },
  {
    name: "Connexion",
    key: "/accounts/login/google/callback/",
    route: WAIT_PAGE_SOCIAL_GOOGLE,
    component: <WaitPage />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    route: SIGN_UP,
    component: <SignUp />,
  },
  {
    name: "Sign Up Activation",
    key: "sign-up-activation",
    route: SIGN_UP_ACTIVATION,
    component: <SignupActivationEmail />,
  },
  {
    name: "New Password",
    key: "new-password",
    route: NEW_PASSWORD,
    component: <ChangePassword />,
  },
  {
    name: "Reset Password",
    key: "reset-password",
    route: RESET_PASSWORD,
    component: <ResetPassword />,
  },
  {
    name: "Not Found",
    key: "not-found",
    route: NOT_FOUND,
    component: <NotFound />,
  },
];

export const useAvailableRoutes = () => {
  const role = useRole();
  const authentifiedUser = useRole();
  let routes = [];

  if (role === IS_ADMIN_VALUE) {
    routes = [...publicRoutes, ...adminRoutes];
  } else if (
    role === IS_MANAGER_BRAND_VALUE ||
    role === IS_EMPLOYEE_BRAND_VALUE ||
    role === IS_MANAGER_SHOP_VALUE
  ) {
    routes = [...publicRoutes, ...managerRoutes];
  } else if (role === IS_EMPLOYEE_SHOP_VALUE) {
    routes = [...publicRoutes, ...employeeShopRoutes];
  } else if (authentifiedUser) {
    routes = [...publicRoutes, ...authRoutes];
  } else {
    routes = publicRoutes;
  }

  return routes;
};
