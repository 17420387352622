import { getAuthorizationToken, URL_REGISTER_DEVICE, URL_UNREGISTER_DEVICE, URL_LIST_NOTIFICATIONS } from "./constantApi";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// fcm
import { firebaseConfig } from "../firebaseConfig";
import { HTTP } from "../utils/http-common";

const registerDevice = () => {
    let messaging = getMessaging(firebaseConfig.app);
    let token = ""
    getToken(messaging).then((response) => {
        token = response
    }).finally(() => {
        return new Promise((resolve, reject) => {
            HTTP.post(URL_REGISTER_DEVICE, { token: token })
                .then((r) => {
                    resolve(r.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    });
};

const unregisterDevice = () => {
    let messaging = getMessaging(firebaseConfig.app);
    let token = ""
    getToken(messaging).then((response) => {
        token = response
    }).finally(() => {
        return new Promise((resolve, reject) => {
            HTTP.post(URL_UNREGISTER_DEVICE, { token: token })
                .then((r) => {
                    resolve(r.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    });
};

const listNotifications = () => {
    return new Promise((resolve, reject) => {
        HTTP.get(URL_LIST_NOTIFICATIONS)
            .then((r) => {
                resolve(r.data);
            })
            .catch((e) => {
                reject(e);
            });
    });
}

const readNotifs = (notifsId) => {
    return new Promise((resolve, reject) => {
        HTTP.post(URL_LIST_NOTIFICATIONS, { Id: notifsId })
            .then((r) => {
                resolve(r.data);
            })
            .catch((e) => {
                reject(e);
            });
    });
}


export const FCMService = { registerDevice, unregisterDevice, listNotifications, readNotifs };
