import { useState, useEffect, useCallback } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Oval } from "@agney/react-loading";

// common
import {
  IS_ADMIN_VALUE,
  IS_MANAGER_BRAND_VALUE,
  IS_MANAGER_SHOP_VALUE,
} from "@sharecode/common/redux/actions/actionTypes";
// actions
import { getPosItem } from "@sharecode/common/redux/actions/posActions";
import { getSingleBrand } from "@sharecode/common/redux/actions/brand";

// components
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
// examples
import Table from "examples/Tables/Table";
// routes
import { useRole } from "routes/ProtectedRoutes";

const TableRequest = (props) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const role = useRole();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const posBusiness = useSelector((state) => state?.posReducer?.posBusiness);

  // Fetch posBusiness initially and whenever searchParams change
  useEffect(() => {
    const page = searchParams.get("page") || 1;
    const pagesize = searchParams.get("pagesize") || 100;
    setLoading(true);
    dispatch(getPosItem(page, pagesize))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, searchParams]);

  // Update rows whenever posbusiness (from the reducer) changes
useEffect(() => {
  if (posBusiness?.data) {
    // Extract unique brand IDs
    const brandIds = [...new Set(posBusiness?.data?.info?.map(infoItem => infoItem?.brands_brand_id))];

    // Fetch brand names for all unique brand IDs
    Promise.all(brandIds.map(brandId => {
      return dispatch(getSingleBrand(brandId))
        .then(response => {
          return {
            brandId,
            brandName: response.name || "Unknown Brand"
          };
        })
        .catch(error => {
          console.error(`Error fetching brand for ID ${brandId}:`, error);
          return { brandId, brandName: "Error Fetching Brand" };
        });
    }))
    .then(brandData => {
        // Create a map of brandId to brandName
      const brandNamesMap = brandData.reduce((acc, { brandId, brandName }) => {
        acc[brandId] = brandName;
        return acc;
      }, {});

      // Create rows for each brand ID associated with the POS business
      const newRows = posBusiness.data.info.map((infoItem) => {;

        const brandId = infoItem.brands_brand_id;
        return {
          "Nom partenaire": <DefaultCell>{posBusiness.data?.name || "-"}</DefaultCell>,
          Secteur: <DefaultCell>{posBusiness.data?.area || "-"}</DefaultCell>,
          "Brand ID": <DefaultCell>{brandId}</DefaultCell>,
          "Brand": <DefaultCell>{brandNamesMap[brandId] || "-"}</DefaultCell>,
          "Register ID": <DefaultCell>{infoItem.register_id || "-"}</DefaultCell>,
          "Business ID": <DefaultCell>{infoItem.business_id || "-"}</DefaultCell>,
          infoItemId: infoItem.id,
        };
      });

      if (
        role &&
        [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1
      ) {
        newRows.forEach((row) => {
          row.Action = <ActionCell business={posBusiness?.data || ""} infoItemId={row.infoItemId} />;
        });
      }

      setRows(newRows);
    })
    .catch(error => {
      console.error("Error fetching brand names:", error);
    });
  } else {
    setRows([]);
  }
}, [posBusiness?.data, role]);


  useEffect(() => {
    let objColumns = [
      { name: "Nom partenaire", align: "center" },
      { name: "Secteur", align: "center" },
      { name: "Brand ID", align: "center" },
      { name: "Brand", align: "center" },
      { name: "Register ID", align: "center" },
      { name: "Business ID", align: "center" },


    ];

    if (
      role &&
      [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1
    ) {
      objColumns.push({ name: "Action", align: "center" });
    }

    setColumns(objColumns);
  }, [role]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun PDV trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);