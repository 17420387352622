/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    campaignName,
    maxBudget,
    costPerClick,
    storyName,
    visualChoice,
    redirectURL,
    budgetSplit,
    countries,
  },
} = checkout;

const initialValues = {
  [campaignName.name]: "",
  [maxBudget.name]: "",
  [costPerClick.name]: 0,
  [storyName.name]: "",
  [visualChoice.name]: "",
  [redirectURL.name]: "",
  [budgetSplit.name]: 0,
  [countries.name]: [],
};

export default initialValues;
