import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// utils
import { months, monthMap } from "utils/date-utils";

export const useFavoritesChartData = (
  periodicity,
  selectedCampaignIds,
  selectedStoryIds,
  selectedBrandIds,
  startDate,
  endDate
) => {
  const stats = useSelector((state) => {
    const reducer = state.campaignReducer;
    switch (periodicity) {
      case "daily":
        return reducer.dailyAdminStats;
      case "weekly":
        return reducer.weeklyAdminStats;
      case "monthly":
        return reducer.monthlyAdminStats;
      default:
        return {};
    }
  });

  const [chartData, setChartData] = useState({
    labels: [],
    data: [],
    overallProgress: 0,
    totalCount: 0,
    periodicityProgress: 0,
  });

  const getDateLabel = (dateString, periodicity) => {
    const date = new Date(dateString);
    if (periodicity === "weekly") {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const days = Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000));
      const weekNum = Math.ceil((days + firstDayOfYear.getDay() + 1) / 7);
      return `Semaine ${weekNum}`;
    } else if (periodicity === "monthly") {
      return months[date.getMonth()];
    }
    return dateString?.split("T")[0];
  };

  useEffect(() => {
    let aggregatedData = {};
    let totalFavorites = 0;

    Object.entries(stats).forEach(([brandId, brandStats]) => {
      if (selectedBrandIds.length === 0 || selectedBrandIds.includes(parseInt(brandId))) {
        brandStats.data
          ?.filter((stat) => {
            const isCampaignSelected =
              selectedCampaignIds.length === 0 || selectedCampaignIds.includes(stat.adsCampaignID);
            const isStorySelected =
              selectedStoryIds.length === 0 || selectedStoryIds.includes(stat.adsID);
            const statDate = new Date(stat.statDate.split("T")[0]);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;
            const isInDateRange = (!start || statDate >= start) && (!end || statDate <= end);

            return isCampaignSelected && isStorySelected && isInDateRange;
          })
          .forEach((stat) => {
            let label = getDateLabel(stat.statDate, periodicity);
            aggregatedData[label] = (aggregatedData[label] || 0) + (stat.totalWishlist || 0);
            totalFavorites += stat.totalWishlist || 0;
          });
      }
    });

    const labels = Object.keys(aggregatedData).sort((a, b) => {
      if (periodicity === "monthly") {
        return monthMap[a] - monthMap[b];
      }
      return a.localeCompare(b);
    });
    const data = labels.map((label) => aggregatedData[label]);

    let overallProgress = 0;
    if (data.length > 1) {
      const startValue = data[0];
      const endValue = data[data.length - 1];
      overallProgress = Math.round(((endValue - startValue) / startValue) * 100);
    }

    setChartData({
      labels,
      data,
      overallProgress,
      totalCount: totalFavorites,
      periodicityProgress: overallProgress,
    });
  }, [
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    selectedBrandIds,
    startDate,
    endDate,
    stats,
  ]);

  return chartData;
};

export default useFavoritesChartData;
