/* eslint-disable */

import * as types from "./actionTypes";

import { UserService } from "@sharecode/common/services/UserService";

export const getUser = (page, size, search, cancelToken) => (dispatch) => {
  return UserService.getUser(page, size, search, cancelToken).then(
    (response) => {
      dispatch({
        type: types.GET_USER,
        payload: response,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Get Users using CHECK API URL
export const getUsers = (page, pagesize, search, role) => (dispatch) => {
  return UserService.getUsers(page, pagesize, search, role).then(
    (response) => {
      dispatch({
        type: types.GET_USERS,
        payload: response,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};


export const postUser = (payload) => (dispatch) => {
  return UserService.postUser(payload).then(
    (response) => {
      if (response === undefined) {
        return;
      }
      dispatch({
        type: types.POST_USER,
        payload: { newState: response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );
};

export const updateUser = (payload) => (dispatch) => {
  return UserService.updateUser(payload).then(
    (response) => {
      dispatch({
        type: types.UPDATE_USER,
        payload: { newState: response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const deleteUser = (payload) => (dispatch) => {
  return UserService.deleteUser(payload).then(
    (response) => {
      dispatch({
        type: types.REMOVE_USER,
        payload: { newState: response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
