import { useState, useEffect, useMemo } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import {
  fetchAdminCampaigns,
  fetchAdminCampaignStats,
} from "@sharecode/common/redux/actions/campaignsActions";
import { getSingleBrand } from "@sharecode/common/redux/actions/brand";
// Soft UI Dashboard PRO React components

import Table from "examples/Tables/Table";
// Soft UI Dashboard PRO React context
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { useRole } from "routes/ProtectedRoutes";
import DefaultCell from "../DefaultCell";
import { Oval } from "@agney/react-loading";

const TableRequest = () => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const role = useRole();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const campaigns = useSelector((state) => state.campaignReducer.campaigns);
  const stats = useSelector((state) => state.campaignReducer.campaignStats);
  const statsArray = Object.keys(stats).map((key) => ({
    campaignId: parseInt(key),
    data: stats[key].data,
  }));

  // Map to get totalClicked by campaignId
  const campaignStatsMap = useMemo(() => {
    return statsArray.reduce((acc, stat) => {
      acc[stat.campaignId] = stat.data.totalClicked;
      return acc;
    }, {});
  }, [statsArray]);

  const spendByBrand = useMemo(() => {
    return campaigns.data?.reduce((acc, campaign) => {
      const totalClicked = campaignStatsMap[campaign.id] || 0;
      const spend = totalClicked * campaign.clickBid;
      if (acc[campaign.brandsBrandID]) {
        acc[campaign.brandsBrandID] += spend;
      } else {
        acc[campaign.brandsBrandID] = spend;
      }
      return acc;
    }, {});
  }, [campaigns.data, campaignStatsMap]);

  const [totalBrands, setTotalBrands] = useState([]);

  // get all the brands id from the different campaigns and remove duplicates
  useEffect(() => {
    if (campaigns?.data && campaigns?.data.length > 0) {
      let brands = campaigns.data.map((campaign) => campaign.brandsBrandID);
      let uniqueBrands = [...new Set(brands)];
      setLoading(true);
      Promise.all(uniqueBrands.map((brandId) => dispatch(getSingleBrand(brandId))))
        .then((brandsData) => {
          setLoading(false);
          setTotalBrands(brandsData);
        })
        .catch((error) => {
          console.error("Error fetching brands:", error);
          setLoading(false);
        });
    }
  }, [campaigns?.data, dispatch]);

  // fetching campaigns
  useEffect(() => {
    setLoading(true);
    dispatch(fetchAdminCampaigns())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
        setLoading(false);
      });
  }, []);

  // fetching stats for each campaign
  useEffect(() => {
    if (campaigns?.data && campaigns?.data?.length > 0) {
      setLoading(true);
      Promise.all(campaigns?.data.map((campaign) => dispatch(fetchAdminCampaignStats(campaign.id))))
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching campaign stats:", error);
          setLoading(false);
        });
    }
  }, [campaigns?.data, dispatch]);

  useEffect(() => {
    if (totalBrands?.length > 0 && campaigns?.data && campaigns?.data.length > 0) {
      let objColumns = [
        { name: "Enseigne", align: "center" },
        { name: "Crédit publicitaire", align: "center" },
        { name: "Consommé", align: "center" },
        { name: "A facturer", align: "center" },
      ];

      setColumns(objColumns);

      let totalSpent = 0;
      let totalToBill = 0;
      let totalCredits = 0;

      let rowValues = totalBrands.map((brand) => {
        const brandSpent = spendByBrand[brand.id] / 100 || 0;
        const amountToBill = brandSpent > 500 ? brandSpent - 500 : 0;
        const creditPublicitaire = 500;

        totalSpent += brandSpent;
        totalToBill += amountToBill;
        totalCredits += creditPublicitaire;

        return {
          Enseigne: <DefaultCell>{brand.name}</DefaultCell>,
          "Crédit publicitaire": <DefaultCell>{`${creditPublicitaire}€`}</DefaultCell>,
          Consommé: <DefaultCell>{`${brandSpent.toFixed(2)}€`}</DefaultCell>,
          "A facturer": <DefaultCell>{`${amountToBill.toFixed(2)}€`}</DefaultCell>,
        };
      });

      // Append total row
      rowValues.push({
        Enseigne: (
          <DefaultCell>
            <strong>Total</strong>
          </DefaultCell>
        ),
        "Crédit publicitaire": (
          <DefaultCell>
            <strong>{`${totalCredits}€`}</strong>
          </DefaultCell>
        ),
        Consommé: (
          <DefaultCell>
            <strong>{`${totalSpent.toFixed(2)}€`}</strong>
          </DefaultCell>
        ),
        "A facturer": (
          <DefaultCell>
            <strong>{`${totalToBill.toFixed(2)}€`}</strong>
          </DefaultCell>
        ),
      });

      setRows(rowValues);
    } else {
      setRows([]);
    }
  }, [campaigns?.data, totalBrands, stats]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucune enseigne trouvée
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
