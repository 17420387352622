import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function SimpleStatisticsCard({ bgColor, title, count, percentage, icon }) {
  const getBackgroundStyle = (color) => {
    if (color === "gradient") {
      return {
        background: "linear-gradient(90deg, rgba(14, 131, 239, 0.2) 0%, rgba(42, 161, 252, 0.2) 66.5%, rgba(180, 219, 240, 0.2) 100%)"
      };
    }
    return {};
  };

  return (
    <Card>
      <SuiBox bgColor={bgColor === "gradient" ? "white" : bgColor} variant="gradient" style={getBackgroundStyle(bgColor)}>
        <SuiBox p={2}>
          <Grid container>
            <Grid item>
              <SuiBox
                variant="gradient"
                bgColor={bgColor === "white" || bgColor === "gradient" ? icon.color : "white"}
                color={bgColor === "white" || bgColor === "gradient" ? "white" : "dark"}
                width="2.2rem"
                height="2.2rem"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
              >
                <Icon fontSize="medium" color="inherit">
                  {icon.component}
                </Icon>
              </SuiBox>
            </Grid>
            <Grid item display="flex" alignItems="center" ml={2}>
              <SuiBox>
                <SuiTypography
                  variant="h5"
                  fontWeight="medium"
                  color={bgColor === "white" || bgColor === "gradient" ? "dark" : "white"}
                >
                  {count}{" "}
                  <SuiTypography variant="button" color={percentage.color} fontWeight="medium">
                    {percentage.text}
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
          <Grid container mt={4} mb={2}>
            <Grid item>
              <SuiBox lineHeight={1}>
                <SuiTypography
                  variant="body2"
                  color={bgColor === "white" || bgColor === "gradient" ? "text" : "white"}
                  textTransform="uppercase"
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

SimpleStatisticsCard.defaultProps = {
  bgColor: "white",
  count: 0,
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
};

SimpleStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "gradient"
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
};

export default SimpleStatisticsCard;