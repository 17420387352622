/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import * as React from "react";

// services
import { AccountService } from "@sharecode/common/services";

// redux
import { useDispatch } from "react-redux";
import { DefaultServiceHandler } from "utils/http-common";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import lock from "assets/images/clip-1057.png";

// utils
import checkEmail from "@sharecode/common/utils/validator";
import { useFormik } from "formik";

// Loading spinner
import { Oval } from "@agney/react-loading";
import { SIGN_IN } from "@sharecode/common/routes/constants";

function ResetPassword() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Champs requis";
    } else if (!checkEmail(values.email)) {
      errors.email = "Adresse mail invalide";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: () => {
      setLoading(true);
      AccountService.resetPassword(formik.values.email)
        .then(() => {
          dispatch(
            DefaultServiceHandler(
              "success",
              "success",
              "Un email de confirmation a été envoyé à " +
                formik.values.email +
                " avec un lien pour modifier votre mot de passe"
            )
          );
        })
        .catch((error) => {
          dispatch(DefaultServiceHandler("error", "error", error.toString()));
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <IllustrationLayout
      title="Réinitialisation de votre mot de passe"
      description="
      Pour réinitialiser votre mot de passe, entrez l'adresse e-mail que vous utilisez et nous vous enverrons des instructions supplémentaires par e-mail."
      color="info"
      illustration={{
        image: lock,
      }}
    >
      <SuiBox component="form" role="form" onSubmit={formik.handleSubmit}>
        <SuiBox mb={formik.touched.email && formik.errors.email != null ? 0 : 2}>
          <SuiInput
            id="email"
            type="email"
            placeholder="Email"
            size="large"
            error={formik.touched.email && formik.errors.email != null}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              {formik.touched.email && formik.errors.email ? formik.errors.email : <></>}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox mt={formik.touched.email && formik.errors.email != null ? 0 : 4} mb={1}>
          {loading ? (
            <SuiBox textAlign="center">
              <Oval width="30" color="info" />
            </SuiBox>
          ) : (
            <SuiButton variant="gradient" color="info" size="large" fullWidth type="submit">
              Envoyer
            </SuiButton>
          )}
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2} textAlign="center">
        <SuiTypography variant="button" color="text" fontWeight="regular">
          Retour à la{" "}
          <SuiTypography
            component={Link}
            to={SIGN_IN}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            connexion
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
    </IllustrationLayout>
  );
}

export default ResetPassword;
