import * as types from "./actionTypes";

import { CheckService } from "../../services";

export const getReviews = (page, size, search, getReviews) => (dispatch) => {
  return CheckService.getListReviews(page, size, search, getReviews).then(
    (response) => {
      dispatch({
        type: types.GET_CLIENTS_REVIEWS,
        payload: response,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
