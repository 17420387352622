import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";

export const TICKET_TYPES_OPTIONS = [
  { value: null, label: "Tous" },
  { value: false, label: "E-tickets" },
  { value: true, label: "Tickets photo" },
];

export function TicketsTypeFilter({ ticketValue, handleChangeTicketType }) {
  return (
    <SuiBox width={250}>
      <SuiSelect
        placeholder="Filtrer les tickets par type"
        options={TICKET_TYPES_OPTIONS}
        onChange={(e) => handleChangeTicketType(e)}
        value={ticketValue}
        isClearable
      />
    </SuiBox>
  );
}
